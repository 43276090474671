import { v4 as uuidv4 } from 'uuid';

export function parseTokenData(accessToken) {
  let payload = '';
  let tokenData = {};

  try {
    payload = accessToken.split('.')[1];
    tokenData = JSON.parse(atob(payload));
  } catch (error) {
    throw new Error(error);
  }

  return tokenData;
}

export function getFingerprint() {
  let fingerprint = localStorage.getItem('fingerprint');

  if (!fingerprint) {
    fingerprint = uuidv4();
    localStorage.setItem('fingerprint', fingerprint);
  }

  return fingerprint;
}

export function isExpired(expireAt) {
  const nowTime = Math.floor(new Date().getTime() / 1000);
  if (!expireAt) {
    return true;
  }

  return nowTime >= expireAt;
}
