export function arrayToMap(arr, keyField = 'id') {
  const keyFn = typeof keyField === 'function' ? keyField : x => x[keyField];

  let res = {};
  for (const item of arr) {
    const key = keyFn(item);

    res[key] = item;
  }

  return res;
}

export function groupBy(data, key) {
  const keyFn = typeof key === 'function' ? key : x => x[key];

  return data.reduce(function (grouped, x) {
    const key = keyFn(x);
    (grouped[key] = grouped[key] || []).push(x);
    return grouped;
  }, {});
}

export function uniqueFilter(value, index, self) {
  return self.indexOf(value) === index;
}

export const asArray = x => (Array.isArray(x) ? x : [x].filter(x => x));

export const scalarArraysEquals = (a1, a2) =>
  a1.length === a2.length && a1.every((value, index) => value === a2[index]);

export const range = (start, stop, step = 1) =>
  Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step);
