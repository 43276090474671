export default {
  namespaced: true,

  getters: {
    categories: (state, _, __, rootGetters) => rootGetters['db/cbCategories/getItems']('all'),

    getServiceCategories: (state, getters) => serviceId => {
      const ids = getters.getServiceProducts(serviceId).map(x => x.categoryId);
      return getters.categories.filter(x => ids.includes(x.id));
    },

    getServiceProducts: (state, _, __, rootGetters) => serviceId =>
      rootGetters['db/cbProducts/getItems'](serviceId),
  },

  actions: {
    fetchCategories({ dispatch }) {
      return dispatch(
        'db/cbCategories/fetchItems',
        { key: 'all', query: { useGrid: 0 } },
        { root: true }
      );
    },

    fetchServiceProducts({ dispatch }, { serviceId }) {
      return dispatch(
        'db/cbProducts/fetchItems',
        { key: serviceId, query: { serviceId, useGrid: 0 } },
        { root: true }
      );
    },
  },
};
