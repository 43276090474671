import http from './http';

const baseUrl = (process.env.VUE_APP_API_REQUEST || '').replace(/\/$/g, '');

export default {
  upload: data => http.post(`${baseUrl}/upload`, data, { skipAuth: true }),
  createRequest: (data, sendAuth = true) => {
    return http.post(`${baseUrl}/service/${data.serviceId}/request`, data, {
      skipAuth: !sendAuth,
    });
  },
  createAnonRequest: (data, sendAuth = false) => {
    return http.post(`${baseUrl}/request`, data, {
      skipAuth: !sendAuth,
    });
  },
  cancelRequest: data => http.post(`${baseUrl}/request/cancel`, data, { skipAuth: true }),
  fetchServices: params => http.get(`${baseUrl}/service`, { params }),
};
