import Vue from 'vue';
import userId from './auth';
import router from './router';
import rules from './rules';
import imagefilter from './imagefilter';
import env from './env';
import brand from './brand';

Vue.mixin(userId);
Vue.mixin(router);
Vue.mixin(rules);
Vue.mixin(imagefilter);
Vue.mixin(env);
Vue.mixin(brand);
