<template>
  <v-badge
    :value="unseen > 0"
    color="primary"
    class="hidden-xs-only"
    bordered
    dot
    offset-x="16"
    offset-y="16"
  >
    <router-link v-slot="{ navigate }" :to="{ name: 'home.notifications' }">
      <v-btn icon @click="navigate">
        <transition name="notify" mode="out-in">
          <v-icon :key="key">mdi-bell-outline</v-icon>
        </transition>
      </v-btn>
    </router-link>
  </v-badge>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import notifySound from '@/assets/audio/slow-spring-board.mp3';

export default {
  name: 'NotificationBell',

  data: () => ({
    key: 0,
    audio: null,
    firstTime: true,
  }),

  computed: mapGetters('notificationStats', ['unseen']),

  watch: {
    unseen(newVal, oldVal) {
      if (newVal > oldVal) {
        this.key++;
      }
    },

    key() {
      if (this.firstTime) {
        return;
      }

      if (this.audio.readyState === 4) {
        this.play();
      } else {
        this.audio.addEventListener('canplaythrough', () => this.play());
      }
    },
  },

  created() {
    this.audio = new Audio(notifySound);
    this.audio.volume = 0.32;
  },

  mounted() {
    this.fetchStats().finally(() => {
      this.$nextTick(() => (this.firstTime = false));
    });
  },

  methods: {
    ...mapActions('notificationStats', ['fetchStats']),

    play() {
      try {
        this.audio.play();
      } catch (e) {
        // NotAllowedError: The play method is not allowed
        // by the user agent or the platform in the current context,
        // possibly because the user denied permission.
      }
    },
  },
};
</script>

<style scoped>
.notify-enter-active {
  animation: bell 1s ease-out;
}

@keyframes bell {
  0% {
    transform: rotate(35deg);
  }
  12.5% {
    transform: rotate(-30deg);
  }
  25% {
    transform: rotate(25deg);
  }
  37.5% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(15deg);
  }
  62.5% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0);
  }
}
</style>
