<template>
  <div class="poly d-inline-flex">
    <v-img v-bind="$attrs" :src="src" :width="width" :height="height" />
  </div>
</template>

<script>
export default {
  name: 'PolyImg',
  inheritAttrs: false,
  props: {
    src: String,
    width: {
      type: [Number, String],
      default: 110,
    },
    height: {
      type: [Number, String],
      default: 128,
    },
  },
};
</script>

<style lang="scss" scoped>
.poly {
  clip-path: $polygon;
  background: $menu-bg;
}
</style>
