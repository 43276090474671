import http from './http';

const baseUrl = (process.env.VUE_APP_API_ADV || '').replace(/\/$/g, '');

export default {
  fetchStories(params) {
    return http.get(`${baseUrl}/story`, { params });
  },
  createStory(data) {
    return http.post(`${baseUrl}/story`, data);
  },
  updateStory(id, data) {
    return http.put(`${baseUrl}/story/${id}`, data);
  },
  deleteStory(id) {
    return http.delete(`${baseUrl}/story/${id}`);
  },
  updateStoryOrder(data) {
    return http.put(`${baseUrl}/story/order`, data);
  },

  fetchUserStories(params) {
    return http.get(`${baseUrl}/story-user`, { params });
  },

  addLike(id) {
    return http.post(`${baseUrl}/story-user/${id}/like`);
  },
  deleteLike(id) {
    return http.delete(`${baseUrl}/story-user/${id}/like`);
  },

  markViewed(id) {
    return http.post(`${baseUrl}/story-user/${id}/view`);
  },
};
