import http from './http';

const baseUrl = (process.env.VUE_APP_API_NFC || '').replace(/\/$/g, '');

export default {
  accessPoint: {
    list: params => http.get(`${baseUrl}/access-point`, { params }),
    create: data => http.post(`${baseUrl}/access-point`, data),
    update: data => http.patch(`${baseUrl}/access-point/${data.id}`, data),
    delete: ({ id }) => http.delete(`${baseUrl}/access-point/${id}`),
  },

  accessLog: {
    list: params => http.get(`${baseUrl}/access-log`, { params }),
  },

  token: {
    list: params => http.get(`${baseUrl}/token-admin`, { params }),
    create: data => http.post(`${baseUrl}/token-admin`, data),
    update: ({ id, ...data }) => http.patch(`${baseUrl}/token-admin/${id}`, data),
    delete: ({ id }) => http.delete(`${baseUrl}/token-admin/${id}`),
  },

  fetchDevicesByUnit: params => http.get(`${baseUrl}/devicesByUnit`, { params }),

  setDeviceMode: data => http.patch(`${baseUrl}/setDeviceMode`, data),

  admin: {
    setDeviceMode: data => http.patch(`${baseUrl}/admin/setDeviceMode`, data),
  },
};
