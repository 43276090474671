export default [
  {
    path: '/admin/booking',
    name: 'admin.booking',
    components: {
      default: () =>
        import(/* webpackChunkName: "admin-booking" */ '@/views/admin/booking/RoutePage.vue'),
      tabs: () =>
        import(/* webpackChunkName: "admin-booking" */ '@/views/admin/booking/RouteTabs.vue'),
    },
    children: [
      {
        path: 'calendar',
        name: 'admin.booking.calendar',
        component: () =>
          import(
            /* webpackChunkName: "admin-booking" */ '@/views/admin/booking/calendar/CalendarPage.vue'
          ),
        meta: { isAuth: true },
      },
      {
        path: 'calendar/reservation',
        name: 'admin.booking.reservation-calendar',
        component: () =>
          import(
            /* webpackChunkName: "admin-booking" */ '@/views/admin/booking/calendar/reservation-calendar/ReservationCalendar'
          ),
        meta: { isAuth: true },
      },
      {
        path: 'calendar/coworking',
        name: 'admin.booking.coworking-calendar',
        component: () =>
          import(
            /* webpackChunkName: "admin-booking" */ '@/views/admin/booking/calendar/coworking-calendar/CoworkingCalendar'
          ),
        meta: { isAuth: true },
      },
      {
        path: 'calendar/hotel',
        name: 'admin.booking.hotel-calendar',
        component: () =>
          import(
            /* webpackChunkName: "admin-booking" */ '@/views/admin/booking/calendar/hotel-calendar/HotelCalendar'
          ),
        meta: { isAuth: true },
      },
      {
        path: 'hotel-tickets',
        name: 'admin.hotel.tickets',
        component: () =>
          import(
            /* webpackChunkName: "admin-booking" */ '@/views/admin/booking/hotel-tickets/TicketsPage'
          ),
        meta: { isAuth: true },
      },
      {
        path: 'coworking-tickets',
        name: 'admin.coworking.tickets',
        component: () =>
          import(
            /* webpackChunkName: "admin-booking" */ '@/views/admin/booking/coworking-tickets/TicketsPage'
          ),
        meta: { isAuth: true },
      },
    ],
  },
  {
    path: '/admin/booking/hotel-tickets/:id(\\d+)/view',
    name: 'admin.hotel.tickets.view',
    component: () =>
      import(
        /* webpackChunkName: "admin-booking" */ '@/views/admin/booking/hotel-tickets/TicketViewPage'
      ),
    props: route => ({ id: Number(route.params.id) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/booking/coworking-tickets/:id(\\d+)/view',
    name: 'admin.coworking.tickets.view',
    component: () =>
      import(
        /* webpackChunkName: "admin-booking" */ '@/views/admin/booking/coworking-tickets/TicketViewPage'
      ),
    props: route => ({ id: Number(route.params.id) }),
    meta: { isAuth: true, backBtn: true },
  },
];
