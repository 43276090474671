export function objectOmit(obj, key) {
  // const { [key]: deleted, ...newObj } = obj; // bug
  const newObj = { ...obj };
  const keys = Array.isArray(key) ? key : [key];

  keys.forEach(k => {
    delete newObj[k];
  });

  return newObj;
}
