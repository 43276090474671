<script>
import { mapActions } from 'vuex';

export default {
  name: 'PushStreamConnect',

  mounted() {
    this.connect();
  },

  destroyed() {
    this.disconnect();
  },

  methods: {
    ...mapActions('pushStream', ['connect', 'disconnect']),
  },

  render() {
    return null;
  },
};
</script>
