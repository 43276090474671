<template>
  <v-app id="simple">
    <OpenInAppBar />
    <v-main>
      <slot />
      <SnackbarNotifications />
    </v-main>
  </v-app>
</template>

<script>
import SnackbarNotifications from '@/components/layout/SnackbarNotifications';
import OpenInAppBar from '@/components/layout/OpenInAppBar';

export default {
  components: { OpenInAppBar, SnackbarNotifications },
};
</script>
