<template>
  <v-btn icon v-bind="$attrs" @click.stop.prevent="markFavorite(serviceId)" v-on="$listeners">
    <SwitchBookmark :value="favoriteIds.includes(serviceId)" :size="size" />
  </v-btn>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SwitchBookmark from '@/components/common/SwitchBookmark';

export default {
  name: 'FavoriteBtn',
  components: { SwitchBookmark },
  props: {
    serviceId: {
      type: Number,
      required: true,
    },
    size: {
      type: [Number, String],
    },
  },
  computed: {
    ...mapGetters('services', ['favoriteIds']),
  },
  methods: {
    ...mapActions('services', ['fetchCatalog', 'markFavorite']),
  },
};
</script>
