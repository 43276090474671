const requireRoute = require.context('.', false, /\.js$/);

const routes = [];
requireRoute.keys().forEach(fileName => {
  if (fileName === './index.js') {
    return;
  }
  const moduleRoutes = requireRoute(fileName).default;
  routes.push(...moduleRoutes);
});

export default routes;
