import { uniqueFilter } from '@/utils/arrays';

export async function loadWith(items, withData, dispatch) {
  const promises = withData.map(rel => {
    let payload;

    if (typeof rel.payload === 'function') {
      payload = rel.payload(items);
    } else {
      const ids = items.map(x => x[rel.field]).filter(uniqueFilter);
      if (ids.length) {
        const idProp = rel.idProp || 'id';
        payload = { query: { [idProp]: ids.join(','), limit: ids.length } };
      }
    }

    if (!payload) return;

    const module = rel.module || rel.dataModel;
    const action = rel.action || 'fetchItems';

    return dispatch(`${module}/${action}`, payload, { root: true });
  });
  await Promise.all(promises);
}
