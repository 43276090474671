import http from './http';
import { treeToList } from '@/utils/tree';

const baseUrl = (process.env.VUE_APP_API_LOCATION || '').replace(/\/$/g, '');

export default {
  location: {
    fetchTree: ({ locationId }) => http.get(`${baseUrl}/location/${locationId}/struct`),
    createRootUnit: ({ locationId, ...data }) =>
      http.post(`${baseUrl}/location/${locationId}`, data),
  },

  unit: {
    // list: params => http.get(`${baseUrl}/unit`, { params }),
    list: ({ locationId, ...params }) => {
      return http.get(`${baseUrl}/location/${locationId}/struct`, { params }).then(res => {
        return { ...res, data: treeToList(res.data) };
      });
    },
    find: ({ id }) => http.get(`${baseUrl}/unit/${id}`),
    create: ({ parentId, ...data }) =>
      http.post(parentId ? `${baseUrl}/unit/${parentId}` : `${baseUrl}/unit`, data),
    update: ({ id, ...data }) => http.patch(`${baseUrl}/unit/${id}`, data),
    delete: ({ id }) => http.delete(`${baseUrl}/unit/${id}`),
  },

  floorScheme: {
    list: params => http.get(`${baseUrl}/floor-scheme`, { params: { useGrid: 0, ...params } }),
    create: data => http.post(`${baseUrl}/floor-scheme`, data),
    update: ({ id, ...data }) => http.patch(`${baseUrl}/floor-scheme/${id}`, data),
    delete: ({ id }) => http.delete(`${baseUrl}/floor-scheme/${id}`),
  },

  priceCategory: {
    list: params => http.get(`${baseUrl}/price-category`, { params: { useGrid: 0, ...params } }),
    create: data => http.post(`${baseUrl}/price-category`, data),
    update: ({ id, ...data }) => http.patch(`${baseUrl}/price-category/${id}`, data),
    delete: ({ id }) => http.delete(`${baseUrl}/price-category/${id}`),
  },

  counter: {
    list: params => http.get(`${baseUrl}/counter`, { params }),
    create: data => http.post(`${baseUrl}/counter`, data),
    update: ({ id, ...data }) => http.patch(`${baseUrl}/counter/${id}`, data),
    delete: ({ id }) => http.delete(`${baseUrl}/counter/${id}`),
  },

  counterConsumption: {
    list: params => http.get(`${baseUrl}/counter/consumption`, { params }),
  },

  counterType: {
    list: params => http.get(`${baseUrl}/counter-type`, { params }),
    create: data => http.post(`${baseUrl}/counter-type`, data),
    update: ({ id, ...data }) => http.patch(`${baseUrl}/counter-type/${id}`, data),
    delete: ({ id }) => http.delete(`${baseUrl}/counter-type/${id}`),
  },

  meterReading: {
    list: params => http.get(`${baseUrl}/meter-reading`, { params }),
    create: data => http.post(`${baseUrl}/meter-reading`, data),
    update: ({ id, ...data }) => http.patch(`${baseUrl}/meter-reading/${id}`, data),
    delete: ({ id }) => http.delete(`${baseUrl}/meter-reading/${id}`),
  },

  camera: {
    list: params => http.get(`${baseUrl}/camera`, { params }),
    find: ({ id }) => http.get(`${baseUrl}/camera/${id}`),
    create: data => http.post(`${baseUrl}/camera`, data),
    update: ({ id, ...data }) => http.patch(`${baseUrl}/camera/${id}`, data),
    delete: ({ id }) => http.delete(`${baseUrl}/camera/${id}`),
  },
};
