export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function truncate(text, stop, clamp) {
  return text.slice(0, stop) + (stop < text.length ? clamp || '…' : '');
}

// https://gist.github.com/tomfun/830fa6d8030d16007bbab50a5b21ef97
export function plural(number, one, two, five) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
}

export function copyTextToClipboard(text) {
  return navigator.clipboard.writeText(text);
}

export function stripTags(html) {
  // let tmp = document.createElement('div');
  // tmp.innerHTML = html;
  // return tmp.textContent || tmp.innerText || '';
  return html ? html.replace(/(<([^>]+)>)/gi, '') : '';
}

export function makeSlug(string) {
  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^-a-zа-я0-9\u0370-\u03ff\u1f00-\u1fff]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}
