import Vue from 'vue';
import dayjs from 'dayjs';
import servicesApi from '@/api/servicesApi';

const initialState = () => ({
  slotsByService: {
    // id => [...slots],
  },
});

const state = initialState();

const getters = {
  getServiceSlotsByDate: state => (id, date) => {
    const slots = state.slotsByService[id] || [];

    const from = dayjs.tz(date).startOf('d').unix();
    const to = from + 86400;

    return slots.filter(x => x.slot >= from && x.slot < to);
  },

  getServiceSlots: state => (id, time1, time2) => {
    const slots = state.slotsByService[id] || [];

    return slots.filter(x => x.slot >= time1 && x.slot < time2);
  },

  getSlot: state => (id, slot) => {
    const slots = state.slotsByService[id] || [];

    return slots.find(x => x.slot === slot);
  },
};

const actions = {
  async fetchServiceSlots({ commit }, { serviceId, ...params }) {
    return servicesApi
      .fetchServiceSlots({ serviceId, ...params })
      .then(res => commit('ADD_SLOTS', { serviceId, data: res.data }));
  },
};

const mutations = {
  ADD_SLOTS(state, { serviceId, data }) {
    let newSlots = data;

    if (serviceId in state.slotsByService) {
      newSlots = state.slotsByService[serviceId].filter(
        x => !data.find(item => item.slot === x.slot)
      );
      newSlots.push(...data);
    }

    Vue.set(state.slotsByService, serviceId, newSlots);
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
