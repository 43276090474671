import Vue from 'vue';

const requiredFiles = require.context('.', false, /\.js$/);

requiredFiles.keys().forEach(fileName => {
  if (fileName === './index.js') {
    return;
  }

  const filterName = fileName.replace(/(\.\/|\.js)/g, '');

  Vue.filter(filterName, requiredFiles(fileName).default);
});
