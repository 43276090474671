import { uploadFiles } from '@/api/storageApi';
import { DynamicFieldType } from '@/constants/dynamicFields';

export async function uploadDynamicFields(inputDynamicFields) {
  let dynamicFields = [];

  for (let item of inputDynamicFields) {
    if (item.inputType === DynamicFieldType.UPLOAD) {
      const newFiles = item.value.filter(x => x.file);

      if (newFiles.length > 0) {
        const urls = await uploadFiles(item.value);

        const newValue = item.value.map(x => {
          if (x.file) {
            const idx = newFiles.indexOf(x);

            return {
              filename: x.name,
              contentSize: x.size,
              contentType: x.type,
              url: urls[idx],
            };
          }

          return x;
        });

        item = { ...item, value: newValue };
      }
    }
    dynamicFields.push(item);
  }

  return dynamicFields;
}
