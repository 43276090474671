import { getField, updateField } from 'vuex-map-fields';

const initialState = () => ({
  drawer: null,
  titleVisible: true,
  homeCalendarLoaded: false,
});

const state = initialState();

const getters = {
  getField,
};

const actions = {};

const mutations = {
  updateField,

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
