import i18n from '@/plugins/i18n';

export default function inMinutes(minutes) {
  const parts = [];
  let remainder = minutes;

  if (remainder >= 1440) {
    parts.push(i18n.tc('{n} суток', Math.floor(remainder / 1440)));
    remainder = remainder % 1440;
  }

  if (remainder >= 60) {
    parts.push(i18n.tc('{n} часов', Math.floor(remainder / 60)));
    remainder = remainder % 60;
  }

  if (remainder > 0) {
    parts.push(i18n.tc('за {n} минут', remainder));
  }

  if (parts.length > 1) {
    parts.splice(parts.length - 1, 0, i18n.t('и'));
  }

  return parts.join(' ');
}
