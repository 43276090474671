import storyApi from '@/api/storyApi';

const initialState = () => ({
  stories: [],
});

const state = initialState();

const getters = {
  stories: state => state.stories,
  getStoryById: state => id => state.stories.find(x => x.id === id),
};

const actions = {
  async fetchStories({ commit }) {
    return storyApi.fetchStories().then(res => commit('SET_STORIES', res.data));
  },

  async createStory(ctx, data) {
    return storyApi.createStory(data);
  },

  async updateStory(ctx, data) {
    return storyApi.updateStory(data.id, data);
  },

  async deleteStory({ dispatch }, id) {
    return storyApi.deleteStory(id).then(() => dispatch('fetchStories'));
  },

  async updateStoryOrder({ dispatch }, storyIds) {
    return storyApi.updateStoryOrder({ storyIds }).then(() => dispatch('fetchStories'));
  },
};

const mutations = {
  SET_STORIES(state, payload) {
    state.stories = payload;
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
