<template>
  <v-list-item class="pl-6" v-bind="$attrs">
    <v-list-item-action class="my-3 mr-4">
      <slot name="icon" />
    </v-list-item-action>

    <v-list-item-content>
      <v-list-item-title>
        <slot />
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'AppMenuListItem',
};
</script>

<style lang="scss" scoped>
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--v-t-grey-base) !important;
}

.v-list-item__title {
  font-size: 0.875rem !important;
  font-weight: 700;
}

.v-list-item--active {
  background-color: var(--v-t-back-light-base);
  color: var(--v-secondary-base);
  font-weight: 700;
}

.v-list-item--link:before {
  background-color: inherit;
}

.v-list-item::v-deep {
  .v-list-item__action,
  .v-list-item__avatar,
  .v-list-item__icon {
    min-width: 16px;
  }
}
</style>
