<template>
  <div
    class="px-sm-6 py-4 text-body-2 text-md-subtitle-1"
    :class="primary ? 'primary--text' : 't-grey--text'"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BlockTitle',
  props: { primary: Boolean },
};
</script>
