import { Layout } from '@/layouts';

export default [
  {
    path: '/coworking/ticket-link',
    name: 'coworking.ticket-link',
    component: () =>
      import(/* webpackChunkName: "sharelink" */ '@/views/sharelink/CoworkingTicketLinkPage.vue'),
    meta: { isAuth: true, backBtn: false },
  },
  {
    path: '/sharelink/reservation/:token',
    name: 'sharelink.reservation',
    props: true,
    component: () =>
      import(/* webpackChunkName: "sharelink" */ '@/views/sharelink/ReservationShareLinkPage.vue'),
    meta: { isAuth: true, layout: Layout.SIMPLE },
  },
];
