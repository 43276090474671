import http from './http';

const baseUrl = (process.env.VUE_APP_API_ACS || '').replace(/\/$/g, '');

export default {
  accessLevel: {
    list: params => http.get(`${baseUrl}/access-level`, { params }),
    find: ({ id }) => http.get(`${baseUrl}/access-level/${id}`),
  },
  accessPoint: {
    list: ({ providerId, ...params }) =>
      http.get(`${baseUrl}/provider/${providerId}/access-point`, { params }),
  },
  driver: {
    list: params => http.get(`${baseUrl}/driver`, { params }),
  },
  provider: {
    list: params => http.get(`${baseUrl}/provider`, { params }),
    create: data => http.post(`${baseUrl}/provider`, data),
    update: ({ id, ...data }) => http.patch(`${baseUrl}/provider/${id}`, data),
    delete: ({ id }) => http.delete(`${baseUrl}/provider/${id}`),
  },

  admin: {
    accessLevel: {
      list: params => http.get(`${baseUrl}/admin/access-level`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/admin/access-level/${id}`),
      delete: ({ id }) => http.delete(`${baseUrl}/admin/access-level/${id}`),
    },
  },

  operator: {
    accessLevel: {
      list: params => http.get(`${baseUrl}/operator/access-level`, { params }),
    },
  },
};
