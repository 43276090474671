import { objectOmit } from '@/utils/objects';

export function listToTree(list) {
  let map = {};
  let roots = [];
  let i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i;
    list[i].children = [];
  }

  for (let node of list) {
    if (node.parentIds?.length) {
      node.parentIds.forEach(parentId => {
        list[map[parentId]].children.push(node);
      });
    } else {
      roots.push(node);
    }
  }

  return roots;
}

export function findParent(tree, id, parent = null) {
  for (let item of tree) {
    if (item.id === id) {
      return parent;
    }

    const childParent = findParent(item.children, id, item);
    if (childParent) {
      return childParent;
    }
  }

  return null;
}

export function treeToList(items) {
  return Array.prototype.concat
    .apply(
      items,
      items.map(x => treeToList(x.children || []))
    )
    .map(x => objectOmit(x, 'children'));
}
