<template>
  <div>
    <v-dialog v-model="dialog" width="345">
      <template #activator="{ on, attrs }">
        <a href="#" class="t-grey--text text-body-2" v-bind="attrs" v-on="on" @click.prevent>
          <slot>{{ location && location.name }}</slot>
        </a>
      </template>
      <v-card>
        <v-card-title class="text-h3">
          {{ $t('Выберите город и локацию') }}
        </v-card-title>
        <v-card-text>
          <LocationSelectForm v-if="dialog" :btn-name="$t('Перейти')" :callback="onChange" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LocationSelectForm from '@/components/auth/LocationSelectForm';

export default {
  name: 'AppLocation',

  components: { LocationSelectForm },

  data: () => ({
    dialog: false,
  }),

  computed: {
    ...mapGetters('auth', ['location']),
  },

  methods: {
    async onChange() {
      window.location = '/';
    },
  },
};
</script>
