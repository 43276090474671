import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';
import createLogger from 'vuex/dist/logger';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  modules,
  actions: {
    reset({ commit }) {
      Object.keys(modules).forEach(moduleName => {
        commit(`${moduleName}/RESET`);
      });
    },
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});

if (process.env.NODE_ENV === 'development') {
  window.store = store;
}

export default store;
