import dayjs from 'dayjs';
import i18n from '@/plugins/i18n';

export default function (value, isEnd = false) {
  if (!value && value !== 0) {
    return '';
  }

  const date = typeof value === 'number' ? value * 1000 : dayjs(value).tz();
  const format = isEnd ? 'time24' : 'time';

  return i18n.d(date, format);
}
