<template>
  <v-list class="py-0">
    <template v-for="(item, idx) in items">
      <v-list-item :key="idx" :to="item.route" class="pl-16">
        <v-list-item-action class="mr-2">
          <SvgIcon v-if="typeof item.icon === 'object'" size="16">
            <component :is="item.icon" />
          </SvgIcon>
          <v-icon v-else small>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <component :is="item.component || defaultComponent" :title="item.title" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import SvgIcon from '@/components/icons/SvgIcon';
import DefaultItem from '@/components/layout/menu/item/DefaultItem';

export default {
  name: 'AppSubMenuList',

  components: { SvgIcon },

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    defaultComponent: DefaultItem,
  }),
};
</script>

<style lang="scss" scoped>
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--v-t-grey-base) !important;
}

.v-list-item__title {
  font-size: 0.75rem !important;
  font-weight: normal;
}

.v-list-item--active {
  background-color: $back-light;
  color: var(--v-secondary-base);
  font-weight: bold;
}

.v-list-item--link:before {
  background-color: inherit;
}
</style>
