export default {
  methods: {
    // https://storage.lk.olvery.ru/58ce6da58e1698bbbc4afc11d6bbb349.jpeg
    // https://lk.olvery.ru/storage/58ce6da58e1698bbbc4afc11d6bbb349.jpeg
    $crop(url, size = '100x100') {
      if (url && url.startsWith('http') && (url.endsWith('jpg') || url.endsWith('jpeg'))) {
        const parsed = new URL(url);
        const pathname = parsed.pathname.startsWith('/storage/')
          ? parsed.pathname.replace('/storage/', `/storage/crop/${size}/`)
          : `/crop/${size}${parsed.pathname}`;

        return `${parsed.origin}${pathname}`;
      }

      return url;
    },
  },
};
