import { render, staticRenderFns } from "./EditorContent.vue?vue&type=template&id=b4ea5d7a"
import script from "./EditorContent.vue?vue&type=script&lang=js"
export * from "./EditorContent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports