import coffeeBreakApi from '@/api/coffeeBreakApi';

export default {
  namespaced: true,

  getters: {
    categories: (state, _, __, rootGetters) => rootGetters['db/cbAdminCategories/getItems']('all'),

    products: (state, _, __, rootGetters) => rootGetters['db/cbAdminProducts/getItems']('all'),

    getSupplierCategories: (state, _, __, rootGetters) => supplierCompanyId =>
      rootGetters['db/cbAdminCategories/getItems'](`supplier-${supplierCompanyId}`),

    getSupplierProducts: (state, _, __, rootGetters) => supplierCompanyId =>
      rootGetters['db/cbAdminProducts/getItems'](`supplier-${supplierCompanyId}`),

    getProduct: (state, _, __, rootGetters) => id => rootGetters['db/cbAdminProducts/getItem'](id),

    getCategoryProducts: (state, getters) => categoryId =>
      getters.products.filter(x => x.categoryId === categoryId),

    lackCountByCategory(state, getters) {
      return getters.products.reduce(
        (res, x) => ({
          ...res,
          [x.categoryId]: (res[x.categoryId] || 0) + (x.stockQuantity < 1 ? 1 : 0),
        }),
        {}
      );
    },

    totalLackCount(state, getters) {
      return getters.products.reduce((sum, x) => sum + (x.stockQuantity < 1 ? 1 : 0), 0);
    },
  },

  actions: {
    fetchCategories({ dispatch }) {
      return dispatch(
        'db/cbAdminCategories/fetchItems',
        { key: 'all', query: { limit: 1000 } },
        { root: true }
      );
    },

    fetchProducts({ dispatch }) {
      return dispatch(
        'db/cbAdminProducts/fetchItems',
        { key: 'all', query: { limit: 1000 } },
        { root: true }
      );
    },

    fetchSupplierCategories({ dispatch }, { supplierCompanyId }) {
      if (!supplierCompanyId) {
        return Promise.resolve();
      }

      return dispatch(
        'db/cbAdminCategories/fetchItems',
        {
          _key: `supplier-${supplierCompanyId}`,
          supplierCompanyId,
          useGrid: 0,
        },
        { root: true }
      );
    },

    fetchSupplierProducts({ dispatch }, { supplierCompanyId, ...params }) {
      if (!supplierCompanyId) {
        return Promise.resolve();
      }

      return dispatch(
        'db/cbAdminProducts/fetchItems',
        {
          _key: `supplier-${supplierCompanyId}`,
          supplierCompanyId,
          ...params,
          useGrid: 0,
        },
        { root: true }
      );
    },

    income({ commit }, payload) {
      return coffeeBreakApi.productIncome(payload).then(res => {
        commit('db/cbAdminProducts/UPDATE', res.data, { root: true });
      });
    },
  },
};
