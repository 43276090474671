export default [
  {
    path: '/operator/access',
    name: 'operator.access',
    redirect: { name: 'operator.access.roles' },
    components: {
      default: () =>
        import(/* webpackChunkName: "admin-saas" */ '@/views/operator/access/RoutePage'),
      tabs: () => import(/* webpackChunkName: "admin-saas" */ '@/views/operator/access/RouteTabs'),
    },
    meta: { isAuth: true },
    children: [
      {
        path: '/operator/access/roles',
        name: 'operator.access.roles',
        component: () =>
          import(/* webpackChunkName: "admin-saas" */ '@/views/operator/access/roles/Roles.vue'),
        meta: { isAuth: true },
      },
      {
        path: '/operator/access/permissions',
        name: 'operator.access.permissions',
        component: () =>
          import(
            /* webpackChunkName: "admin-saas" */ '@/views/operator/access/permissions/Permissions.vue'
          ),
        meta: { isAuth: true },
      },
      {
        path: '/operator/access/templates',
        name: 'operator.access.templates',
        component: () =>
          import(
            /* webpackChunkName: "admin-saas" */ '@/views/operator/access/templates/Templates.vue'
          ),
        meta: { isAuth: true },
      },
    ],
  },
  {
    path: '/operator/access/permissions/new',
    name: 'operator.access.permissions.new',
    component: () =>
      import(
        /* webpackChunkName: "admin-saas" */ '@/views/operator/access/permissions/PermissionNew.vue'
      ),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/operator/access/permissions/:id(\\d+)/edit',
    name: 'operator.access.permissions.edit',
    component: () =>
      import(
        /* webpackChunkName: "admin-saas" */ '@/views/operator/access/permissions/PermissionEdit.vue'
      ),
    meta: { isAuth: true, backBtn: true },
  },
];
