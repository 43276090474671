import Vue from 'vue';
import VueYandexMetrika from 'vue-yandex-metrika';
import VueGtm from '@/vendor/vue-gtm/src';
import router from '@/router';

const ymId = process.env.VUE_APP_YANDEX_METRIKA_ID;
const ymDebug = !!process.env.VUE_APP_YANDEX_METRIKA_DEBUG;

if (ymId || ymDebug) {
  Vue.use(VueYandexMetrika, {
    id: ymId,
    router: router,
    env: process.env.NODE_ENV,
    debug: ymDebug,
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    },
  });
}

// const gaId = process.env.VUE_APP_GA_ID;
// const gaDebug = !!process.env.VUE_APP_GA_DEBUG;
//
// if (gaId || gaDebug) {
//   Vue.use(VueAnalytics, {
//     id: gaId,
//     router,
//     debug: {
//       enabled: gaDebug,
//       trace: false,
//     },
//   });
// }

const gtmId = process.env.VUE_APP_GOOGLE_TAG_ID;
const gtmDebug = !!process.env.VUE_APP_GOOGLE_TAG_DEBUG;

if (gtmId || gtmDebug) {
  Vue.use(VueGtm, {
    id: gtmId,
    enabled: process.env.NODE_ENV === 'production',
    debug: gtmDebug,
    loadScript: true,
    vueRouter: router,
  });
}
