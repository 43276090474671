import Vue from 'vue';
import { asArray } from '@/utils/arrays';

const DEFAULT_KEY = 'items';

export function makeDataModel({ service, idProp = 'id' }, ext = {}) {
  const initialState = () => ({
    byId: {},
    queries: {},
    fetchingKeys: [],
    ...ext.state,
  });

  return {
    namespaced: true,

    state: initialState,

    getters: {
      allItems: state => {
        return Object.values(state.byId);
      },

      getItems:
        (state, getters) =>
        (key = DEFAULT_KEY) => {
          key = typeof key === 'object' ? JSON.stringify(key) : key;

          return state.queries[key]?.data.map(id => getters.getItem(id)) || [];
        },

      items: (state, getters) => getters.getItems(),

      getMeta:
        state =>
        (key = DEFAULT_KEY) => {
          return state.queries[key]?.meta ?? { page: 0, pageCount: 0, pageSize: 0, totalCount: 0 };
        },

      meta: (state, getters) => getters.getMeta(),

      getItem: state => id => {
        return state.byId[id];
      },

      hasKey: state => key => {
        return key in state.queries;
      },

      ...ext.getters,
    },

    actions: {
      async fetchItems({ commit }, { _key, ...params } = {}) {
        // support { key, query } - old format
        const query = 'query' in params ? params.query : params;
        const key = _key || params.key || DEFAULT_KEY;

        commit('ADD_FETCH_KEY', { key });
        const response = await service.list(query);
        commit('ADD_QUERY', { key, response, query });

        return key;
      },

      async fetchItem({ commit }, { _key, ...params }) {
        const query = 'query' in params ? params.query : params;
        const key = _key || params.key || JSON.stringify(query) || 'item';

        commit('ADD_FETCH_KEY', { key });
        const response = await service.find(query);
        commit('ADD_QUERY', { key, response, query });

        return key;
      },

      async create({ commit }, payload) {
        const response = await service.create(payload);
        if (response && response.data) {
          commit('ADD', response.data);
        }
        return response;
      },

      async update({ commit }, payload) {
        const response = await service.update(payload);
        if (response && response.data) {
          commit('UPDATE', response.data);
        }
        return response;
      },

      async delete({ commit }, payload) {
        const response = await service.delete(payload);
        commit('DELETE', { [idProp]: payload[idProp] });
        return response;
      },

      ...ext.actions,
    },

    mutations: {
      ADD_FETCH_KEY(state, { key }) {
        state.fetchingKeys.push(key);
      },

      ADD_QUERY(state, { key, response, query }) {
        let data = response.data;
        let meta = null;

        if ('items' in data && 'page' in data) {
          const { items, ...rest } = data;
          data = items;
          meta = rest;
        } else {
          meta = { page: 1, pageCount: 1, pageSize: data.length, totalCount: data.length };
        }

        const dataIds = Array.isArray(data) ? data.map(x => x[idProp]) : data?.[idProp];

        const queryDetails = { key, query, data: dataIds, meta };

        Vue.set(state.queries, key, queryDetails);
        asArray(data).forEach(item => Vue.set(state.byId, item[idProp], item));

        const foundIdx = state.fetchingKeys.indexOf(key);
        if (foundIdx !== -1) {
          state.fetchingKeys.splice(foundIdx, 1);
        }
      },

      DELETE(state, payload) {
        for (const query of Object.values(state.queries)) {
          if (asArray(query.data).includes(payload[idProp])) {
            Vue.set(
              state.queries[query.key],
              'data',
              Array.isArray(query.data) ? query.data.filter(x => x !== payload[idProp]) : null
            );
          }
        }
        Vue.delete(state.byId, payload[idProp]);
      },

      UPDATE(state, item) {
        Vue.set(state.byId, item[idProp], item);
      },

      ADD(state, item) {
        Vue.set(state.byId, item[idProp], item);
      },

      ADD_TO_LIST_START(state, { key, item }) {
        const id = item[idProp];
        Vue.set(state.byId, id, item);
        if (!state.queries[key].data.includes(id)) {
          state.queries[key].data.unshift(id);
        }
      },

      RESET(state) {
        const newState = initialState();
        Object.keys(newState).forEach(key => (state[key] = newState[key]));
      },

      ...ext.mutations,
    },
  };
}
