import { Layout } from '@/layouts';

export default [
  {
    path: '/support',
    name: 'public.support',
    component: () => import(/* webpackChunkName: "public" */ '@/views/public/SupportPage.vue'),
    meta: { isAuth: false },
  },
  {
    path: '/support/cancel',
    name: 'public.support.cancel',
    component: () =>
      import(/* webpackChunkName: "public" */ '@/views/public/SupportCancelPage.vue'),
    meta: { isAuth: false, layout: Layout.SIMPLE },
  },
];
