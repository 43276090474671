export default {
  methods: {
    $hasRoute(raw) {
      return this.$router.match(raw).matched.length > 0;
    },
    $goBack(fallbackLocation) {
      if (window.history.length > 2) {
        return this.$router.go(-1);
      }

      if (fallbackLocation) {
        return this.$router.push(fallbackLocation);
      }

      return this.$router.push('/');
    },
  },
};
