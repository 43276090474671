export default [
  {
    path: '/location-plan',
    name: 'location-plan',
    component: () => import(/* webpackChunkName: "plan" */ '@/views/plan/PlanPage.vue'),
    meta: { isAuth: true },
  },
  {
    path: '/location-plan/:id(\\d+)',
    name: 'location-plan.unit',
    component: () => import(/* webpackChunkName: "plan" */ '@/views/plan/PlanUnitPage.vue'),
    props: ({ params }) => ({ id: Number(params.id) }),
    meta: { isAuth: true, backBtn: true },
  },
];
