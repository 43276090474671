<template>
  <PageTabs>
    <v-tabs>
      <v-tab :to="{ name: 'home.main' }">
        {{ $t('Мой кабинет') }}
      </v-tab>
      <v-tab :to="{ name: 'home.orders' }">
        {{ $t('Мои заказы') }}
      </v-tab>
      <v-tab :to="{ name: 'home.tickets' }">
        {{ $t('Мои бронирования') }}
      </v-tab>
      <v-tab :to="{ name: 'home.notifications' }">
        <v-badge :value="unseen > 0" :content="unseen" color="primary" bordered>
          {{ $t('Уведомления') }}
        </v-badge>
      </v-tab>
    </v-tabs>
  </PageTabs>
</template>

<script>
import { mapGetters } from 'vuex';
import PageTabs from '@/components/ui/PageTabs';

export default {
  name: 'HomeTabs',
  components: { PageTabs },
  computed: mapGetters('notificationStats', ['unseen']),
};
</script>
