import Vue from 'vue';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import formats from '@/dayjs/plugin/formats';

import 'dayjs/locale/ru';
import 'dayjs/locale/en';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(formats);

// if (process.env.VUE_APP_ENV === 'dev') {
//   const devHelper = require('dayjs/plugin/devHelper');
//   dayjs.extend(devHelper);
// }

Object.defineProperties(Vue.prototype, {
  $dayjs: {
    get: function () {
      return dayjs;
    },
  },
});

if (process.env.NODE_ENV === 'development') {
  window.dayjs = dayjs;
}
