import brand from '@/brand';

export default {
  computed: {
    $brand() {
      return this.$store.state.appBrand.brand;
    },
    $brandConfig() {
      return brand;
    },
  },
};
