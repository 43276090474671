export default {
  namespaced: true,

  getters: {
    getPayer: (state, _, __, rootGetters) => payer => {
      const [type, id] = (payer || '').split('-');
      if (type === 'co') {
        return rootGetters['db/adminCompanies/getItem'](id)?.shortName;
      }
      if (type === 'pe') {
        return rootGetters['db/adminProfiles/getItem'](id)?.fullName;
      }
      return null;
    },
  },

  actions: {
    async fetchPayers({ dispatch, rootGetters }, payers) {
      const userIds = [];
      const companyIds = [];

      for (const payer of payers) {
        const [type, id] = (payer || '').split('-');
        if (
          type === 'pe' &&
          userIds.indexOf(id) === -1 &&
          !rootGetters['db/adminProfiles/getItem'](id)
        ) {
          userIds.push(id);
        }

        if (
          type === 'co' &&
          companyIds.indexOf(id) === -1 &&
          !rootGetters['db/adminCompanies/getItem'](id)
        ) {
          companyIds.push(id);
        }
      }

      const promises = [];
      if (userIds.length) {
        promises.push(
          dispatch(
            'db/adminProfiles/fetchItems',
            { query: { userId: userIds.join(','), limit: userIds.length } },
            { root: true }
          )
        );
      }
      if (companyIds.length) {
        promises.push(
          dispatch(
            'db/adminCompanies/fetchItems',
            { query: { id: companyIds.join(','), limit: companyIds.length } },
            { root: true }
          )
        );
      }

      return Promise.all(promises);
    },
  },
};
