<template>
  <BounceTransition mode="out-in">
    <SwitchIcon
      :value="value"
      icon-on="mdi-bookmark"
      icon-off="mdi-bookmark-outline"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </BounceTransition>
</template>

<script>
import BounceTransition from '@/components/ui/BounceTransition';
import SwitchIcon from '@/components/ui/SwitchIcon';

export default {
  name: 'SwitchBookmark',
  components: { SwitchIcon, BounceTransition },
  inheritAttrs: false,
  props: { value: Boolean },
};
</script>
