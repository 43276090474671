import servicesApi from '@/api/servicesApi';
import { uploadFile } from '@/api/storageApi';

const initialState = () => ({
  categories: [],
  services: [],
});

const state = initialState();

const getters = {
  categories: state => state.categories,
  services: state => state.services,
  getServiceById: state => id => state.services.find(x => x.id === id),

  maxCategoryHomePosition: state =>
    (state.categories.length && Math.max(...state.categories.map(x => x.homePosition))) || 0,

  maxServiceHomePosition: state =>
    (state.services.length && Math.max(...state.services.map(x => x.homePosition))) || 0,
};

const actions = {
  async fetchCatalog({ dispatch }) {
    return Promise.all([dispatch('fetchCategories'), dispatch('fetchServices')]);
  },

  async fetchCategories({ commit }) {
    return servicesApi.fetchAdminCategories().then(res => commit('SET_CATEGORIES', res.data));
  },

  async fetchServices({ commit }) {
    return servicesApi.fetchAdminServices().then(res => commit('SET_SERVICES', res.data));
  },

  async createCategory({ dispatch }, { form, image }) {
    const data = { ...form };
    if (image) {
      data.image = await uploadFile(image);
    }

    const res = await servicesApi.createCategory(data);
    await dispatch('fetchCategories');

    return res;
  },

  async updateCategory({ dispatch }, { id, form, image }) {
    const data = { ...form };
    if (image) {
      data.image = await uploadFile(image);
    }

    const res = await servicesApi.updateCategory(id, data);
    await dispatch('fetchCategories');

    return res;
  },

  async updateCategoryPriority({ dispatch }, data) {
    return servicesApi.updateCategoryPriority(data).then(() => dispatch('fetchCategories'));
  },

  async deleteCategory({ dispatch }, id) {
    return servicesApi.deleteCategory(id).then(() => dispatch('fetchCatalog'));
  },

  async createService(_, { data }) {
    return servicesApi.createService(data);
  },

  async updateService(_, { id, data }) {
    return servicesApi.updateService(id, data);
  },

  async deleteService(_, id) {
    return servicesApi.deleteService(id);
  },

  async updateServicePriority({ dispatch }, data) {
    return servicesApi.updateServicePriority(data).then(() => dispatch('fetchServices'));
  },
};

const mutations = {
  SET_CATEGORIES(state, payload) {
    state.categories = Object.freeze(payload);
  },

  SET_SERVICES(state, payload) {
    state.services = Object.freeze(payload);
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
