export default [
  {
    path: '/operator/locations',
    name: 'operator.locations.layout',
    redirect: { name: 'operator.locations' },
    components: {
      default: () =>
        import(/* webpackChunkName: "admin-saas" */ '@/views/operator/locations/RoutePage'),
      tabs: () =>
        import(/* webpackChunkName: "admin-saas" */ '@/views/operator/locations/RouteTabs'),
    },
    children: [
      {
        path: '/operator/locations',
        name: 'operator.locations',
        components: {
          default: () =>
            import(/* webpackChunkName: "admin-saas" */ '@/views/operator/locations/LocationsPage'),
          search: () =>
            import(
              /* webpackChunkName: "admin-saas" */ '@/views/operator/locations/LocationSearch'
            ),
        },

        component: () =>
          import(/* webpackChunkName: "admin-saas" */ '@/views/operator/locations/LocationsPage'),
        meta: { isAuth: true },
      },
    ],
  },
  {
    path: '/operator/locations/new',
    name: 'operator.locations.new',
    component: () =>
      import(
        /* webpackChunkName: "admin-saas" */ '@/views/operator/locations/info/LocationFormPage'
      ),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/operator/locations/:id(\\d+)/edit',
    name: 'operator.locations.edit',
    component: () =>
      import(
        /* webpackChunkName: "admin-saas" */ '@/views/operator/locations/info/LocationFormPage'
      ),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/operator/locations/:id(\\d+)/info',
    name: 'operator.locations.info.layout',
    redirect: { name: 'operator.locations.info' },
    components: {
      default: () =>
        import(/* webpackChunkName: "admin-saas" */ '@/views/operator/locations/info/RoutePage'),
      tabs: () =>
        import(/* webpackChunkName: "admin-saas" */ '@/views/operator/locations/info/RouteTabs'),
    },
    props: true,
    children: [
      {
        path: '/operator/locations/:id(\\d+)/info',
        name: 'operator.locations.info',
        component: () =>
          import(
            /* webpackChunkName: "admin-saas" */ '@/views/operator/locations/info/LocationInfoPage'
          ),
        props: route => ({
          id: parseInt(route.params.id, 10),
        }),
        meta: { isAuth: true, backBtn: true },
      },
      {
        path: '/operator/locations/:id(\\d+)/structure',
        name: 'operator.locations.structure',
        component: () =>
          import(
            /* webpackChunkName: "admin-saas" */ '@/views/operator/locations/structure/LocationStructurePage'
          ),
        props: route => ({
          id: parseInt(route.params.id, 10),
        }),
        meta: { isAuth: true, backBtn: true },
      },
      {
        path: '/operator/locations/:id(\\d+)/providers',
        name: 'operator.locations.providers',
        component: () =>
          import(
            /* webpackChunkName: "admin-saas" */ '@/views/operator/locations/providers/ProvidersPage'
          ),
        props: route => ({
          id: parseInt(route.params.id, 10),
        }),
        meta: { isAuth: true, backBtn: true },
      },
    ],
  },
  {
    path: '/operator/locations/:id(\\d+)/tou',
    name: 'operator.locations.tou',
    component: () =>
      import(
        /* webpackChunkName: "admin-saas" */ '@/views/operator/locations/info/LocationTouPage'
      ),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/operator/locations/:id(\\d+)/contacts',
    name: 'operator.locations.contacts',
    component: () =>
      import(
        /* webpackChunkName: "admin-saas" */ '@/views/operator/locations/info/LocationContactsPage'
      ),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/operator/locations/:id(\\d+)/about',
    name: 'operator.locations.about',
    component: () =>
      import(
        /* webpackChunkName: "admin-saas" */ '@/views/operator/locations/info/LocationAboutPage'
      ),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
];
