export default [
  {
    path: '/admin/media',
    name: 'admin.media',
    components: {
      default: () => import(/* webpackChunkName: "admin-media" */ '@/views/admin/media/RoutePage'),
      tabs: () => import(/* webpackChunkName: "admin-media" */ '@/views/admin/media/RouteTabs'),
    },
    meta: { isAuth: true },
    children: [
      {
        path: '/admin/media/news',
        name: 'admin.news',
        components: {
          default: () =>
            import(/* webpackChunkName: "admin-media" */ '@/views/admin/media/news/NewsPage'),
          search: () =>
            import(/* webpackChunkName: "admin-media" */ '@/views/admin/media/news/NewsSearch'),
        },
        meta: { isAuth: true },
      },
      {
        path: '/admin/media/events',
        name: 'admin.events',
        components: {
          default: () =>
            import(/* webpackChunkName: "admin-media" */ '@/views/admin/media/events/EventsPage'),
          search: () =>
            import(/* webpackChunkName: "admin-media" */ '@/views/admin/media/events/EventsSearch'),
        },
        meta: { isAuth: true },
      },
    ],
  },
  {
    path: '/admin/media/news/new',
    name: 'admin.news.new',
    component: () =>
      import(/* webpackChunkName: "admin-media" */ '@/views/admin/media/news/NewsFormPage'),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/media/news/draft',
    name: 'admin.news.draft',
    component: () =>
      import(/* webpackChunkName: "admin-media" */ '@/views/admin/media/news/NewsDraftPage'),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/media/news/:id(\\d+)/edit',
    name: 'admin.news.edit',
    component: () =>
      import(/* webpackChunkName: "admin-media" */ '@/views/admin/media/news/NewsFormPage'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/media/news/:id(\\d+)/view',
    name: 'admin.news.view',
    component: () =>
      import(/* webpackChunkName: "admin-media" */ '@/views/admin/media/news/NewsViewPage'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/media/events/new',
    name: 'admin.events.new',
    component: () =>
      import(/* webpackChunkName: "admin-media" */ '@/views/admin/media/events/EventFormPage'),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/media/events/draft',
    name: 'admin.events.draft',
    component: () =>
      import(/* webpackChunkName: "admin-media" */ '@/views/admin/media/events/EventDraftPage'),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/media/events/:id(\\d+)/edit',
    name: 'admin.events.edit',
    component: () =>
      import(/* webpackChunkName: "admin-media" */ '@/views/admin/media/events/EventFormPage'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/media/events/:id(\\d+)/view',
    name: 'admin.events.view',
    components: {
      default: () =>
        import(/* webpackChunkName: "admin-media" */ '@/views/admin/media/events/EventViewPage'),
      tabs: () =>
        import(/* webpackChunkName: "admin-media" */ '@/views/admin/media/events/ViewTabs'),
    },
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/media/events/:id(\\d+)/tickets',
    name: 'admin.events.tickets',
    components: {
      default: () =>
        import(
          /* webpackChunkName: "admin-media" */ '@/views/admin/media/events/tickets/EventTicketsPage'
        ),
      search: () =>
        import(
          /* webpackChunkName: "admin-media" */ '@/views/admin/media/events/tickets/EventTicketSearch'
        ),
      tabs: () =>
        import(/* webpackChunkName: "admin-media" */ '@/views/admin/media/events/ViewTabs'),
    },
    props: {
      default: route => ({ eventId: parseInt(route.params.id, 10) }),
    },
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/media/events/:eventId(\\d+)/tickets/:id(\\d+)/view',
    name: 'admin.events.tickets.view',
    component: () =>
      import(
        /* webpackChunkName: "admin-media" */ '@/views/admin/media/events/tickets/EventTicketView'
      ),
    props: route => ({
      eventId: parseInt(route.params.eventId, 10),
      id: parseInt(route.params.id, 10),
    }),
    meta: { isAuth: true, backBtn: true },
  },
];
