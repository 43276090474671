import Vue from 'vue';
import { loadWith } from '@/store/factory/utils';

export function makeSearchModel({
  name,
  dataModel,
  params: defaultParams,
  withRelations = [],
  limit = 20,
}) {
  const initialState = () => ({
    searchMap: {},
  });

  return {
    namespaced: true,

    state: initialState,

    getters: {
      getSearchItems: (state, _, __, rootGetters) => search => {
        const key = state.searchMap[search];
        return key ? rootGetters[`${dataModel}/getItems`](key) : [];
      },

      getItem: (state, _, __, rootGetters) => id => {
        return rootGetters[`${dataModel}/getItem`](id);
      },
    },

    actions: {
      async fetchSearch({ commit, dispatch, getters }, { search }) {
        const key = `${name}-search-${search}`;
        const query = { ...defaultParams, search, limit: limit };
        commit('ADD_SEARCH', { search, key });
        await dispatch(`${dataModel}/fetchItems`, { key, query }, { root: true });
        loadWith(getters.getSearchItems(search), withRelations, dispatch);
      },

      async fetchValue({ dispatch }, params) {
        const key = `${name}-search-value`;
        const query = { ...defaultParams, ...params };
        await dispatch(`${dataModel}/fetchItems`, { key, query }, { root: true });
      },

      async fetchItem({ dispatch }, payload) {
        await dispatch(`${dataModel}/fetchItem`, payload, { root: true });
      },
    },

    mutations: {
      ADD_SEARCH(state, { search, key }) {
        Vue.set(state.searchMap, search, key);
      },

      RESET(state) {
        const newState = initialState();
        Object.keys(newState).forEach(key => (state[key] = newState[key]));
      },
    },
  };
}
