import storyApi from '@/api/storyApi';

const initialState = () => ({
  loaded: false,
  loading: false,
  stories: [],
});

const state = initialState();

const getters = {
  loaded: state => state.loaded,
  loading: state => state.loading,
  stories: state => state.stories,
};

const actions = {
  async fetchStories({ commit }) {
    commit('SET_DATA', { loading: true });
    return storyApi
      .fetchUserStories()
      .then(res => {
        commit('SET_STORIES', res.data);
        commit('SET_DATA', { loaded: true, loading: false });
      })
      .catch(() => {
        commit('SET_DATA', { loading: false });
      });
  },

  async like({ commit, state }, id) {
    const item = state.stories.find(x => x.id === id);

    commit('SET_STORY', { ...item, liked: !item.liked });

    try {
      const res = !item.liked ? await storyApi.addLike(id) : await storyApi.deleteLike(id);
      commit('SET_STORY', res.data);
    } catch (e) {
      commit('SET_STORY', { ...item });
      throw e;
    }
  },

  async markViewed({ commit }, id) {
    return storyApi.markViewed(id).then(res => commit('SET_STORY', res.data));
  },
};

const mutations = {
  SET_STORIES(state, payload) {
    state.stories = payload;
  },

  SET_STORY(state, payload) {
    const idx = state.stories.findIndex(x => x.id === payload.id);
    if (idx > -1) {
      state.stories.splice(idx, 1, payload);
    }
  },

  SET_DATA(state, obj) {
    Object.keys(obj).forEach(key => (state[key] = obj[key]));
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
