import Vue from 'vue';
import dayjs from 'dayjs';
import servicesApi from '@/api/servicesApi';

const initialState = () => ({
  timezone: { 0: '' },
  calendar: { 0: {} },
  workDates: { 0: [] },
  workTime: { 0: { '2020-02-20': [] } },
});

const state = initialState;

const getters = {
  getWorkDates: state => serviceId => state.workDates?.[serviceId] || [],

  getWorkTime: state => (serviceId, date) => state.workTime?.[serviceId]?.[date],

  getTimezone: state => serviceId => state.timezone?.[serviceId] || '+05:00',
};

const actions = {
  async fetchWorkDatesMonth({ commit, state }, { serviceId, date }) {
    const calendar = state.calendar?.[serviceId] || {};

    const [yyyy, mm] = date.split('-');
    if (yyyy in calendar && mm in calendar[yyyy]) {
      return Promise.resolve();
    }

    const dt = dayjs.tz(date);
    const dateFrom = dt.startOf('month').format('YYYY-MM-DD');
    const dateTo = dt.endOf('month').format('YYYY-MM-DD');

    return servicesApi
      .fetchWorkDates({ serviceId, dateFrom, dateTo })
      .then(res => commit('ADD_WORK_DATES', { serviceId, data: res.data }));
  },

  async fetchWorkTime({ commit, state }, { serviceId, date }) {
    const workTime = state.workTime?.[serviceId]?.[date];
    if (workTime) {
      return Promise.resolve();
    }

    return servicesApi
      .fetchWorkTime({ serviceId, date })
      .then(res => commit('ADD_WORK_TIME', { serviceId, date, data: res.data }));
  },
};

const mutations = {
  ADD_WORK_DATES(state, { serviceId, data }) {
    const calendar = { ...state.calendar?.[serviceId] };
    const dates = data.dates || [];

    dates.forEach(x => {
      const [y, m, d] = x.split('-');
      if (!(y in calendar)) {
        calendar[y] = {};
      }
      if (!(m in calendar[y])) {
        calendar[y][m] = {};
      }
      calendar[y][m][d] = '+';
    });

    const workDates = [...new Set([...(state.workDates?.[serviceId] || []), ...dates])];

    Vue.set(state.timezone, serviceId, data.timezone);
    Vue.set(state.workDates, serviceId, workDates);
    Vue.set(state.calendar, serviceId, calendar);
  },

  ADD_WORK_TIME(state, { serviceId, date, data }) {
    const newData = { ...state.workTime?.[serviceId], [date]: data };
    Vue.set(state.workTime, serviceId, newData);
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
