import dayjs from 'dayjs';

export function makeDate(value) {
  if (typeof value === 'number') {
    return fromUnixTime(value);
  }

  if (value === null) {
    return null;
  }

  if (dayjs.isDayjs(value)) {
    return value.toDate();
  }

  if (typeof value === 'object') {
    return new Date(value);
  }

  let str = value;
  // new Date('2021-04-07') -> Wed Apr 07 2021 05:00:00 GMT+0500 (Yekaterinburg Standard Time)
  // new Date('2021-04-07 00:00') -> Wed Apr 07 2021 00:00:00 GMT+0500 (Yekaterinburg Standard Time)
  // but dayjs('2022-04-19T00:00').toDate() -> Tue Apr 19 2022 00:00:00 GMT+0500 (Yekaterinburg Standard Time)
  // if (str.length === 10) {
  //   str = `${str} 00:00`;
  // }
  if (str.includes('-')) {
    str = str.replace(' ', 'T');
  }
  if (str.includes('.')) {
    const parts = str.split(' ');
    parts[0] = parts[0].split('.').reverse().join('-');
    str = parts.join('T');
  }

  return dayjs.tz(str).toDate();
}

export const fromUnixTime = time => {
  return new Date(time * 1000);
};

/*
export const dateToUnixTime = value => {
  return Math.round(makeDate(value).getTime() / 1000);
};

export const formatDateYmd = value => {
  let date = value;

  if (typeof value === 'number') {
    date = fromUnixTime(value);
  }

  if (typeof value === 'string') {
    date = new Date(value);
  }

  let year = String(date.getFullYear());
  let month = String(date.getMonth() + 1).padStart(2, '0');
  let day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const formatTime = date => {
  let hh = String(date.getHours()).padStart(2, '0');
  let mm = String(date.getMinutes()).padStart(2, '0');

  return `${hh}:${mm}`;
};

export function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function addMinutes(date, minutes) {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() + minutes);
  return result;
}

// ms
export function roundTime(time, roundTo = 60, down = true) {
  const roundDownTime = roundTo * 60 * 1000;
  return down ? time - (time % roundDownTime) : time + (roundDownTime - (time % roundDownTime));
}
*/

export function getNights(start, end) {
  if (!start || !end) {
    return null;
  }

  return dayjs
    .unix(end - 1)
    .tz()
    .startOf('d')
    .diff(dayjs.unix(start).tz().startOf('d'), 'day');
}
