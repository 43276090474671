const initialState = () => ({
  images: [],
  selected: 0,
});

const state = initialState();

const getters = {
  images: state => state.images,
  selected: state => state.selected,
};

const actions = {
  showGallery({ commit }, { images, selected }) {
    commit('SET_IMAGES', images);
    commit('SET_SELECTED', selected || 0);
  },

  selectImage({ commit }, idx) {
    commit('SET_SELECTED', idx);
  },

  next({ state, commit }) {
    if (state.selected + 1 < state.images.length) {
      commit('SET_SELECTED', state.selected + 1);
    }
  },

  prev({ state, commit }) {
    if (state.selected - 1 >= 0) {
      commit('SET_SELECTED', state.selected - 1);
    }
  },
};

const mutations = {
  SET_IMAGES(state, images) {
    state.images = images ? [...images] : null;
  },

  SET_SELECTED(state, idx) {
    state.selected = idx + 1 <= state.images.length ? idx : state.images.length - 1;
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
