const config = {
  // name: 'main',
  dataModel: 'db/employees',
  params: {
    sort: 'id',
  },
};

export default {
  namespaced: true,

  getters: {
    getCompanyEmployees: (state, _, __, rootGetters) => companyId =>
      rootGetters[config.dataModel + '/getItems'](`c-${companyId}`),

    getUserEmployments: (state, _, __, rootGetters) => userId => {
      if (userId && typeof userId !== 'number') {
        console.warn('[getUserEmployments] userId must be number');
      }

      return rootGetters[config.dataModel + '/getItems'](`u-${userId}`);
    },

    getEmployments: (state, _, __, rootGetters) => userId => {
      if (userId && typeof userId !== 'number') {
        console.warn('[getEmployments] userId must be number');
      }

      return rootGetters[config.dataModel + '/allItems'].filter(x => x.userId === userId);
    },
  },

  actions: {
    async fetchCompanyEmployees({ dispatch, rootGetters }, { companyId }) {
      if (!rootGetters['permissions/canAccess']('profile:employee.list')) {
        return;
      }

      const key = `c-${companyId}`;
      const payload = {
        _key: key,
        company: companyId,
        expand: 'profile',
        withProfiles: true,
        ...config.params,
      };
      await dispatch(`${config.dataModel}/fetchItems`, payload, { root: true });
    },

    async fetchUserEmployments({ dispatch, rootGetters }, { userId }) {
      if (!rootGetters['permissions/canAccess']('profile:employee.list')) {
        return;
      }

      const key = `u-${userId}`;
      const payload = {
        _key: key,
        profile: userId,
        expand: 'company',
        withCompanies: true,
        ...config.params,
      };
      await dispatch(`${config.dataModel}/fetchItems`, payload, { root: true });
    },

    async fetchEmployments({ dispatch, rootGetters }, { userIds }) {
      if (!rootGetters['permissions/canAccess']('profile:employee.list')) {
        return;
      }

      const key = `emp`;
      const payload = {
        _key: key,
        profile: userIds.join(','),
        expand: 'company',
        withCompanies: true,
        ...config.params,
      };
      await dispatch(`${config.dataModel}/fetchItems`, payload, { root: true });
    },
  },
};
