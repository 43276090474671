export default [
  {
    path: '/admin/registry',
    name: 'admin.registry',
    components: {
      default: () =>
        import(/* webpackChunkName: "admin-registry" */ '@/views/admin/registry/RoutePage.vue'),
      tabs: () =>
        import(/* webpackChunkName: "admin-registry" */ '@/views/admin/registry/RouteTabs.vue'),
    },
    meta: { isAuth: true },
    children: [
      {
        path: 'daily-payment',
        name: 'admin.registry.daily-payment',
        component: () =>
          import(
            /* webpackChunkName: "admin-registry" */ '@/views/admin/registry/DailyPaymentPage'
          ),
        meta: { isAuth: true },
      },
      {
        path: 'monthly-bill',
        name: 'admin.registry.monthly-bill',
        component: () =>
          import(/* webpackChunkName: "admin-registry" */ '@/views/admin/registry/MonthlyBillPage'),
        meta: { isAuth: true },
      },
      {
        path: 'daily-poster',
        name: 'admin.registry.daily-poster',
        component: () =>
          import(/* webpackChunkName: "admin-registry" */ '@/views/admin/registry/DailyPosterPage'),
        meta: { isAuth: true },
      },
      {
        path: 'payments',
        name: 'admin.registry.payments',
        component: () =>
          import(/* webpackChunkName: "admin-registry" */ '@/views/admin/registry/PaymentsPage'),
        meta: { isAuth: true },
      },
      {
        path: 'docs',
        name: 'admin.registry.docs',
        component: () =>
          import(/* webpackChunkName: "admin" */ '@/views/admin/registry/docs/DocsPage.vue'),
        meta: { isAuth: true },
      },
    ],
  },
  {
    path: '/admin/registry/docs/new',
    name: 'admin.registry.docs.new',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/registry/docs/DocForm'),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/registry/docs/:id(\\d+)/edit',
    name: 'admin.registry.docs.edit',
    component: () =>
      import(/* webpackChunkName: "admin-cap" */ '@/views/admin/registry/docs/DocForm'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
];
