const initialState = () => ({
  loaded: false,
  schemes: [],
  units: [],
});

const state = initialState();

const getters = {
  loaded: state => state.loaded,
  schemes: state => state.schemes,
  units: state => state.units,
};

const actions = {
  async fetchSchemesWithUnits({ commit, dispatch, rootGetters }, { locationId }) {
    await Promise.all([
      dispatch('db/units/fetchItems', { locationId }, { root: true }),
      dispatch('db/floorSchemes/fetchItems', {}, { root: true }),
    ]);

    let units = rootGetters['db/units/items'].filter(x => x.showOnSite);
    let schemes = rootGetters['db/floorSchemes/items'];

    schemes = schemes
      .map(scheme => ({
        ...scheme,
        unit: units.find(x => x.id === scheme.unitId),
        content: scheme.content
          .map(item => ({ ...item, unit: units.find(x => x.id === item.unitId) }))
          .filter(x => x.unit),
      }))
      .filter(x => x.unit);

    commit('SET_DATA', { units, schemes, loaded: true });
  },
};

const mutations = {
  SET_DATA(state, obj) {
    Object.keys(obj).forEach(key => (state[key] = obj[key]));
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
