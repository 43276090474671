<template>
  <v-sheet height="80%" class="d-flex flex-column align-center justify-center">
    <div class="text-h1 mb-4">{{ $t('Такой страницы нет') }}</div>
    <div>
      <BtnAction to="/">{{ $t('Перейти на главную') }}</BtnAction>
    </div>
  </v-sheet>
</template>

<script>
import BtnAction from '@/components/ui/BtnAction';

export default {
  name: 'NotFound',
  components: { BtnAction },
};
</script>
