<template>
  <v-row v-if="stories.length">
    <v-col>
      <v-slide-group
        v-model="slide"
        class="stories"
        prev-icon="mdi-arrow-left-drop-circle"
        next-icon="mdi-arrow-right-drop-circle"
        :show-arrows="!$vuetify.breakpoint.xsOnly"
      >
        <template #prev>
          <v-icon large>mdi-arrow-left-drop-circle</v-icon>
        </template>

        <template #next="">
          <v-icon large>mdi-arrow-right-drop-circle</v-icon>
        </template>

        <v-slide-item v-for="(item, idx) of stories" :key="item.id">
          <StoryCard
            :story="item.story"
            :viewed="item.viewed"
            :href="`/services/story/${item.id}`"
            class="mx-4"
            @click.prevent="shownIdx = idx"
          />
        </v-slide-item>
      </v-slide-group>

      <StoryDialog
        v-if="shownItem"
        :key="shownIdx"
        :value="true"
        :story="shownItem.story"
        :liked="shownItem.liked"
        :transition="dialogTransition"
        @next="next"
        @prev="prev"
        @like="like(shownItem.id)"
        @viewed="!shownItem.viewed && markViewed(shownItem.id)"
        @close="shownIdx = null"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import StoryDialog from '@/components/story/StoryDialog';
import StoryCard from '@/components/story/StoryCard';

export default {
  name: 'StoriesFeed',

  components: { StoryCard, StoryDialog },

  data: () => ({
    slide: null,
    shownIdx: null,
    dialogTransition: 'dialog-transition',
  }),

  computed: {
    ...mapGetters('stories', ['stories']),

    shownItem() {
      return this.stories[this.shownIdx];
    },
  },

  watch: {
    shownItem(newVal, oldVal) {
      if (!oldVal) {
        this.dialogTransition = 'dialog-transition';
      } else {
        this.dialogTransition = 'slide-x-reverse-transition';
      }
    },
  },

  methods: {
    ...mapActions('stories', ['markViewed', 'like']),

    next() {
      if (this.shownIdx + 1 < this.stories.length) {
        this.shownIdx++;
      } else {
        this.shownIdx = null;
      }
    },

    prev() {
      if (this.shownIdx - 1 >= 0) {
        this.shownIdx--;
      } else {
        this.shownIdx = null;
        this.$nextTick(() => (this.shownIdx = 0));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stories::v-deep {
  overflow-x: hidden;

  .v-slide-group__wrapper {
    margin: 0 -16px;
  }

  .v-slide-group__content {
    //margin: 0 -16px;
  }

  .v-slide-group__prev {
    margin-right: -56px;
  }

  .v-slide-group__next {
    margin-left: -56px;
  }

  .v-slide-group__prev,
  .v-slide-group__next {
    background: #e5ebf4;
    opacity: 0.8;
    z-index: 1;
  }

  .v-slide-group__prev--disabled,
  .v-slide-group__next--disabled {
    opacity: 0.5;
  }

  .v-slide-group__prev {
    border-radius: 4px 0 0 4px;
  }
  .v-slide-group__next {
    border-radius: 0 4px 4px 0;
  }
}
</style>
