<template>
  <v-main :class="[backLight && 't-back-light']">
    <slot />
  </v-main>
</template>

<script>
export default {
  name: 'AppMain',

  props: {
    backLight: Boolean,
  },

  watch: {
    backLight: {
      immediate: true,
      handler(val) {
        window.document.body.classList.toggle('t-back-light', val);
      },
    },
  },
};
</script>
