import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { VTextField, VTextarea } from 'vuetify/lib';
import i18n from './i18n';
import brand from '@/brand';
import '@/styles/main.scss';

Vuetify.config.silent = process.env.VUE_APP_ENV !== 'local';

VTextField.options.props.color = { type: String, default: 'secondary' };
VTextarea.extendOptions.props.autoGrow = { type: Boolean, default: true };
VTextarea.extendOptions.props.rows = {
  ...VTextarea.extendOptions.props.rows,
  default: 1,
};

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 1280,
    scrollBarWidth: 16,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920,
    },
  },
  icons: {
    iconfont: 'mdi', // default
    values: {
      subgroup: 'mdi-chevron-down',
      sort: 'mdi-sort-reverse-variant',
    },
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    options: {
      customProperties: true,
      // variations: false,
    },
    themes: brand.themes,
  },
});
