<template>
  <component :is="appLayout" v-if="!showLoader">
    <router-view />
  </component>

  <AppLoader v-else />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Layout } from '@/layouts';
import AppLayout from '@/layouts/AppLayout';
import SimpleLayout from '@/layouts/SimpleLayout';
import AppLoader from '@/components/ui/AppLoader';

export default {
  name: 'App',

  components: { AppLoader },

  metaInfo() {
    return {
      titleTemplate: titleChunk => {
        return titleChunk ? `${titleChunk} — ${this.appTitle}` : this.appTitle;
      },
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        { name: 'msapplication-TileColor', content: '#ffffff' },
        { name: 'theme-color', content: '#ffffff' },
        { name: 'application-name', content: this.appTitle },
      ],
    };
  },

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapGetters('appBrand', ['brand']),

    ...mapGetters('auth', ['userId', 'locationId', 'location']),

    ...mapGetters('profile', ['profile', 'isInitialized']),

    ...mapGetters('acceptOffers', ['needAccept']),

    ...mapGetters('locationOffers', ['isLocationAccepted']),

    appTitle() {
      return process.env.VUE_APP_TITLE;
    },

    showLoader() {
      return this.loading || (this.userId > 0 && !this.isInitialized);
    },

    appLayout() {
      let layout;
      const route = this.$route.matched.find(item => item.meta.layout);
      if (route) {
        layout = route.meta.layout;
      } else {
        const isAuthRoute = this.$route.matched.some(item => item.meta.isAuth);
        layout = isAuthRoute ? Layout.APP : null;
      }

      switch (layout) {
        case Layout.APP:
          return AppLayout;
        case Layout.SIMPLE:
          return SimpleLayout;
        default:
          return SimpleLayout;
      }
    },

    userSensData() {
      return [this.profile?.userId, this.location, this.isLocationAccepted(this.locationId)].join(
        ','
      );
    },
  },

  watch: {
    needAccept(val) {
      if (val) {
        if (this.$route.name !== 'termsOfUse') {
          this.$router.replace({ name: 'termsOfUse' });
        }
      }
    },

    isInitialized(val) {
      if (!val) {
        return;
      }

      const locationAccepted = this.locationId && this.isLocationAccepted(this.locationId);
      if ((!locationAccepted || !this.location) && !this.brand.monoBrand) {
        this.$router.replace({ name: 'auth.location' });
      } else if (!this.profile) {
        this.$router.replace({ name: 'auth.register' });
      } else if (!locationAccepted || !this.location) {
        this.$router.replace({ name: 'auth.location' });
      } else if (this.$route.name && !this.$route.meta.isAuth) {
        this.$router.replace(this.$brandConfig.homePage ?? { name: 'home' });
      }

      // // direct open /auth
      // this.$router.onReady(() => {
      //   if (this.profile && this.$route.name && !this.$route.meta.isAuth) {
      //     console.log('direct open', { name: 'home' });
      //     this.$router.replace({ name: 'home' });
      //   }
      // });
    },

    userSensData: {
      immediate: true,
      handler() {
        if (this.profile?.userId && this.location && this.isLocationAccepted(this.locationId)) {
          this.loading = true;
          Promise.all([this.fetchUserOperations(), this.fetchEmployments()]).finally(
            () => (this.loading = false)
          );
        }
      },
    },
  },

  methods: {
    ...mapActions('appBrand', ['fetchBrand']),

    ...mapActions('permissions', ['fetchUserOperations']),

    ...mapActions('profile', ['fetchEmployments']),
  },
};
</script>
