<template>
  <v-menu bottom left>
    <template #activator="{ on, attrs }">
      <v-btn
        :small="small"
        icon
        class="mr-n2 mb-n2 dots"
        absolute
        bottom
        right
        v-bind="attrs"
        v-on="on"
        @click.prevent="() => {}"
        @mousedown.stop="() => {}"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <slot />
  </v-menu>
</template>

<script>
export default {
  name: 'CardMenu',
  props: {
    small: Boolean,
  },
};
</script>
