import { makeSimpleList } from '@/store/factory/simpleList';

export default makeSimpleList(
  {
    name: 'main',
    dataModel: 'db/profiles',
  },
  {
    actions: {
      async fetchProfiles({ dispatch, rootGetters }, ids) {
        const userIds = [];
        for (const id of ids) {
          if (id && userIds.indexOf(id) === -1 && !rootGetters['db/profiles/getItem'](id)) {
            userIds.push(id);
          }
        }

        if (!userIds.length) {
          return Promise.resolve();
        }

        return dispatch(
          'db/profiles/fetchItems',
          { query: { userId: userIds.join(','), limit: userIds.length } },
          { root: true }
        );
      },
    },
  }
);
