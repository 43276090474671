import http from './http';

const baseUrl = (process.env.VUE_APP_API_EVENT || '').replace(/\/$/g, '');

const eventApi = {
  fetchTags(params) {
    return http.get(`${baseUrl}/tag`, { params });
  },

  event: {
    list: params => http.get(`${baseUrl}/event`, { params }),
    find: ({ id }) => http.get(`${baseUrl}/event/${id}`),
  },

  eventCategory: {
    list: params => http.get(`${baseUrl}/category`, { params }),
    find: ({ id }) => http.get(`${baseUrl}/category/${id}`),
  },

  registration: {
    register: ({ id }) => http.post(`${baseUrl}/event/${id}/register`),
    cancel: ({ id }) => http.delete(`${baseUrl}/event/${id}/register`),
  },

  eventLike: {
    create: ({ id }) => http.put(`${baseUrl}/event-like/${id}`),
    delete: ({ id }) => http.delete(`${baseUrl}/event-like/${id}`),
  },

  userEvent: {
    list: params => http.get(`${baseUrl}/user-event`, { params }),
    create: ({ id }) => http.post(`${baseUrl}/user-event/${id}`),
    delete: ({ id }) => http.delete(`${baseUrl}/user-event/${id}`),
  },

  admin: {
    event: {
      list: params => http.get(`${baseUrl}/admin/event`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/admin/event/${id}`),
      create: data => http.post(`${baseUrl}/admin/event`, data),
      update: ({ id, ...data }) => http.patch(`${baseUrl}/admin/event/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/admin/event/${id}`),
      cancel: ({ id }) => http.post(`${baseUrl}/admin/event/${id}/cancel`),
    },

    eventCategory: {
      list: params => http.get(`${baseUrl}/admin/category`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/admin/category/${id}`),
      create: data => http.post(`${baseUrl}/admin/category`, data),
      update: ({ id, ...data }) => http.patch(`${baseUrl}/admin/category/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/admin/category/${id}`),
    },

    ticket: {
      list: params => http.get(`${baseUrl}/admin/tickets/search`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/admin/ticket/${id}`),
      checkin: ({ id }) => http.post(`${baseUrl}/admin/ticket/${id}/checkin`),
    },
  },
};

export default eventApi;
