import http from './http';

const baseUrl = (process.env.VUE_APP_API_OTRS || '').replace(/\/$/g, '');

const coworkingApi = {
  tickets: {
    list: params => http.get(`${baseUrl}/coworking/ticket`, { params }),
    find: ({ id }) => http.get(`${baseUrl}/coworking/ticket/${id}`),
    create: data => http.post(`${baseUrl}/coworking/ticket`, data),
    // update: ({ id, ...data }) => http.put(`${baseUrl}/coworking/ticket/${id}`, data),
    // delete: ({ id }) => http.delete(`${baseUrl}/coworking/ticket/${id}`),
    link: ({ hash }) => http.patch(`${baseUrl}/coworking/ticket/${hash}/link`),
    updateUser: ({ id, userId }) =>
      http.put(`${baseUrl}/coworking/ticket/${id}/set-user`, { userId }),
  },

  fetchOccupation: ({ serviceId, ...params }) =>
    http.get(`${baseUrl}/coworking/occupation/service/${serviceId}`, { params }),

  admin: {
    tickets: {
      list: params => http.get(`${baseUrl}/coworking/admin/ticket`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/coworking/admin/ticket/${id}`),
      create: data => http.post(`${baseUrl}/coworking/admin/ticket`, data),
      update: ({ id, ...data }) => http.put(`${baseUrl}/coworking/admin/ticket/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/coworking/admin/ticket/${id}`),
      validate: ({ id }) => http.patch(`${baseUrl}/coworking/admin/ticket/${id}/validate`),
      invalidate: ({ id }) => http.patch(`${baseUrl}/coworking/admin/ticket/${id}/invalidate`),
      checkin: ({ id }) => http.patch(`${baseUrl}/coworking/admin/ticket/${id}/checkin`),
      checkout: ({ id }) => http.patch(`${baseUrl}/coworking/admin/ticket/${id}/checkout`),
      getFreePlaces: ({ id, ...params }) =>
        http.get(`${baseUrl}/coworking/admin/ticket/${id}/free_places`, { params }),
    },

    calendarTickets: {
      list: params => http.get(`${baseUrl}/coworking/admin/ticket/calendar`, { params }),
    },

    workPlaces: {
      list: params => http.get(`${baseUrl}/coworking/admin/work-place`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/coworking/admin/work-place/${id}`),
      create: data => http.post(`${baseUrl}/coworking/admin/work-place`, data),
      update: ({ id, ...data }) => http.put(`${baseUrl}/coworking/admin/work-place/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/coworking/admin/work-place/${id}`),
    },
  },
};

export default coworkingApi;
