<template>
  <div>{{ title }}</div>
</template>

<script>
export default {
  name: 'DefaultItem',
  props: ['title'],
};
</script>
