<template>
  <div class="d-flex flex-row align-center">
    <NotificationBell v-if="$hasRoute({ name: 'home.notifications' })" class="mr-2" />
    <ProfileMenu />
  </div>
</template>

<script>
import ProfileMenu from '@/components/layout/ProfileMenu';
import NotificationBell from '@/components/layout/NotificationBell';

export default {
  name: 'AppToolbar',
  components: { NotificationBell, ProfileMenu },
};
</script>
