import http from './http';

const baseUrl = (process.env.VUE_APP_API_DOC || '').replace(/\/$/g, '');

export default {
  template: {
    list: params => http.get(`${baseUrl}/template`, { params }),
    create: data => http.post(`${baseUrl}/template`, data),
    find: ({ id }) => http.get(`${baseUrl}/template/${id}`),
    update: ({ id, ...data }) => http.patch(`${baseUrl}/template/${id}`, data),
    delete: ({ id }) => http.delete(`${baseUrl}/template/${id}`),
  },

  document: {
    list: (params, type = 'document') => http.get(`${baseUrl}/${type}`, { params }),
    create: (data, type = 'document') => http.post(`${baseUrl}/${type}`, data),
    find: ({ id }, type = 'document') => http.get(`${baseUrl}/${type}/${id}`),
    update: ({ id, ...data }, type = 'document') => http.patch(`${baseUrl}/${type}/${id}`, data),
    delete: ({ id }, type = 'document') => http.delete(`${baseUrl}/${type}/${id}`),
  },

  contract: {
    list: params => http.get(`${baseUrl}/contract`, { params }),
  },
};
