import otrsApi from '@/api/otrsApi';
import { uploadFiles } from '@/api/storageApi';

export default {
  namespaced: true,

  getters: {
    getArticles: (state, _, __, rootGetters) => ticketId =>
      rootGetters[`db/ticketArticles/getItems`](ticketId),

    getFirstArticle: (state, _, __, rootGetters) => ticketId =>
      rootGetters[`db/ticketArticles/getItems`](`${ticketId}-1`)[0] ?? null,
  },

  actions: {
    async fetchArticles({ dispatch }, { ticketId, ...params }) {
      await dispatch(
        'db/ticketArticles/fetchItems',
        { key: ticketId, query: { ticketId, order: 'DESC', limit: 1000, ...params } },
        { root: true }
      );
    },

    async fetchFirstArticle({ dispatch }, { ticketId, ...params }) {
      await dispatch(
        'db/ticketArticles/fetchItems',
        { key: `${ticketId}-1`, query: { ticketId, order: 'ASC', limit: 1, ...params } },
        { root: true }
      );
    },

    async createArticle({ commit }, { ticketId, senderType, article, files = [] }) {
      const attachments = [];
      if (files && files.length > 0) {
        const urls = await uploadFiles(files);
        files.forEach((file, idx) => {
          attachments.push({
            filename: file.name,
            contentSize: file.size,
            contentType: file.type || 'application/octet-stream',
            url: urls[idx],
          });
        });
      }

      const { data } = await otrsApi.ticketArticle.create({
        ...article,
        ticketId,
        senderType,
        attachments,
      });

      commit('db/ticketArticles/ADD', data, { root: true });
    },

    addArticle({ commit }, { ticketId, article }) {
      commit(
        'db/ticketArticles/ADD_TO_LIST_START',
        { key: ticketId, item: article },
        { root: true }
      );
    },

    async markAsSeen(ctx, { ticketId, ...rest }) {
      await otrsApi.ticketArticle.markAsSeen({
        ticketId: String(ticketId),
        ...rest,
      });
    },
  },
};
