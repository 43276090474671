<template>
  <Card :to="to">
    <v-card-text class="px-2 d-flex flex-column align-center justify-center">
      <PolyImg :src="image" height="64" width="55" />
      <div class="pt-2 text-caption text-lg-body-2 text-center">
        {{ name }}
      </div>
    </v-card-text>
  </Card>
</template>

<script>
import Card from '@/components/ui/Card';
import PolyImg from '@/components/ui/PolyImg';

export default {
  name: 'SimpleServiceCard',
  components: { PolyImg, Card },
  props: {
    image: String,
    name: String,
    to: [Object, String],
  },
};
</script>
