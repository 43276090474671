<template>
  <ServiceBasicCard
    :service-id="service.id"
    :image="service.images[0]"
    :grayscale="!isWorkingTime"
    :title="service.name"
    :subtitle="subtitle"
    :to="{ name: 'services.service', params: { id: service.id } }"
    v-bind="$attrs"
  >
    <template v-if="!isWorkingTime" #notice>
      {{ $t('Сейчас не работает') }}
    </template>
    <template v-else-if="service.minPrice" #notice>
      {{ $t('от') }} {{ $n(service.minPrice, 'currency') }}
    </template>
  </ServiceBasicCard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import ServiceBasicCard from '@/views/services/card/ServiceBasicCard';
import { ServiceType } from '@/constants/service';

export default {
  name: 'ServiceCard',

  components: { ServiceBasicCard },

  props: {
    service: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('productionCalendar', ['getWorkTime']),

    ...mapGetters('serviceSlots', ['getServiceSlotsByDate']),

    subtitle() {
      return this.service.booking.useBusyStateAsDescription ? this.freeStatus : this.service.title;
    },

    hasSchedule() {
      if ([ServiceType.COWORKING, ServiceType.HOTEL].includes(this.service.type)) {
        return false;
      }

      return !!this.service.workBeginTime;
    },

    date() {
      return dayjs.tz().format('YYYY-MM-DD');
    },

    workBeginTime() {
      return dayjs.tz(`${this.date} ${this.service.workBeginTime}`);
    },

    workEndTime() {
      return dayjs.tz(`${this.date} ${this.service.workEndTime}`);
    },

    workTime() {
      return this.getWorkTime(this.service.id, this.date);
    },

    isWorkingTime() {
      if (!this.hasSchedule) {
        return true;
      }

      const now = dayjs().tz();
      const ts = now.unix();

      // while load
      if (!this.workTime) {
        return now >= this.workBeginTime && now <= this.workEndTime;
      }

      for (let range of this.workTime) {
        if (ts >= range.begin && ts < range.end) {
          return true;
        }
      }

      return false;
    },

    freeStatus() {
      const slots = this.getServiceSlotsByDate(this.service.id);
      if (!slots || !slots.length) {
        return null;
      }

      // текущее состояние - следующий за текущим временем слот
      const ts = dayjs().unix();
      const currentSlot = slots.find(x => x.slot >= ts);
      if (!currentSlot) {
        return null;
      }

      const nextSlots = slots.filter(x => x.slot >= ts);

      if (currentSlot.free) {
        const busySlot = nextSlots.find(x => !x.free);
        if (!busySlot) {
          return this.$t('Сегодня свободна');
        }

        return this.$t('Свободна до {0}', [this.$options.filters.time(busySlot.slot)]);
      }

      const freeSlot = nextSlots.find(x => x.free);
      if (!freeSlot) {
        return this.$t('Сегодня занята');
      }

      return this.$t('Занята до {0}', [this.$options.filters.time(freeSlot.slot)]);
    },
  },

  created() {
    if (this.hasSchedule) {
      this.fetchWorkTime({ serviceId: this.service.id, date: this.date });
    }

    if (this.service.booking.useBusyStateAsDescription) {
      const periodStart = dayjs.tz().startOf('d').unix();
      const periodEnd = periodStart + 86400;
      this.fetchServiceSlots({ serviceId: this.service.id, periodStart, periodEnd });
    }
  },

  methods: {
    ...mapActions('productionCalendar', ['fetchWorkTime']),

    ...mapActions('serviceSlots', ['fetchServiceSlots']),
  },
};
</script>
