import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/sentry';
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';
import './plugins/mask';
import './plugins/components';
import './plugins/analytics';
import './plugins/dayjs';
import './plugins/eventBus';
import './plugins/reactiveRefs';
import './directives';
import './filters';
import './mixins/global';

Vue.config.productionTip = false;

// TIP: vue-fragment breaks ckeditor

new Vue({
  router,
  store,
  vuetify,
  i18n,
  created() {
    store.dispatch('setLocale', store.getters.locale);
    store.dispatch('setTimezone', store.getters.timezone);
    if (store.getters['auth/isTokenSet']) {
      store.dispatch('auth/autoLogin');
    }
  },
  render: h => h(App),
}).$mount('#app');
