import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from 'vuetify/es5/locale/en';
import ru from 'vuetify/es5/locale/ru';

Vue.use(VueI18n);

const vuetifyLocales = { ru, en };

function loadLocaleMessages() {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);

      messages[locale]['$vuetify'] = {
        ...vuetifyLocales[locale],
        ...messages[locale]['$vuetify'],
      };
    }
  });

  return messages;
}

const dateFormats = {
  // 09.09.2020
  short: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
  // 09.09.2020, 10:00
  long: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h23',
  },
  // среда, 9 сентября
  weekdayDate: {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  },
  // ср, 09.09
  weekdayDateShort: {
    weekday: 'short',
    day: 'numeric',
    month: 'numeric',
  },
  // среда
  weekday: {
    weekday: 'long',
  },
  // 9 сентября
  dayAndMonth: {
    day: 'numeric',
    month: 'long',
  },
  // 9 сентября 2020 г.
  dateLong: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  },
  // среда, 9 сентября 2020 г.
  dateLongWithWeekday: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    weekday: 'long',
  },
  // 9 сентября 2020 г., 10:00
  dateTimeLong: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h23',
  },
  // 10:00
  time: {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h23',
  },
  // 24:00
  time24: {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h24',
  },
  // сентябрь
  month: {
    month: 'long',
  },
  // сентябрь 2020 г.
  monthYear: {
    month: 'long',
    year: 'numeric',
  },
};

const dateTimeFormats = {
  en: dateFormats,
  ru: dateFormats,
};

const numberFormats = {
  ru: {
    currency: {
      style: 'currency',
      currency: 'RUB',
      minimumFractionDigits: 0,
    },
  },
  en: {
    currency: {
      style: 'currency',
      currency: 'RUB',
      minimumFractionDigits: 0,
    },
  },
};

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: loadLocaleMessages(),
  dateTimeFormats,
  numberFormats,
  pluralizationRules: {
    ru: function (choice, choicesLength) {
      // // this === VueI18n экземпляра, так что свойство locale также существует здесь
      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    },
  },
});

export default i18n;
