import brandConfig from '@/brand';
import { RG } from '@/constants/routeGroup';
import NotFound from '@/views/NotFound';
import commonRoutes from '@/router/routes/common';
import userRoutes from '@/router/routes/user';
import adminRoutes from '@/router/routes/admin';
import operatorRoutes from '@/router/routes/operator';

export default [
  ...commonRoutes,
  {
    path: '/',
    redirect: brandConfig.homePage ?? '/home',
    meta: { isAuth: true },
  },
  ...userRoutes.map(x => ({
    ...x,
    meta: { group: RG.user, isAuth: true, ...x.meta },
  })),
  ...adminRoutes.map(x => ({
    ...x,
    meta: { group: RG.admin, isAuth: true, ...x.meta },
  })),
  ...operatorRoutes.map(x => ({
    ...x,
    meta: { group: RG.operator, isAuth: true, ...x.meta },
  })),
  {
    path: '/404',
    name: '404',
    component: NotFound,
  },
  {
    path: '*',
    component: NotFound,
  },
];
