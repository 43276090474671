<template>
  <g>
    <path
      d="M16.3773 23.0558C14.5826 23.1582 9.61217 23.6629 5.26168 19.3628C3.79728 17.9735 2.42198 16.167 1.08008 14.1706C1.33373 13.7286 1.60286 13.2873 1.88744 12.8465C2.75977 13.9639 4.31697 15.9603 6.28805 17.737C8.97741 20.158 11.6501 21.2419 14.5343 21.2531H15.2303C15.3268 21.037 15.4252 20.8191 15.5199 20.6012C17.3963 16.2844 17.2385 12.9154 15.57 11.6304C15.354 11.4652 15.1109 11.3391 14.8517 11.2579C14.7291 11.214 14.6224 11.1344 14.5451 11.0294C14.4678 10.9243 14.4234 10.7985 14.4176 10.668C14.4118 10.5376 14.4449 10.4083 14.5126 10.2968C14.5804 10.1853 14.6797 10.0965 14.7979 10.0419C15.6576 9.65318 16.5518 9.34641 17.4687 9.12558C17.6234 9.0864 17.7872 9.10895 17.9257 9.18849C18.0641 9.26803 18.1664 9.39836 18.2111 9.55206C19.9984 15.2955 17.4557 21.3574 16.3773 23.0558Z"
    />
    <path
      d="M2.44239 10.8818C3.04931 9.48501 5.96882 5.65607 12.3554 2.86444C14.3431 1.95958 16.4255 1.28058 18.5637 0.840088C18.7258 1.4112 18.8947 1.99163 19.0704 2.58136C16.9801 3.07602 14.9411 3.76818 12.9808 4.64855C9.43771 6.2278 6.69452 8.10317 5.14289 10.3957C5.01854 10.5819 4.89418 10.7682 4.77169 10.9544L5.2227 11.5261C8.20346 15.3476 10.8297 16.9939 12.8138 16.1019C13.0667 15.9883 13.3004 15.8356 13.5061 15.6493C13.5977 15.5595 13.7153 15.5009 13.842 15.4821C13.9688 15.4632 14.0982 15.4849 14.2119 15.5441C14.3257 15.6033 14.4179 15.697 14.4754 15.8119C14.533 15.9267 14.5529 16.0569 14.5325 16.1838C14.3671 17.1289 14.1091 18.0552 13.7622 18.9494C13.7086 19.1019 13.6041 19.2311 13.4665 19.3153C13.3288 19.3994 13.1665 19.4333 13.0068 19.4112C7.7673 18.3869 3.78243 12.5057 2.44239 10.8818Z"
    />
    <path
      d="M20.8002 4.62993C21.3719 6.19056 23.6381 11.7477 22.4706 17.6662C22.0161 19.6557 21.1505 21.5274 19.9297 23.1601C19.3265 23.1228 18.7308 23.08 18.1368 23.0353C19.2353 21.277 20.0321 19.3463 20.494 17.3236C21.2167 14.0861 21.0062 10.7091 19.8871 7.5873C19.8017 7.35637 19.7182 7.12731 19.6346 6.89824L18.9628 6.98949C14.4601 7.67669 11.4236 9.35837 11.0747 11.4852C11.0265 11.7608 11.0221 12.0424 11.0617 12.3195C11.1471 12.9098 10.4826 13.314 10.0483 12.9303C9.34614 12.2963 8.70028 11.6021 8.11808 10.8557C8.01553 10.7326 7.96169 10.5759 7.96682 10.4155C7.97196 10.2551 8.0357 10.1022 8.14592 9.98598C12.2941 6.02481 19.1409 4.75098 20.8002 4.62993Z"
    />
  </g>
</template>

<script>
export default {
  name: 'IconOlvery',
};
</script>
