import { loadWith } from '@/store/factory/utils';

export function makeLoadableList(
  { name, dataModel, params: defaultParams, limit = 20, withRelations = [] },
  ext = {}
) {
  const initialState = () => ({
    keys: [],
    ...ext.state,
  });

  return {
    namespaced: true,

    state: initialState,

    getters: {
      items(state, _, __, rootGetters) {
        return state.keys
          .map(key => rootGetters[`${dataModel}/getItems`](key))
          .reduce((res, items) => [...res, ...items], []);
      },

      meta(state, _, __, rootGetters) {
        const meta = rootGetters[`${dataModel}/getMeta`](state.keys[state.keys.length - 1]);
        return meta || { page: 0, pageCount: 0, pageSize: 0, totalCount: 0 };
      },

      getItem(state, _, __, rootGetters) {
        return id => rootGetters[`${dataModel}/getItem`](id);
      },

      ...ext.getters,
    },

    actions: {
      async fetchItems({ commit, dispatch, rootGetters }, params) {
        const query = { ...defaultParams, ...params, limit: limit, offset: 0 };
        const key = `${name}-${JSON.stringify(query)}`;

        const exists = rootGetters[`${dataModel}/hasKey`](key);
        if (exists) {
          commit('SET_KEY', { key });
        }

        await dispatch(`${dataModel}/fetchItems`, { key, query }, { root: true });
        if (!exists) {
          commit('SET_KEY', { key });
        }

        const items = rootGetters[`${dataModel}/getItems`](key);
        loadWith(items, withRelations, dispatch);
      },

      async fetchMore({ commit, dispatch, getters, rootGetters }, params) {
        const query = { ...defaultParams, ...params, limit: limit, offset: getters.items.length };
        const key = `${name}-${JSON.stringify(query)}`;

        const exists = rootGetters[`${dataModel}/hasKey`](key);
        if (exists) {
          commit('ADD_KEY', { key });
        }

        await dispatch(`${dataModel}/fetchItems`, { key, query }, { root: true });
        if (!exists) {
          commit('ADD_KEY', { key });
        }

        const items = rootGetters[`${dataModel}/getItems`](key);
        loadWith(items, withRelations, dispatch);
      },

      async fetchItem({ dispatch, rootGetters }, payload) {
        const key = await dispatch(`${dataModel}/fetchItem`, payload, { root: true });
        const item = rootGetters[`${dataModel}/getItem`](key);
        loadWith([item], withRelations, dispatch);
      },

      resetList({ commit }) {
        commit('RESET');
      },

      ...ext.actions,
    },

    mutations: {
      SET_KEY(state, { key }) {
        state.keys = [key];
      },

      ADD_KEY(state, { key }) {
        state.keys.push(key);
      },

      RESET(state) {
        const newState = initialState();
        Object.keys(newState).forEach(key => (state[key] = newState[key]));
      },

      ...ext.mutations,
    },
  };
}
