import Vue from 'vue';
import router from '@/router';
import authApi from '@/api/authApi';
import profileApi from '@/api/profileApi';
import storageApi from '@/api/storageApi';
import { toBase64 } from '@/utils/utils';
import { loadWith } from '@/store/factory/utils';

const initialState = () => ({
  userId: null,
  profile: null,
  loading: null,
  oauthServices: [],
  serviceInfo: {},
  userInfo: null,
  isInitialized: false,
});

const state = initialState;

const MEMBER_REQUESTS_KEY = 'user';
const EMPLOYMENTS_KEY = 'items';

const getters = {
  isInitialized: state => state.isInitialized,

  profile: state => state.profile,

  loading: state => state.loading,

  oauthServices: state => state.oauthServices,

  serviceInfo: state => state.serviceInfo,

  userInfo: state => state.userInfo,

  memberRequests: (_, __, ___, rootGetters) => {
    return rootGetters['db/memberRequests/getItems'](MEMBER_REQUESTS_KEY).map(x => ({
      ...x,
      company: rootGetters['db/companies/getItem'](x.companyId),
    }));
  },

  employments: (_, __, ___, rootGetters) => {
    return rootGetters['db/myEmployees/getItems'](EMPLOYMENTS_KEY)
      .map(x => ({
        ...x,
        company: rootGetters['db/companies/getItem'](x.companyId),
      }))
      .filter(x => x.company);
  },

  userIsResident: (state, getters) => getters.employments.length > 0,
};

const actions = {
  initProfile({ commit, dispatch }, userId) {
    commit('RESET');
    commit('SET_USER', userId);

    return dispatch('fetchProfile');
  },

  async fetchProfile({ commit, state }) {
    commit('LOADING', true);

    return profileApi.my.profile
      .fetch(state.userId)
      .then(res => {
        commit('LOADING', false);
        commit('SET_PROFILE', res.data);

        return res.data;
      })
      .catch(e => {
        commit('LOADING', false);
        if (e.response && e.response.status === 404) {
          commit('SET_PROFILE', null);

          return null;
        } else {
          throw e;
        }
      });
  },

  async saveProfile({ commit, state }, { profile, photo }) {
    let res;
    const data = { ...profile };

    if (photo) {
      res = await storageApi.upload({
        content: await toBase64(photo.file),
      });

      data.photoUrl = res.data.url;
    }

    res = state.profile
      ? await profileApi.my.profile.update(state.userId, data)
      : await profileApi.my.profile.create({ userId: state.userId, ...data });

    commit('SET_PROFILE', res.data);
  },

  // user oauth services
  async fetchOauthServices({ commit, state }) {
    return authApi
      .fetchUserServices(state.userId)
      .then(res => commit('OAUTH_SERVICES', res.data.services));
  },

  async fetchServiceInfo({ commit, state }, payload) {
    return authApi
      .fetchOauthInfo(state.userId, payload.service, payload.token)
      .then(res => commit('SERVICE_INFO', { service: payload.service, data: res.data }));
  },

  async attachOauthService({ state, dispatch }, payload) {
    await authApi.attachOauthService(state.userId, payload);
    await dispatch('fetchOauthServices');
    if (router.currentRoute.name !== 'profile.personal') {
      await router.replace({ name: 'profile.personal' });
    }
  },

  async detachOauthService({ commit, state }, { service }) {
    await authApi.detachOauthService(state.userId, service);
    commit('SERVICE_INFO', { service, data: null });
    commit('SERVICE_DETACHED', service);
  },

  async fetchOauthInfo({ commit, state }, payload) {
    return authApi
      .fetchOauthInfo(state.userId, payload.service, payload.token)
      .then(res => commit('USER_INFO', res.data));
  },

  async clearUserInfo({ commit }) {
    commit('USER_INFO', null);
  },

  async fetchMemberRequests({ dispatch, rootGetters }) {
    await dispatch('db/memberRequests/fetchItems', { key: MEMBER_REQUESTS_KEY }, { root: true });
    const items = rootGetters['db/memberRequests/getItems'](MEMBER_REQUESTS_KEY);
    const companyIds = items.map(x => x.companyId);
    if (companyIds.length) {
      await dispatch(
        'db/companies/fetchItems',
        { query: { id: companyIds.join(','), limit: companyIds.length } },
        { root: true }
      );
    }
  },

  async updateMemberRequest({ dispatch }, payload) {
    return dispatch('db/memberRequests/update', payload, { root: true });
  },

  async fetchEmployments({ dispatch, rootGetters }) {
    await dispatch(
      'db/myEmployees/fetchItems',
      {
        key: EMPLOYMENTS_KEY,
        query: { sort: 'id', expand: 'company' },
      },
      { root: true }
    );
    const items = rootGetters['db/myEmployees/getItems'](EMPLOYMENTS_KEY);
    loadWith(items, [{ field: 'companyId', dataModel: 'db/companies' }], dispatch);
  },
};

const mutations = {
  SET_USER(state, payload) {
    state.userId = payload;
  },

  LOADING(state, payload) {
    state.loading = payload;
  },

  SET_PROFILE(state, payload) {
    state.profile = payload;
    state.isInitialized = true;
  },

  OAUTH_SERVICES(state, payload) {
    state.oauthServices = payload;
  },

  SERVICE_INFO(state, { service, data }) {
    Vue.set(state.serviceInfo, service, data);
  },

  SERVICE_DETACHED(state, service) {
    state.oauthServices = state.oauthServices.filter(x => x.serviceId !== service);
  },

  USER_INFO(state, payload) {
    state.userInfo = payload;
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
