<template>
  <v-icon
    :key="value ? 'on' : 'off'"
    :color="value && !disabled ? 'primary' : 't-grey'"
    :disabled="disabled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    {{ value ? iconOn : iconOff }}
  </v-icon>
</template>

<script>
export default {
  name: 'SwitchIcon',
  props: {
    disabled: Boolean,
    iconOn: { type: String, required: true },
    iconOff: { type: String, required: true },
    value: { type: Boolean, required: true },
  },
};
</script>
