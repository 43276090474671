import Vue from 'vue';
import coworkingApi from '@/api/coworkingApi';

const initialState = () => ({
  occupation: {
    // key => [{startTime, free}, ...],
  },
});

const getKey = params => {
  return Object.keys(params)
    .sort()
    .filter(key => params[key])
    .map(key => [key, params[key]].join('='))
    .join('&');
};

const state = initialState();

const getters = {
  getTotalOccupation: state => params => {
    return state.occupation[getKey(params)] ?? [];
  },

  getOccupationForPeriod: (state, getters) => (params, time1, time2) => {
    return getters
      .getTotalOccupation(params)
      .filter(x => x.startTime >= time1 && x.startTime < time2);
  },
};

const actions = {
  async fetchOccupation({ commit }, { periodStart, periodEnd, ...params }) {
    return coworkingApi
      .fetchOccupation({ ...params, periodStart, periodEnd })
      .then(res => commit('ADD_DATA', { params, data: res.data }));
  },
};

const mutations = {
  ADD_DATA(state, { params, data }) {
    const prevData = state.occupation[getKey(params)] ?? [];

    const newData = prevData
      .filter(x => !data.find(y => y.startTime === x.startTime))
      .concat(data)
      .sort((a, b) => a.startTime - b.startTime);

    Vue.set(state.occupation, getKey(params), newData);
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
