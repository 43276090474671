import i18n from '@/plugins/i18n';

export default function daysOfWeekText(daysOfWeek) {
  const map = {
    1: i18n.t('Пн'),
    2: i18n.t('Вт'),
    3: i18n.t('Ср'),
    4: i18n.t('Чт'),
    5: i18n.t('Пт'),
    6: i18n.t('Сб'),
    7: i18n.t('Вс'),
  };

  return daysOfWeek.length === 7 ? i18n.t('Все дни') : daysOfWeek.map(x => map[x]).join(', ');
}
