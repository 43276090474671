export default function formatSize(size) {
  switch (true) {
    case size > 1024 * 1024 * 1024 * 1024:
      return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB';

    case size > 1024 * 1024 * 1024:
      return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB';

    case size > 1024 * 1024:
      return (size / 1024 / 1024).toFixed(2) + ' MB';

    case size > 1024:
      return (size / 1024).toFixed(2) + ' KB';
  }

  return size.toString() + ' B';
}
