import { makeDataModel } from '@/store/factory/dataModel';
import { uniqueFilter } from '@/utils/arrays';

const DEFAULT_KEY = 'items';

export function makeEmployeesDataModel({ service, dbCompanies, dbProfiles, useLocation }) {
  const loadProfiles = async (items, { dispatch, rootGetters }) => {
    const userIds = items.map(x => x.userId).filter(uniqueFilter);
    if (userIds.length > 0) {
      const loadIds = userIds.filter(userId => !rootGetters[`${dbProfiles}/getItem`](userId));
      if (loadIds.length > 0) {
        await dispatch(
          `${dbProfiles}/fetchItems`,
          {
            userId: loadIds.join(','),
            limit: loadIds.length,
          },
          { root: true }
        );
      }
    }
  };

  const loadCompanies = async (items, { dispatch, rootGetters }) => {
    const companyIds = items.map(x => x.companyId).filter(uniqueFilter);
    if (companyIds.length > 0) {
      const loadIds = companyIds.filter(id => !rootGetters[`${dbCompanies}/getItem`](id));
      if (loadIds.length > 0) {
        await dispatch(
          `${dbCompanies}/fetchItems`,
          {
            id: loadIds.join(','),
            limit: loadIds.length,
          },
          { root: true }
        );
      }
    }
  };

  const addRelations = (items, { rootGetters }) => {
    items.forEach(emp => {
      emp.company = rootGetters[`${dbCompanies}/getItem`](emp.companyId);
      emp.profile = rootGetters[`${dbProfiles}/getItem`](emp.userId);
    });
  };

  return makeDataModel(
    { service: service },
    {
      getters: {},

      actions: {
        async fetchItems(
          { commit, dispatch, rootGetters },
          { _key, withCompanies, withProfiles, ...params } = {}
        ) {
          const key = _key || DEFAULT_KEY;

          commit('ADD_FETCH_KEY', { key });

          const query = { ...params };
          if (useLocation) {
            query['company.location_id'] = rootGetters['auth/locationId'];
          }
          const response = await service.list(query);
          const items = response.data;

          if (withCompanies) {
            await loadCompanies(items, { dispatch, rootGetters });
          }
          if (withProfiles) {
            await loadProfiles(items, { dispatch, rootGetters });
          }

          addRelations(items, { rootGetters });

          commit('ADD_QUERY', { key, response, query: query });

          return key;
        },
      },
    }
  );
}
