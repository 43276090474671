<template>
  <v-hover v-slot="{ hover }">
    <Card
      :style="{ backgroundColor: story.backgroundColor }"
      height="157"
      width="157"
      rounded="md"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-img
        :src="$crop(story.backgroundImage, imgSize1x)"
        :srcset="`${$crop(story.backgroundImage, imgSize2x)} 2x`"
        :aspect-ratio="1"
        class="white--text align-end"
      >
        <v-card-title :style="{ color: story.headingColor }" class="text-body-2 font-weight-bold">
          <nl2br tag="div" :text="(story.heading || '').replace(/&nbsp;/g, '\xa0')" />
        </v-card-title>

        <v-overlay absolute :value="viewed && !hover" color="#353439" :opacity="0.33" />
      </v-img>

      <slot name="menu" v-bind="{ hover }" />
    </Card>
  </v-hover>
</template>

<script>
import Card from '@/components/ui/Card';

export default {
  name: 'StoryCard',

  components: { Card },

  props: {
    story: {
      type: Object,
      required: true,
    },
    viewed: Boolean,
  },

  data: () => ({
    imgSize1x: '160x160',
    imgSize2x: '320x320',
  }),
};
</script>
