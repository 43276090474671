<template>
  <v-form ref="form" :disabled="loading" @submit.prevent="submit">
    <v-row dense>
      <v-col cols="12">
        <v-combobox
          v-model="form.city"
          :label="$t('Город')"
          :items="cityItems"
          :rules="[$rules.required()]"
          :search-input.sync="citySearch"
          item-value="id"
          item-text="name"
          return-object
          name="city"
          clearable
        />
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="form.locationId"
          :label="$t('Комплекс')"
          :rules="[$rules.required()]"
          :items="locationItems"
          item-value="id"
          item-text="name"
          name="locationId"
          clearable
        />
      </v-col>
      <v-col v-if="$brandConfig.isOlveryPlatform" cols="12" class="text-body-2">
        {{ $t('Не нашли Ваш город или комплекс?') }}
        <a :href="bxFormLink" target="_blank" class="text--primary">
          {{ $t('Оставьте заявку на подключение.') }}
        </a>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <BtnPrimary
          :disabled="!form.city || !locationOffer"
          :loading="loading"
          block
          @click="submit"
        >
          {{ btnName }}
        </BtnPrimary>
      </v-col>
    </v-row>

    <div
      v-if="$brandConfig.isOlveryPlatform && form.locationId && selectedLocation"
      class="mt-8 text-body-2"
    >
      <i18n
        path="Нажимая кнопку «{btn}», я принимаю условия {link}."
        tag="span"
        class="text-body-2"
      >
        <template #btn>{{ btnName }}</template>
        <template #link>
          <a class="text--primary" href="#" @click.prevent="$refs.touDialog.open()">{{
            $t('пользовательского соглашения')
          }}</a>
        </template>
      </i18n>
      <TermsOfUseDialog ref="touDialog" :public-offer="locationOffer" />
    </div>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BtnPrimary from '@/components/ui/BtnPrimary';
import TermsOfUseDialog from '@/components/terms/TermsOfUseDialog';

export default {
  name: 'LocationSelectForm',

  components: {
    TermsOfUseDialog,
    BtnPrimary,
  },

  props: {
    btnName: {
      type: String,
      default() {
        return this.$t('Продолжить');
      },
    },
    callback: {
      type: Function,
    },
    locationId: {
      type: Number,
    },
  },

  data: () => ({
    loading: false,
    citySearch: null,
    form: {
      city: null,
      locationId: null,
    },
    bxFormLink: process.env.VUE_APP_B24_LINK,
  }),

  computed: {
    ...mapGetters('cities', { allCities: 'items' }),

    ...mapGetters('locations', { locations: 'items' }),

    ...mapGetters('locationOffers', ['getLocationOffer']),

    cityItems() {
      const locationsCityIds = this.locations.map(x => x.city.id);
      const cities = this.allCities.filter(x => x.preListed || locationsCityIds.includes(x.id));

      return cities.sort((a, b) => a.name.localeCompare(b.name, this.$i18n.locale));
    },

    selectedLocation() {
      return this.locations.find(x => x.id === this.form.locationId);
    },

    locationOffer() {
      return this.getLocationOffer(this.form.locationId);
    },

    locationItems() {
      const hasCity = !!this.form.city;
      const cityId = this.form.city?.id;

      const items = hasCity
        ? this.locations.filter(x => x.city.id === cityId || x.demo)
        : this.locations;

      return [...items].sort((a, b) => a.name.localeCompare(b.name, this.$i18n.locale));
    },
  },

  watch: {
    'form.locationId': {
      immediate: true,
      handler(locationId) {
        if (locationId) {
          this.fetchPublicOfferInfo({ locationId });
          if (!this.form.city) {
            this.form.city = this.locations.find(x => x.id === locationId)?.city;
          }
        }
      },
    },

    locationItems() {
      if (this.form.locationId && !this.locationItems.find(x => x.id === this.form.locationId)) {
        this.form.locationId = null;
      }

      if (this.locationItems.length === 1) {
        this.form.locationId = this.locationItems[0].id;
      }
    },
  },

  async created() {
    const promises = [];
    if (this.allCities.length === 0) {
      promises.push(this.fetchCities());
    }
    if (this.locations.length === 0) {
      promises.push(this.fetchLocations());
    }
    await Promise.all(promises);

    const location = this.locations.find(x => x.id === this.locationId);
    if (this.locationId && location) {
      this.form.city = location.city;
      this.form.locationId = location.id;
    } else if (this.locations.length === 1) {
      this.form.city = this.locations[0].city;
      this.form.locationId = this.locations[0].id;
    }
  },

  methods: {
    ...mapActions('cities', { fetchCities: 'fetchItems' }),

    ...mapActions('locations', { fetchLocations: 'fetchItems' }),

    ...mapActions('auth', ['selectLocation']),

    ...mapActions('locationOffers', ['fetchPublicOfferInfo', 'acceptOffer']),

    async submit() {
      // wait combobox change value from selected to search text ($nextTick not worked)
      await new Promise(resolve => setTimeout(resolve, 0));
      const cityName = this.form.city?.name || this.form.city;
      this.loading = true;
      if (this.$refs.form.validate() && this.locationOffer) {
        await this.acceptOffer({ offerId: this.locationOffer.id });
        await this.selectLocation({ locationId: this.form.locationId, cityName });
        // use callback because parent component (auth/location) destroyed while event fire's
        if (this.callback) {
          this.callback();
        }
      } else {
        this.loading = false;
      }
    },
  },
};
</script>
