import i18n from '@/plugins/i18n';
import requestApi from '@/api/requestApi';
import { toBase64 } from '@/utils/utils';

const initialState = () => ({
  services: [],
});

export default {
  namespaced: true,

  state: initialState(),

  getters: {
    services: state => state.services,
  },

  actions: {
    async createRequest({ dispatch }, { files, data }) {
      const reqs = [];
      const attachments = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i].file;
        const base64 = await toBase64(file);
        const req = requestApi.upload({
          content: base64,
          filename: file.name,
        });

        reqs.push(req);
        attachments.push({ filename: file.name, resourceId: '' });
      }

      const responses = await Promise.all(reqs);
      responses.forEach((res, i) => {
        attachments[i].resourceId = res.data.resourceId;
      });

      let res;

      if (!data.serviceId) {
        res = await requestApi.createAnonRequest({ ...data, attachments }, true);
      } else {
        res = await requestApi.createRequest({ ...data, attachments }, true);
      }

      dispatch(
        'snackbar/notify',
        i18n.t('Заявка создана. Вы получите ответ на указанную почту.', [res.data.id]),
        {
          root: true,
        }
      );

      return res.data;
    },

    async fetchServices({ commit }) {
      return requestApi.fetchServices().then(res => commit('SERVICES', res.data));
    },
  },

  mutations: {
    SERVICES(state, payload) {
      state.services = payload;
    },

    RESET(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
  },
};
