<template>
  <v-btn class="dark-half text-none" dark v-bind="$attrs" v-on="$listeners">
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'DarkBtn',
};
</script>

<style lang="scss" scoped>
.dark-half {
  background: rgba($t-dark, 0.5) !important;
}
</style>
