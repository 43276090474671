<template>
  <v-app-bar
    :color="$vuetify.theme.dark ? null : 'white'"
    :height="$vuetify.breakpoint.xs ? 60 : 90"
    elevation="4"
    app
  >
    <div v-if="$vuetify.breakpoint.mobile && !$route.meta.backBtn" style="width: 40px" />

    <v-btn v-if="$route.meta.backBtn" icon height="40" width="40" @click="onBackClick">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <v-app-bar-nav-icon
      v-if="!$route.meta.backBtn && $vuetify.breakpoint.mobile"
      height="40"
      width="40"
      @click.stop="drawer = !drawer"
    />

    <div class="flex-grow-1 d-flex align-center justify-space-between">
      <PageTitle class="pl-2 pl-md-4" />
      <!--<router-view name="search" />-->
      <component :is="searchComponent" />
    </div>

    <AppToolbar class="pl-3 pl-md-6 pr-sm-8" />

    <!--    <template v-if="hasTabsRoute" v-slot:extension>-->
    <!--      <router-view name="tabs" />-->
    <!--    </template>-->

    <template v-if="tabsComponent" #extension>
      <component :is="tabsComponent" />
    </template>
  </v-app-bar>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import PageTitle from '@/components/layout/PageTitle';
import AppToolbar from '@/components/layout/AppToolbar';

export default {
  name: 'AppBar',

  components: { AppToolbar, PageTitle },

  computed: {
    ...mapFields('app', ['drawer']),

    // hasTabsRoute() {
    //   // return !!this.$route.matched[0]?.components?.tabs;
    //   return this.$route.matched.some(x => !!x.components?.tabs);
    // },

    // TODO: bug?
    // fixes render children named view (services)
    tabsComponent() {
      return this.$route.matched.find(x => !!x.components.tabs)?.components.tabs;
    },

    searchComponent() {
      return this.$route.matched.find(x => !!x.components.search)?.components.search;
    },
  },

  methods: {
    onBackClick() {
      const back = this.$route.meta.backBtn;

      if (this.$route.query.backTo) {
        return this.$router.push(this.$route.query.backTo);
      }

      if (typeof back === 'object') {
        return this.$router.push(back);
      }

      return this.$goBack();
    },
  },
};
</script>
