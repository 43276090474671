import http from './http';

const baseUrl = (process.env.VUE_APP_API_SERVICES || '').replace(/\/$/g, '');

const paymentApi = {
  createPayment({ serviceTicketId, ...data }) {
    return http.post(`${baseUrl}/service-ticket/${serviceTicketId}/payment`, data);
  },
  fetchPayment({ serviceTicketId }) {
    return http.get(`${baseUrl}/service-ticket/${serviceTicketId}/payment`);
  },
  fetchPayments({ serviceTicketId }) {
    return http.get(`${baseUrl}/service-ticket/${serviceTicketId}/payments-list`);
  },

  admin: {
    createPaymentAnonymous({ serviceTicketId: id, ...data }) {
      return http.post(`${baseUrl}/admin/service-ticket/${id}/payment-anonymous`, data);
    },
    createCashPayment({ serviceTicketId: id, ...data }) {
      return http.post(`${baseUrl}/admin/service-ticket/${id}/cash-payment`, data);
    },
    fetchPayments({ serviceTicketId }) {
      return http.get(`${baseUrl}/admin/service-ticket/${serviceTicketId}/payments-list`);
    },
  },
};

export default paymentApi;
