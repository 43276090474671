<template>
  <v-list>
    <template v-for="(item, key) in items">
      <template v-if="item.children">
        <AppMenuListGroup :key="key" :item="item">
          <AppSubMenuList v-if="item.children" :key="`children-${key}`" :items="item.children" />
        </AppMenuListGroup>
      </template>

      <AppMenuListItem v-else :key="key" :to="item.route">
        <template #icon>
          <SvgIcon v-if="typeof item.icon === 'object'" size="24">
            <component :is="item.icon" />
          </SvgIcon>
          <v-icon v-else>{{ item.icon }}</v-icon>
        </template>
        <component :is="item.component || defaultComponent" :title="item.title" />
      </AppMenuListItem>
    </template>
    <slot />
  </v-list>
</template>

<script>
import SvgIcon from '@/components/icons/SvgIcon';
import AppSubMenuList from '@/components/layout/menu/AppSubMenuList';
import AppMenuListItem from '@/components/layout/menu/AppMenuListItem';
import AppMenuListGroup from '@/components/layout/menu/item/AppMenuListGroup';
import DefaultItem from '@/components/layout/menu/item/DefaultItem';

export default {
  name: 'AppMenuList',

  components: {
    AppMenuListGroup,
    AppMenuListItem,
    AppSubMenuList,
    SvgIcon,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    defaultComponent: DefaultItem,
  }),
};
</script>
