import authApi from '@/api/authApi';

const state = {
  brand: null,
};

const getters = {
  brand: state => state.brand,
  isTpark: state => state.brand?.strId === 'TPARK',
};

const actions = {
  async fetchBrand({ commit }) {
    const { data: brand } = await authApi.fetchBrand();
    commit('SET_BRAND', brand);
  },
};

const mutations = {
  SET_BRAND(state, brand) {
    state.brand = brand;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
