import axios from 'axios';
import qs from 'qs';
import { isExpired } from '@/utils/auth';
import store from '@/store';
import i18n from '@/plugins/i18n';

const http = axios.create({
  // baseURL: process.env.VUE_APP_BASE_URL,
  // timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  paramsSerializer: {
    serialize: params => qs.stringify(params, { arrayFormat: 'brackets' }),
  },
});

// TIP: interceptors executes in inverse order

const errorInterceptor = async error => {
  if ('errorHandle' in error.config && error.config.errorHandle === false) {
    return Promise.reject(error);
  }

  const res = error.response;
  let errorMsg = i18n.t('Произошла ошибка. Попробуйте повторить позже');

  if (res) {
    switch (res.status) {
      case 400:
        errorMsg = res.data.exception?.message;
        break;
      case 403:
        errorMsg = i18n.t('У Вас нет прав для доступа к странице «{0}»...', [
          document.querySelector('#page-title')?.textContent,
        ]);
        break;
      case 404:
        errorMsg = i18n.t('Что-то потерялось. Ошибка 404');
        break;
      case 406:
        errorMsg = null;
        break;
    }
  }

  if (res && res.status === 406) {
    await store.dispatch('acceptOffers/needAccept', res.data.publicOffers);

    return Promise.resolve();
  }

  if (errorMsg) {
    await store.dispatch('snackbar/error', errorMsg);
  }

  return Promise.reject(error);
};

http.interceptors.response.use(res => res, errorInterceptor);

http.interceptors.request.use(
  config => {
    if (config.skipAuth === true) {
      return config;
    }

    const token = store.getters['auth/accessToken'];
    config.headers.Authorization = `Bearer ${token}`;

    if (process.env.NODE_ENV !== 'production') {
      config.headers['X-T-PARK-USER'] = store.getters['auth/userId'];
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

let refreshPromise;
http.interceptors.request.use(
  async config => {
    if (config.skipAuth === true) {
      return config;
    }

    // prevent multiple refresh
    while (refreshPromise) {
      await refreshPromise;
    }

    if (store.getters['auth/accessToken'] && isExpired(store.getters['auth/expireAt'])) {
      try {
        refreshPromise = store.dispatch('auth/refreshTokens');
        await refreshPromise;
        refreshPromise = null;

        return config;
      } catch (e) {
        refreshPromise = null;

        return Promise.reject(e);
      }
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const getLang = () => localStorage.getItem('locale') || 'en';

const getTimezone = () =>
  localStorage.getItem('timezone') || Intl.DateTimeFormat().resolvedOptions().timeZone;

http.interceptors.request.use(config => {
  config.headers['Accept-Language'] = getLang();
  config.headers['X-Timezone-IANA'] = getTimezone();

  return config;
});

export default http;
