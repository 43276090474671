export default [
  {
    path: '/admin/location',
    name: 'admin.location',
    redirect: { name: 'admin.location.info' },
    components: {
      default: () => import(/* webpackChunkName: "admin-mc" */ '@/views/admin/location/RoutePage'),
      tabs: () => import(/* webpackChunkName: "admin-mc" */ '@/views/admin/location/RouteTabs'),
    },
    children: [
      {
        path: '/admin/location',
        name: 'admin.location.info',
        component: () =>
          import(/* webpackChunkName: "admin-mc" */ '@/views/admin/location/LocationInfoPage'),
        meta: { isAuth: true },
      },
      {
        path: '/admin/location/structure',
        name: 'admin.location.structure',
        component: () =>
          import(/* webpackChunkName: "admin-mc" */ '@/views/admin/location/LocationStructurePage'),
        meta: { isAuth: true },
      },
      {
        path: '/admin/location/roles',
        name: 'admin.location.roles',
        component: () =>
          import(/* webpackChunkName: "admin-mc" */ '@/views/admin/location/LocationRolesPage'),
        props: { type: 'personal' },
        meta: { isAuth: true },
      },
      {
        path: '/admin/location/integrations',
        name: 'admin.location.integrations',
        component: () =>
          import(
            /* webpackChunkName: "admin-mc" */ '@/views/admin/location/LocationIntegrationsPage.vue'
          ),
        meta: { isAuth: true },
      },
    ],
  },
  {
    path: '/admin/location/edit',
    name: 'admin.location.edit',
    component: () =>
      import(/* webpackChunkName: "admin-mc" */ '@/views/admin/location/LocationFormPage'),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/location/tou',
    name: 'admin.location.tou',
    component: () =>
      import(/* webpackChunkName: "admin-mc" */ '@/views/admin/location/LocationTouPage'),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/location/contacts',
    name: 'admin.location.contacts',
    component: () =>
      import(/* webpackChunkName: "admin-mc" */ '@/views/admin/location/LocationContactsPage'),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/location/about',
    name: 'admin.location.about',
    component: () =>
      import(/* webpackChunkName: "admin-mc" */ '@/views/admin/location/LocationAboutPage'),
    meta: { isAuth: true, backBtn: true },
  },
];
