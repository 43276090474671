import offerApi from '@/api/offerApi';

const initialState = () => ({
  offers: [],
});

const state = initialState();

const getters = {
  needAccept: state => state.offers && state.offers.length > 0,
  offers: state => state.offers,
};

const actions = {
  needAccept({ commit }, payload) {
    commit('setOffers', payload);
  },
  async accept({ commit, dispatch }, { offerId }) {
    await offerApi.acceptOffer({ offerId: offerId });
    await dispatch('auth/utilizeRegistration', null, { root: true });
    commit('accepted', offerId);
  },
};

const mutations = {
  setOffers(state, payload) {
    state.offers = payload;
  },
  accepted(state, offerId) {
    state.offers = state.offers.filter(x => x.id !== offerId);
  },
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
