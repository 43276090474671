import http from './http';

const baseUrl = (process.env.VUE_APP_API_OTRS || '').replace(/\/$/g, '');

const otrsPosterApi = {
  createOrder: data => http.post(`${baseUrl}/poster/incoming-order`, data, { errorHandle: false }),
};

export default otrsPosterApi;

export const categoryService = {
  list: ({ serviceId, ...params }) =>
    http.get(`${baseUrl}/poster/service/${serviceId}/category`, { params }),
};

export const productService = {
  list: ({ serviceId, ...params }) =>
    http.get(`${baseUrl}/poster/service/${serviceId}/product`, { params }),
};

export const adminPosterService = {
  update: ({ id, ...data }) => http.patch(`${baseUrl}/poster/service-ticket/${id}`, data),
};
