export default [
  {
    path: '/admin/meter-readings',
    name: 'admin.meter-readings',
    components: {
      default: () =>
        import(
          /* webpackChunkName: "admin-meters" */ '@/views/admin/meter-readings/MeterReadingsPage.vue'
        ),
      tabs: () =>
        import(/* webpackChunkName: "admin-meters" */ '@/views/admin/meter-readings/RouteTabs'),
    },
    meta: { isAuth: true },
  },
];
