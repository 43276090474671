<template>
  <div
    class="d-flex align-center"
    :class="{ 'flex-column': vertical }"
    :style="{ cursor: $listeners.click && !disabled ? 'pointer' : null }"
    @click.stop.prevent="$emit('click')"
    @mousedown.stop="() => {}"
  >
    <BounceTransition>
      <SwitchIcon
        :value="value"
        :icon-on="iconOn"
        :icon-off="iconOff"
        :small="small"
        :class="{ 'mr-1': !vertical, 'mb-1': vertical }"
        :disabled="disabled"
      />
    </BounceTransition>
    <span class="t-grey--text caption" :class="{ count: small }">{{ count }}</span>
  </div>
</template>

<script>
import SwitchIcon from '@/components/ui/SwitchIcon';
import BounceTransition from '@/components/ui/BounceTransition';

export default {
  name: 'EventAction',

  components: {
    BounceTransition,
    SwitchIcon,
  },

  props: {
    count: [Number, String],
    disabled: Boolean,
    iconOn: String,
    iconOff: String,
    small: Boolean,
    value: Boolean,
    vertical: Boolean,
  },
};
</script>

<style scoped>
.count {
  font-size: 10px;
}
</style>
