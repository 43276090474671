import { render, staticRenderFns } from "./AppMenuListGroup.vue?vue&type=template&id=af9c7080&scoped=true"
import script from "./AppMenuListGroup.vue?vue&type=script&lang=js"
export * from "./AppMenuListGroup.vue?vue&type=script&lang=js"
import style0 from "./AppMenuListGroup.vue?vue&type=style&index=0&id=af9c7080&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af9c7080",
  null
  
)

export default component.exports