<template>
  <v-toolbar-title
    v-show="titleVisible || $vuetify.breakpoint.smAndUp"
    id="page-title"
    class="page-title text-body-1 text-md-h5 font-weight-bold"
  />
</template>

<script>
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'PageTitle',

  metaInfo: {
    changed(newInfo) {
      document.querySelector('#page-title').textContent =
        'pageTitle' in newInfo ? newInfo.pageTitle : newInfo.titleChunk;
    },
  },

  computed: mapFields('app', ['titleVisible']),
};
</script>
