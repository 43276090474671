import offerApi from '@/api/offerApi';
import { OfferStatus, ContractOfferTypes } from '@/constants/offer';

const prefix = 'ac';

export default {
  namespaced: true,

  getters: {
    getContractsByCompany: (state, _, __, rootGetters) => companyId =>
      rootGetters['db/offerAcceptances/getItems'](`${prefix}-co-${companyId}`),

    getContractsByAccessLevel: (state, _, __, rootGetters) => accessLevelId =>
      rootGetters['db/offerAcceptances/getItems'](`${prefix}-al-${accessLevelId}`),
  },

  actions: {
    async fetchContractsByCompany({ dispatch }, { companyId }) {
      await dispatch(
        'db/offerAcceptances/fetchItems',
        {
          _key: `${prefix}-co-${companyId}`,
          acceptor: `co-${companyId}`,
          offerType: ContractOfferTypes.join(','),
          status: OfferStatus.ACTIVE,
          useGrid: 0,
        },
        { root: true }
      );
    },

    async fetchContractsByCompanies({ commit }, companyIds) {
      const params = {
        acceptor: companyIds.map(id => `co-${id}`).join(','),
        offerType: ContractOfferTypes.join(','),
        status: OfferStatus.ACTIVE,
        useGrid: 0,
      };

      const { data: items } = await offerApi.offerAcceptance.list(params);

      companyIds.forEach(companyId => {
        commit(
          'db/offerAcceptances/ADD_QUERY',
          {
            key: `${prefix}-co-${companyId}`,
            response: { data: items.filter(x => x.acceptor === `co-${companyId}`) },
            query: { ...params, acceptor: `co-${companyId}` },
          },
          { root: true }
        );
      });
    },

    async fetchContractsByAccessLevel({ dispatch }, { accessLevelId }) {
      await dispatch(
        'db/offerAcceptances/fetchItems',
        {
          _key: `${prefix}-al-${accessLevelId}`,
          offerType: ContractOfferTypes.join(','),
          status: OfferStatus.ACTIVE,
          accessLevelId,
          useGrid: 0,
        },
        { root: true }
      );
    },
  },
};
