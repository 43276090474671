<script>
import { truncate } from '@/utils/string';

export default {
  name: 'Notification',

  props: {
    notification: {
      type: Object,
      required: true,
    },
    truncate: Boolean,
  },

  computed: {
    link() {
      let { data } = this.notification;
      if (!data) {
        return null;
      }

      let linkTo = { name: 'home.orders.view', params: { id: data.serviceTicket.id } };
      if (data.event && data.event.includes('.user')) {
        linkTo.name = 'home.orders.view';
      } else {
        linkTo.name = 'admin.requests.view';
      }

      return linkTo;
    },
  },

  render(h) {
    const { data, message } = this.notification;
    const extId = data?.serviceTicket?.extId;

    let truncated = this.truncate && message.length > 255;
    let truMessage = truncated ? truncate(message, 255) : message;
    let children = [truMessage];

    if (extId && truMessage.includes(extId)) {
      children = truMessage.split(extId);
      children.splice(1, 0, h('router-link', { props: { to: this.link } }, extId));
    }

    children = children.map(x =>
      typeof x === 'string' ? h('nl2br', { props: { tag: 'span', text: x } }) : x
    );

    if (this.notification.title || data?.title) {
      children.unshift(
        h('div', { staticClass: 'mb-2 font-weight-bold' }, this.notification.title || data?.title)
      );
    }

    if (truncated) {
      children.push(
        h(
          'div',
          { staticClass: 'mt-1 text-caption t-grey--text font-italic' },
          this.$t('… читать полностью')
        )
      );
    }

    return h('div', children);
  },
};
</script>
