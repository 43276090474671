import http from './http';

const baseUrl = (process.env.VUE_APP_API_BOOKING || '').replace(/\/$/g, '');

const bookingApi = {
  fetchReservationList(params) {
    return http.get(`${baseUrl}/conference/reservation`, { params });
  },
  cancelReservation(id) {
    return http.delete(`${baseUrl}/conference/reservation/${id}`);
  },
  fetchStatusList(params) {
    return http.get(`${baseUrl}/conference/reservation/status`, { params });
  },
  fetchTariffList(params) {
    return http.get(`${baseUrl}/conference/reservation/tariff`, { params });
  },
  fetchRoomList(params) {
    return http.get(`${baseUrl}/conference/room`, { params });
  },
  fetchRoom(id) {
    return http.get(`${baseUrl}/conference/room/${id}`);
  },
  updateRoom({ id, ...data }) {
    return http.put(`${baseUrl}/conference/room/${id}`, data);
  },
  createRoomReservation(roomId, data) {
    return http.post(`${baseUrl}/conference/room/${roomId}/reservation`, data);
  },
  fetchRoomSlotList(id, params) {
    return http.get(`${baseUrl}/conference/room/${id}/slot`, { params });
  },
  fetchUserReservations(id, params) {
    return http.get(`${baseUrl}/conference/user/reservation`, { params });
  },
  createServiceReservation({ serviceId, ...data }) {
    return http.post(`${baseUrl}/servicing/service/${serviceId}/reservation`, data);
  },

  reservation: {
    list: params => http.get(`${baseUrl}/conference/reservation`, { params }), // calendar
    find: ({ id }) => http.get(`${baseUrl}/conference/reservation/${id}`), // calendar makeServiceTicketUpdateListMixin
    create: data => http.post(`${baseUrl}/conference/reservation`, data),
    update: ({ id, ...data }) => http.put(`${baseUrl}/conference/reservation/${id}`, data),
    // cancel: ({ id }) => http.delete(`${baseUrl}/conference/reservation/${id}`),
  },
};

export default bookingApi;
