export default [
  {
    path: '/admin/coffee-break',
    name: 'admin.coffee-break',
    components: {
      default: () =>
        import(/* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/RoutePage'),
      tabs: () => import(/* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/RouteTabs'),
    },
    meta: { isAuth: true },
    children: [
      {
        path: 'delivery',
        name: 'admin.coffee-break.delivery',
        components: {
          default: () =>
            import(
              /* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/delivery/OrdersPage'
            ),
          search: () =>
            import(
              /* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/delivery/OrderSearch'
            ),
        },
        meta: { isAuth: true },
      },
      {
        path: 'stock-transactions',
        name: 'admin.coffee-break.stock-transactions',
        component: () =>
          import(
            /* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/transactions/StockTransactionsPage.vue'
          ),
        meta: { isAuth: true },
      },
      {
        path: 'menus',
        name: 'admin.coffee-break.menus',
        component: () =>
          import(
            /* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/menus/MenusPage.vue'
          ),
        meta: { isAuth: true },
      },
      {
        path: 'stock-balance',
        name: 'admin.coffee-break.stock-balance',
        component: () =>
          import(
            /* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/stock-balance/StockBalancePage.vue'
          ),
        meta: { isAuth: true },
      },
    ],
  },
  {
    path: '/admin/coffee-break/delivery/:id(\\d+)',
    name: 'admin.coffee-break.delivery.view',
    component: () =>
      import(
        /* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/delivery/OrderViewPage.vue'
      ),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
];
