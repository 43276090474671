import i18n from '@/plugins/i18n';
import { makeDate } from '@/utils/date';

export default function (value, format = 'short') {
  if (!value && value !== 0) {
    return '';
  }

  let formatted = i18n.d(makeDate(value), format);
  if (format === 'long') {
    return formatted.replace(',', '');
  }

  return formatted;
}
