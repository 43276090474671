import { makeDataModel } from '@/store/factory/dataModel';
import coworkingApi from '@/api/coworkingApi';

export default makeDataModel(
  { service: coworkingApi.admin.tickets },
  {
    actions: {
      async validate({ commit }, { id }) {
        return coworkingApi.admin.tickets.validate({ id }).then(res => {
          commit('UPDATE', res.data);
          return res;
        });
      },

      async invalidate({ commit }, { id }) {
        return coworkingApi.admin.tickets.invalidate({ id }).then(res => {
          commit('UPDATE', res.data);
          return res;
        });
      },

      async checkin({ commit }, { id }) {
        return coworkingApi.admin.tickets.checkin({ id }).then(res => {
          commit('UPDATE', res.data);
          return res;
        });
      },

      async checkout({ commit }, { id }) {
        return coworkingApi.admin.tickets.checkout({ id }).then(res => {
          commit('UPDATE', res.data);
          return res;
        });
      },
    },
  }
);
