<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    width="80vw"
    max-width="1000"
    scrollable
  >
    <v-card>
      <v-card-title>
        {{ $t('Пользовательское соглашение') }}
      </v-card-title>
      <v-card-text>
        <EditorContent v-if="publicOffer" :content="publicOffer.text || defaultText" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import BaseDialog from '@/components/ui/BaseDialog';
import EditorContent from '@/components/ui/EditorContent';

export default {
  name: 'TermsOfUseDialog',

  components: { EditorContent },

  extends: BaseDialog,

  props: {
    publicOffer: Object,
  },

  data: () => ({
    defaultText: null,
  }),

  watch: {
    publicOffer: {
      immediate: true,
      handler(publicOffer) {
        if (publicOffer && !publicOffer.text) {
          axios.get('/terms-of-use.html').then(res => (this.defaultText = res.data));
        }
      },
    },
  },
};
</script>
