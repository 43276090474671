import http from './http';

const baseUrl = (process.env.VUE_APP_API_OTRS || '').replace(/\/$/g, '');

const hotelApi = {
  tickets: {
    list: params => http.get(`${baseUrl}/hotel/ticket`, { params }),
    find: ({ id }) => http.get(`${baseUrl}/hotel/ticket/${id}`),
    create: data => http.post(`${baseUrl}/hotel/ticket`, data),
    // update: ({ id, ...data }) => http.put(`${baseUrl}/hotel/ticket/${id}`, data),
    // delete: ({ id }) => http.delete(`${baseUrl}/hotel/ticket/${id}`),
    // link: ({ hash }) => http.patch(`${baseUrl}/hotel/ticket/${hash}/link`),
    // updateUser: ({ id, userId }) => http.put(`${baseUrl}/hotel/ticket/${id}/set-user`, { userId }),
  },

  searchVariants: ({ serviceId, ...params }) =>
    http.get(`${baseUrl}/hotel/available-variants/service/${serviceId}`, { params }),

  admin: {
    tickets: {
      list: params => http.get(`${baseUrl}/hotel/admin/ticket`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/hotel/admin/ticket/${id}`),
      create: data => http.post(`${baseUrl}/hotel/admin/ticket`, data),
      update: ({ id, ...data }) => http.put(`${baseUrl}/hotel/admin/ticket/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/hotel/admin/ticket/${id}`),
      validate: ({ id }) => http.patch(`${baseUrl}/hotel/admin/ticket/${id}/validate`),
      invalidate: ({ id }) => http.patch(`${baseUrl}/hotel/admin/ticket/${id}/invalidate`),
      checkin: ({ id }) => http.patch(`${baseUrl}/hotel/admin/ticket/${id}/checkin`),
      checkout: ({ id }) => http.patch(`${baseUrl}/hotel/admin/ticket/${id}/checkout`),
      getFreePlaces: ({ id, ...params }) =>
        http.get(`${baseUrl}/hotel/admin/ticket/${id}/free_places`, { params }),
    },

    calendarTickets: {
      list: params => http.get(`${baseUrl}/hotel/admin/ticket/calendar`, { params }),
    },

    locks: {
      blocking: data => http.post(`${baseUrl}/hotel/admin/blocking`, data),
      unblocking: data => http.post(`${baseUrl}/hotel/admin/unblocking`, data),
    },

    rooms: {
      list: params => http.get(`${baseUrl}/hotel/admin/room`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/hotel/admin/room/${id}`),
      create: data => http.post(`${baseUrl}/hotel/admin/room`, data),
      update: ({ id, ...data }) => http.put(`${baseUrl}/hotel/admin/room/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/hotel/admin/room/${id}`),
    },

    searchVariants: ({ serviceId, ...params }) =>
      http.get(`${baseUrl}/hotel/admin/available-variants/service/${serviceId}`, { params }),
  },
};

export default hotelApi;
