<template>
  <v-list-group class="group" :value="true" :color="null">
    <template #activator>
      <v-list-item-title>{{ item.title }}</v-list-item-title>
    </template>
    <template #prependIcon>
      <SvgIcon v-if="typeof item.icon === 'object'" size="16">
        <component :is="item.icon" />
      </SvgIcon>
      <v-icon v-else small>{{ item.icon }}</v-icon>
    </template>
    <template #appendIcon>
      <v-icon small>mdi-menu-down</v-icon>
    </template>
    <slot />
  </v-list-group>
</template>

<script>
import SvgIcon from '@/components/icons/SvgIcon';

export default {
  name: 'AppMenuListGroup',
  components: { SvgIcon },
  props: ['item'],
};
</script>

<style lang="scss" scoped>
.v-list-item__title {
  font-size: 0.875rem !important;
  font-weight: bold;
}

.group.v-list-group::v-deep {
  .theme--light.v-list-item:not(.v-list-item--disabled) {
    color: var(--v-t-grey-base) !important;
  }

  & > .theme--light.v-list-item--active {
    background-color: unset !important;
  }

  .v-list-item__action,
  .v-list-item__avatar,
  .v-list-item__icon {
    min-width: 16px;
  }

  .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    min-width: 16px;
    margin-left: 4px;
  }

  .v-list-item {
    padding-left: 32px;
    padding-right: 16px;
  }
}
</style>
