export const STStatus = {
  CREATING: 'creating',
  NEW: 'new',
  INVOICING: 'invoicing',
  WAITING_FOR_WIRE_PAYMENT: 'waiting for wire payment',
  WAITING_FOR_PAYMENT: 'waiting for payment',
  CONFIRMED: 'confirmed',
  ACTIVE: 'active',
  IN_WORK: 'in_work',
  PACKED: 'packed',
  DELIVERY: 'delivery',
  PROCESSING_REQUIRED: 'processing required',
  PENDING_CLOSE: 'pending_close',
  CLOSED: 'closed',
  CANCELED: 'canceled',
};

export const closedStatuses = [STStatus.CLOSED, STStatus.CANCELED];
