import Vue from 'vue';
import rbacApi from '@/api/rbacApi';
import { uniqueFilter } from '@/utils/arrays';
import { operationToAlias } from '@/utils/rbac';

const initialState = () => ({
  operations: [],
  operationOfferors: {},
  operationCompanies: {},
});

const state = initialState();

const getters = {
  aliases: state => {
    const result = {};
    state.operations.forEach(service =>
      service.operations.forEach(op => (result[operationToAlias(service, op)] = op.id))
    );
    return result;
  },

  getSecureOfferors: state => id => state.operationOfferors[id] || [],

  canAccess: (state, getters) => (operation, secureOfferor) => {
    const operationId = getters.aliases[operation];

    if (!operationId) {
      return false;
    }

    if (!secureOfferor) {
      return operationId > 0;
    }

    return getters.getSecureOfferors(operationId).includes(secureOfferor);
  },

  getOperationId: (state, getters) => operation => getters.aliases[operation] ?? null,

  getOperationCompanies: (state, _, __, rootGetters) => operation =>
    (state.operationCompanies[operation] || [])
      .map(id => rootGetters['db/companies/getItem'](id))
      .filter(x => x),
};

const actions = {
  async fetchUserOperations({ commit }) {
    return rbacApi.fetchUserOperation().then(res => commit('SET_OPERATIONS', res.data));
  },

  async fetchSecureOfferors({ state, commit, getters }, payload) {
    const operationId = payload.operationId ?? getters.getOperationId(payload.operation);
    if (!operationId) {
      return Promise.resolve();
    }

    if (operationId in state.operationOfferors) {
      return Promise.resolve();
    }

    return rbacApi
      .fetchOperationOfferors({ operationId })
      .then(res => commit('SET_OPERATION_OFFERORS', { operationId, offerors: res.data }));
  },

  async fetchOperationCompanies({ commit, dispatch, getters, rootGetters }, { operation }) {
    const operationId = getters.getOperationId(operation);
    if (!operationId) {
      return Promise.resolve();
    }

    await dispatch('fetchSecureOfferors', { operationId });
    const offerors = getters.getSecureOfferors(operationId);

    const companyIds = offerors
      .map(x => x.split('co-')?.[1])
      .filter(x => x)
      .filter(uniqueFilter);

    const loadCompanyIds = companyIds.filter(id => !rootGetters['db/companies/getItem'](id));
    if (loadCompanyIds.length) {
      const query = {
        id: loadCompanyIds.join(','),
        limit: loadCompanyIds.length,
      };
      await dispatch('db/companies/fetchItems', { query }, { root: true });
    }

    commit('SET_OPERATION_COMPANIES', { operation, companyIds });
  },
};

const mutations = {
  SET_OPERATIONS(state, payload) {
    state.operations = payload;
  },

  SET_OPERATION_OFFERORS(state, payload) {
    Vue.set(state.operationOfferors, payload.operationId, payload.offerors || []);
  },

  SET_OPERATION_COMPANIES(state, payload) {
    Vue.set(state.operationCompanies, payload.operation, payload.companyIds);
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
