import http from './http';
import { toBase64 } from '@/utils/utils';

const baseUrl = (process.env.VUE_APP_API_STORAGE || '').replace(/\/$/g, '');

const storageApi = {
  upload(data) {
    return http.post(`${baseUrl}/upload`, data);
  },
};

export default storageApi;

export const uploadFiles = async (files, quality) => {
  const result = [];

  if (files && files.length > 0) {
    const reqs = [];
    for (let file of files) {
      if (typeof file === 'string' && file.startsWith('/')) {
        const res = await fetch(file);
        const buf = await res.arrayBuffer();
        file = new File([buf], file.split('/').pop());
      }

      reqs.push(
        storageApi.upload({
          content: await toBase64(file.file || file),
          quality,
          fileName: file?.name,
        })
      );
    }

    const responses = await Promise.all(reqs);
    for (let res of responses) {
      result.push(res.data.url);
    }
  }

  return result;
};

export const uploadFile = async (file, quality) => {
  return file && (await uploadFiles([file], quality))[0];
};
