<template>
  <v-sheet :color="slide.backgroundColor || defaultColor" :height="height" :width="width">
    <div v-if="slide.video && isCurrent" class="slide-video" v-html="slide.video.iframe"></div>
    <v-img
      v-else
      :src="$crop(slide.backgroundImage, imgSize1x)"
      :srcset="`${$crop(slide.backgroundImage, imgSize2x)} 2x`"
      height="100%"
      class="white--text"
    >
      <div class="d-flex fill-height" style="position: absolute; width: 100%">
        <div class="flex-grow-1" @click="$emit('prev')"></div>
        <div class="flex-grow-1" @click="$emit('next')"></div>
      </div>

      <div class="d-flex flex-column fill-height">
        <div
          class="d-flex flex-column grow"
          :class="contentClasses"
          :style="{ color: slide.textColor, paddingTop: '48px' }"
        >
          <v-card-title v-if="slide.heading" class="px-6 pt-4 pb-0 text-h4 font-weight-bold">
            <nl2br tag="div" :text="(slide.heading || '').replace(/&nbsp;/g, '\xa0')" />
          </v-card-title>

          <v-card-subtitle v-if="slide.subHeading" class="px-6 pt-4 pb-0 mt-0">
            <nl2br tag="div" :text="(slide.subHeading || '').replace(/&nbsp;/g, '\xa0')" />
          </v-card-subtitle>

          <v-card-text v-if="slide.text" class="px-6 pt-4 pb-0 text-body-1">
            <nl2br tag="div" :text="(slide.text || '').replace(/&nbsp;/g, '\xa0')" />
          </v-card-text>

          <v-card-text v-if="slide.buttonName" class="px-6 pt-6 pb-0">
            <v-btn
              class="text-none text-body-1"
              block
              outlined
              text
              rounded
              :color="slide.buttonColor || 'white'"
              :style="{ borderColor: slide.buttonColor }"
              :href="internalLink ? null : slide.buttonLink"
              :target="internalLink ? null : '_blank'"
              @click="onBtnClick"
            >
              {{ slide.buttonName }}
            </v-btn>
          </v-card-text>
        </div>

        <v-card-actions class="pt-4">
          <v-btn v-if="likesEnabled" fab x-small @click.stop="$emit('like')">
            <BounceTransition mode="out-in">
              <SwitchIcon
                :value="liked"
                icon-on="mdi-heart"
                icon-off="mdi-heart-outline"
                size="20"
              />
            </BounceTransition>
          </v-btn>
        </v-card-actions>
      </div>
    </v-img>
  </v-sheet>
</template>

<script>
import { trackEvent } from '@/utils/analytics';
import BounceTransition from '@/components/ui/BounceTransition';
import SwitchIcon from '@/components/ui/SwitchIcon';

export default {
  name: 'StorySlide',

  components: { SwitchIcon, BounceTransition },

  props: {
    defaultColor: {
      type: String,
      default: 'transparent',
    },
    likesEnabled: Boolean,
    liked: Boolean,
    isCurrent: Boolean,
    slide: {
      type: Object,
      required: true,
    },
    width: {
      type: [Number, String],
      default: 360,
    },
    height: {
      type: [Number, String],
      default: 640,
    },
  },

  data: () => ({
    imgSize1x: '576x768',
    imgSize2x: '1152x1536',
  }),

  computed: {
    contentClasses() {
      return {
        'justify-start': this.slide.contentPosition === 'top',
        'justify-end': this.slide.contentPosition === 'bottom',
      };
    },

    internalLink() {
      try {
        return new URL(this.slide.buttonLink).host === window.location.host;
      } catch (e) {
        return false;
      }
    },
  },

  methods: {
    onBtnClick() {
      if (this.internalLink) {
        const url = new URL(this.slide.buttonLink);
        this.$router.push(`${url.pathname}${url.search}`);
      }
      trackEvent('slide_button_click', {
        button_name: this.slide.buttonName,
      });
    },
  },
};
</script>

<style>
.slide-video,
.slide-video iframe {
  width: 100%;
  height: 100%;
}
</style>
