export default [
  {
    path: '/admin/requests',
    name: 'admin.requests',
    components: {
      default: () =>
        import(/* webpackChunkName: "admin-requests" */ '@/views/admin/requests/RequestListPage'),
      tabs: () =>
        import(/* webpackChunkName: "admin-requests" */ '@/views/admin/requests/RouteTabs'),
      search: () =>
        import(/* webpackChunkName: "admin-requests" */ '@/views/admin/requests/RequestSearch'),
    },
    meta: { isAuth: true },
  },
  {
    path: '/admin/requests/:id(\\d+)/view',
    name: 'admin.requests.view',
    component: () =>
      import(/* webpackChunkName: "admin-requests" */ '@/views/admin/requests/RequestViewPage'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/requests/new',
    name: 'admin.requests.new',
    component: () =>
      import(/* webpackChunkName: "admin-requests" */ '@/views/admin/requests/RequestFormPage.vue'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/requests/:id(\\d+)/edit',
    name: 'admin.requests.edit',
    component: () =>
      import(/* webpackChunkName: "admin-requests" */ '@/views/admin/requests/RequestEditPage'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
];
