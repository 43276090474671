export default {
  computed: {
    $rules() {
      return {
        required: () => v =>
          (v !== null && v !== undefined && v !== '') || this.$t('messages.required'),

        requiredOr: some => v => !!some || !!v || this.$t('messages.required'),

        requiredArray: () => v => !!(v && v.length) || this.$t('messages.required'),

        requiredTrue: () => v => v === true || this.$t('messages.required'),

        int: () => v => {
          return !v || /^-?\d+$/.test(v) || this.$t('messages.int');
        },

        number: () => v => {
          return !v || !isNaN(+v) || this.$t('messages.number');
        },

        positive: () => v => {
          return v === null || v === undefined || v === '' || v > 0 || this.$t('messages.positive');
        },

        gte0: () => v => {
          return v === null || v === undefined || v === '' || v >= 0 || this.$t('messages.gte0');
        },

        max: (max, message) => v => {
          return v === null || v === undefined || v === '' || v <= max || message;
        },

        min: (min, message) => v => {
          return v === null || v === undefined || v === '' || v >= min || message;
        },

        maxLength: (max, message) => v => {
          return v === null || v === undefined || String(v).length <= max || message;
        },

        inn: () => v => !v || /^(\d{10}|\d{12})$/.test(v) || this.$t('Укажите корректный ИНН'),

        kpp: () => v => !v || /^\d{9}$/.test(v) || this.$t('Укажите корректный КПП'),

        json: () => v => {
          try {
            if (v !== null && v !== undefined) {
              JSON.parse(v);
            }
          } catch (e) {
            return this.$t('Невалидный JSON');
          }

          if (v && !v.startsWith('{')) {
            return this.$t('Укажите JSON объект');
          }

          return true;
        },

        url: () => val => {
          if (!val) {
            return true;
          }

          let url;
          try {
            url = new URL(val);
          } catch (_) {
            return this.$t('Укажите корректный URL в формате https://example.com');
          }

          return (
            url.protocol === 'http:' ||
            url.protocol === 'https:' ||
            this.$t('Укажите корректный URL')
          );
        },
      };
    },
  },
};
