import i18n from '@/plugins/i18n';

const initialState = () => ({});

const getters = {
  getItems: (state, _, __, rootGetters) => locationId =>
    rootGetters['db/acsProviders/getItems'](locationId),

  drivers: (state, _, __, rootGetters) => rootGetters['db/acsDrivers/getItems']('list'),

  getDevices: (state, _, __, rootGetters) => providerId =>
    rootGetters['db/acsAccessPoints/getItems'](providerId),
};

const actions = {
  async fetchProviders({ dispatch }, { locationId }) {
    await dispatch('db/acsProviders/fetchItems', { _key: locationId, locationId }, { root: true });
  },

  async fetchRefs({ dispatch }) {
    await dispatch('db/acsDrivers/fetchItems', { _key: 'list' }, { root: true });
  },

  async saveProvider({ dispatch }, payload) {
    if (payload.id) {
      await dispatch('db/acsProviders/update', payload, { root: true });
    } else {
      await dispatch('db/acsProviders/create', payload, { root: true });
    }
    dispatch('snackbar/notify', i18n.t('Успешно сохранено'), { root: true });
  },

  async deleteProvider({ dispatch, rootGetters }, { id }) {
    await dispatch(
      'db/accessPoints/fetchItems',
      { _key: `acsId_${id}`, acsId: id, limit: 1000 },
      { root: true }
    );
    const accessPoints = rootGetters['db/accessPoints/getItems'](`acsId_${id}`);
    const deleteAccessPoints = accessPoints
      .filter(x => x.acsId === id)
      .map(x => dispatch('db/accessPoints/delete', { id: x.id }, { root: true }));
    await Promise.all(deleteAccessPoints);

    await dispatch('db/acsProviders/delete', { id }, { root: true });

    dispatch('snackbar/notify', i18n.t('Успешно удалено'), { root: true });
  },

  async fetchDevices({ dispatch }, { providerId }) {
    await dispatch(
      'db/acsAccessPoints/fetchItems',
      { _key: providerId, providerId },
      { root: true }
    );
  },
};

const mutations = {};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
