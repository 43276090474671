import http from './http';

const baseUrl = (process.env.VUE_APP_API_EVENT || '').replace(/\/$/g, '');

const newsApi = {
  news: {
    list: params => http.get(`${baseUrl}/news`, { params }),
    find: ({ id }) => http.get(`${baseUrl}/news/${id}`),
  },

  newsCategory: {
    list: params => http.get(`${baseUrl}/news-category`, { params }),
    find: ({ id }) => http.get(`${baseUrl}/news-category/${id}`),
  },

  newsLike: {
    create: ({ id }) => http.put(`${baseUrl}/news-like/${id}`),
    delete: ({ id }) => http.delete(`${baseUrl}/news-like/${id}`),
  },

  admin: {
    news: {
      list: params => http.get(`${baseUrl}/admin/news`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/admin/news/${id}`),
      create: data => http.post(`${baseUrl}/admin/news`, data),
      update: ({ id, ...data }) => http.put(`${baseUrl}/admin/news/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/admin/news/${id}`),
    },

    newsCategory: {
      list: params => http.get(`${baseUrl}/admin/news-category`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/admin/news-category/${id}`),
      create: data => http.post(`${baseUrl}/admin/news-category`, data),
      update: ({ id, ...data }) => http.patch(`${baseUrl}/admin/news-category/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/admin/news-category/${id}`),
    },

    fetchStat(params) {
      return http.get(`${baseUrl}/news-position-stat`, { params });
    },
  },
};

export default newsApi;
