import { makeSimpleList } from '@/store/factory/simpleList';
import i18n from '@/plugins/i18n';
import { uploadFile } from '@/api/storageApi';

export default makeSimpleList(
  {
    name: 'list',
    dataModel: 'db/adminCompanies',
    params: {
      sort: 'shortName,id',
    },
  },
  {
    actions: {
      async submitForm({ dispatch }, { id, logo, form, employeesData }) {
        if (logo) {
          form.logo = await uploadFile(logo);
        }

        const { data: company } = id
          ? await dispatch('update', { ...form, id: id })
          : await dispatch('create', { ...form });

        await dispatch(
          'adminEmployees/saveEmployees',
          { companyId: company.id, data: employeesData },
          { root: true }
        );

        await dispatch('snackbar/notify', i18n.t('Изменения сохранены'), { root: true });
      },
    },
  }
);
