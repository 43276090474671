<template>
  <v-card
    :elevation="elevation"
    :height="height"
    :rounded="rounded"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-card>
</template>

<script>
export default {
  name: 'Card',
  props: {
    elevation: {
      type: String,
      default: '8',
    },
    rounded: {
      type: String,
      default: 'lg',
    },
    height: {
      type: [String, Number],
      default: '100%',
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card--link:focus:before {
  opacity: 0;
}

.v-card::v-deep .dots {
  visibility: hidden;
}

.v-card:hover::v-deep .dots {
  visibility: visible;
}
</style>
