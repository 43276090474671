import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import qs from 'qs';
import {
  checkAccessMiddleware,
  checkPublicOffers,
  initBrand,
  initUserMiddleware,
} from '@/router/middlewares';
import routes from '@/brand/routes';

Vue.use(VueRouter);
Vue.use(Meta);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    }

    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  parseQuery: function (str) {
    return qs.parse(str, { arrayLimit: 0 });
  },
  stringifyQuery: function (obj) {
    const res = qs.stringify(obj);
    return res ? `?${res}` : '';
  },
});

router.beforeEach(initBrand);
router.beforeEach(initUserMiddleware);
router.beforeEach(checkAccessMiddleware);
router.beforeEach(checkPublicOffers);

export default router;
