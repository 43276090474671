<template>
  <v-dialog
    v-resize="onResize"
    :value="value"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :transition="transition"
    content-class="story rounded-0"
    overlay-color="#6F7882"
    overlay-opacity="0.8"
    @input="close()"
  >
    <div class="d-flex">
      <div
        v-if="!$vuetify.breakpoint.xs"
        class="d-flex align-center justify-center arrow"
        @click="prev"
      >
        <v-icon large>mdi-arrow-left-drop-circle</v-icon>
      </div>

      <div class="wrapper">
        <div class="top">
          <div class="px-5 py-4 d-flex top-items">
            <div v-for="(progressValue, idx) of progress" :key="idx" class="px-1 top-item">
              <v-progress-linear
                :value="(progressValue / progressStepCounts[idx]) * 100"
                background-color="rgba(111, 120, 130, 0.33)"
                color="#F7F9FC"
                height="8"
                rounded
                stream
              />
            </div>
          </div>

          <div class="px-6 text-right">
            <DarkBtn icon small>
              <v-icon small @click="close()">mdi-close</v-icon>
            </DarkBtn>
          </div>
        </div>

        <v-window
          v-touch="{ left: () => $emit('next'), right: () => $emit('prev') }"
          :value="current"
        >
          <v-window-item v-for="(slide, idx) of story.slides" :key="idx">
            <StorySlide
              :slide="slide"
              :likes-enabled="story.likesEnabled"
              :liked="liked"
              :width="$vuetify.breakpoint.xs ? mobileWidth : undefined"
              :height="$vuetify.breakpoint.xs ? mobileHeight : undefined"
              :is-current="current === idx"
              @like="$emit('like')"
              @prev="prev"
              @next="next"
            />
          </v-window-item>
        </v-window>
      </div>

      <div
        v-if="!$vuetify.breakpoint.xs"
        class="d-flex align-center justify-center arrow"
        @click="next"
      >
        <v-icon large>mdi-arrow-right-drop-circle</v-icon>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import StorySlide from '@/components/story/StorySlide';
import DarkBtn from '@/components/ui/DarkBtn';
import { trackEvent } from '@/utils/analytics';

export default {
  name: 'StoryDialog',

  components: { DarkBtn, StorySlide },

  props: {
    value: Boolean,
    liked: Boolean,
    story: {
      type: Object,
      required: true,
    },
    transition: String,
  },

  data: () => ({
    interval: null,
    stepTime: 500, // ms

    current: 0, // slide idx
    progress: [],

    mobileHeight: '100%',
    mobileWidth: 360,
  }),

  computed: {
    length() {
      return this.story.slides.length;
    },

    progressStepCounts() {
      return this.story.slides.map(slide => (slide.video?.duration ?? 15) * 2);
    },

    currentStepCount() {
      return this.progressStepCounts[this.current];
    },
  },

  watch: {
    current: {
      immediate: true,
      handler(val) {
        const slide = this.story.slides[val];
        trackEvent('slide_view', {
          story_name: this.story.heading,
          slide_name: slide.heading,
          slide_number: val,
          service_button: slide.buttonName,
        });
      },
    },
  },

  created() {
    this.progress = new Array(this.length).fill(0);
  },

  mounted() {
    this.play();
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    onResize() {
      if (this.$vuetify.breakpoint.xs) {
        this.mobileHeight = window.innerHeight;
        this.mobileWidth = window.innerWidth;
      }
    },

    next() {
      if (this.current + 1 >= this.length) {
        return this.$emit('next');
      }

      this.progress.splice(this.current, 1, this.currentStepCount);
      this.current++;
    },

    prev() {
      if (this.current - 1 >= 0) {
        this.progress.splice(this.current, 1, 0);
        this.progress.splice(this.current - 1, 1, 0);
        this.current = this.current - 1;
      } else {
        return this.$emit('prev');
      }
    },

    play() {
      this.$emit('viewed');

      this.interval = setInterval(() => {
        if (this.progress[this.current] < this.currentStepCount) {
          this.$set(this.progress, this.current, this.progress[this.current] + 1);
        } else {
          this.next();
        }
      }, this.stepTime);
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>

<style>
.story.v-dialog {
  width: auto;
  box-shadow: none;
}
</style>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  background-color: rgba($t-grey, 0.8);
}

.top {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.top-items {
}
.top-item {
  width: 100%;
}

.v-progress-linear {
  transition-duration: 0.5s;
  transition-timing-function: linear;
}

.arrow {
  /* icon 36 + padding 2*40 */
  width: calc(36px + 80px);
  cursor: pointer;
}
</style>
