import profileApi from '@/api/profileApi';

export default {
  namespaced: true,

  state: {
    stats: {},
  },

  getters: {
    total: state => state.stats?.total || 0,
    unseen: state => state.stats?.unseen || 0,
  },

  actions: {
    fetchStats({ commit, getters }) {
      return profileApi.notification.stats().then(res => {
        if (res.data.total !== getters.total || res.data.unseen !== getters.unseen) {
          commit('SET_STATS', res.data);
        }
      });
    },
  },

  mutations: {
    SET_STATS(state, stats) {
      state.stats = stats;
    },

    RESET(state) {
      state.stats = {};
    },
  },
};
