export default [
  {
    path: '/admin/acs',
    name: 'admin.acs',
    redirect: { name: 'admin.acs.access-log' },
    components: {
      default: () => import(/* webpackChunkName: "admin-acs" */ '@/views/admin/acs/RoutePage'),
      tabs: () => import(/* webpackChunkName: "admin-acs" */ '@/views/admin/acs/RouteTabs'),
    },
    children: [
      {
        path: '/admin/acs/access-log',
        name: 'admin.acs.access-log',
        component: () =>
          import(/* webpackChunkName: "admin-acs" */ '@/views/admin/acs/access-log/AccessLogPage'),
        meta: { isAuth: true },
      },
    ],
  },
];
