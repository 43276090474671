import offerApi from '@/api/offerApi';
import nfcApi from '@/api/nfcApi';

const initialState = () => ({
  accessibleUnits: [
    // 1, 2, ...
  ],
  devicesByUnit: {
    // 1 => [{id: 3, providerId: 5, name: "Офис 205", mode: "UNLOCKED", unitId: 39}, ...]
  },
  accessibleLevels: [
    // 1, 2, ...
  ],
});

const actions = {
  async fetchAccessibleUnits({ commit, rootState, rootGetters }) {
    const { data: accessibleUnits } = await offerApi.my.fetchAccessibleUnits();
    commit('SET_DATA', { accessibleUnits });

    if (rootGetters['permissions/canAccess']('nfc:devices-by-unit.read')) {
      const devicesByUnit = {};
      const reqs = accessibleUnits.map(unitId =>
        nfcApi.fetchDevicesByUnit({ unitId, locationId: rootState.auth.locationId })
      );
      const responses = await Promise.all(reqs);
      responses.forEach((res, i) => {
        const unitId = accessibleUnits[i];
        devicesByUnit[unitId] = res.data;
      });
      commit('SET_DATA', { devicesByUnit });
    }
  },

  async setDeviceMode({ commit, state, rootState }, { unitId, deviceId, mode }) {
    const device = state.devicesByUnit[unitId].find(x => x.id === deviceId);
    await nfcApi.setDeviceMode({ deviceId, providerId: device.providerId, mode });
    const res = await nfcApi.fetchDevicesByUnit({ unitId, locationId: rootState.auth.locationId });
    const devicesByUnit = { ...state.devicesByUnit, [unitId]: res.data };
    commit('SET_DATA', { devicesByUnit });
  },

  async fetchAccessibleLevels({ commit }) {
    const { data: accessibleLevels } = await offerApi.my.fetchAccessibleLevels();
    commit('SET_DATA', { accessibleLevels });
  },
};

const mutations = {
  SET_DATA(state, obj) {
    Object.keys(obj).forEach(key => (state[key] = obj[key]));
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};
