export default [
  {
    path: '/events',
    name: 'events',
    redirect: { name: 'events.upcoming' },
    components: {
      default: () => import(/* webpackChunkName: "events" */ '@/views/events/EventsPage'),
      search: () => import(/* webpackChunkName: "events" */ '@/views/events/EventSearch'),
      tabs: () => import(/* webpackChunkName: "events" */ '@/views/events/EventTabs'),
    },
    meta: { isAuth: true },
    children: [
      {
        path: 'upcoming',
        name: 'events.upcoming',
        component: () => import(/* webpackChunkName: "events" */ '@/views/events/EventList'),
        props: { module: 'upcomingEventList', past: false },
        meta: { isAuth: true },
      },
      {
        path: 'past',
        name: 'events.past',
        component: () => import(/* webpackChunkName: "events" */ '@/views/events/EventList'),
        props: { module: 'pastEventList', past: true },
        meta: { isAuth: true },
      },
    ],
  },
  {
    path: '/events/:id(\\d+)',
    name: 'events.view',
    component: () => import(/* webpackChunkName: "events" */ '@/views/events/EventViewPage'),
    props: true,
    meta: { isAuth: true, backBtn: true },
  },
];
