<template>
  <v-chip
    :class="['x-small' in $attrs && $attrs['x-small'] !== false && 'px-2']"
    close-icon="mdi-close"
    v-bind="$attrs"
    v-on="$listeners"
    @mousedown.stop="() => {}"
  >
    <slot />
  </v-chip>
</template>

<script>
export default {
  name: 'Tag',
};
</script>

<style lang="scss" scoped>
.theme--light.v-chip:not(.v-chip--active) {
  background: $back-light;
}

.theme--light.v-chip {
  color: $tag-text;
}
</style>
