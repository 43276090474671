export default [
  {
    path: '/rbac/microservices',
    name: 'rbac.microservices',
    component: () =>
      import(/* webpackChunkName: "admin-saas" */ '@/views/rbac/microservice/MicroServices.vue'),
    meta: { isAuth: true },
  },
  {
    path: '/rbac/microservices/:id(\\d+)/edit',
    name: 'rbac.microservices.edit',
    component: () =>
      import(/* webpackChunkName: "admin-saas" */ '@/views/rbac/microservice/MicroServiceEdit.vue'),
    meta: { isAuth: true, backBtn: true },
  },
];
