<template>
  <v-system-bar v-if="showBar" app class="px-0" height="auto" style="z-index: 6">
    <v-toolbar elevation="0" tile>
      <v-btn icon height="40" width="40" style="opacity: 0.5" @click="os = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <img class="mr-2" src="/favicon-32x32.png" width="24" height="24" alt="" />
      <div class="text-body-2">{{ $t('Открыть в приложении') }}</div>
      <v-spacer />
      <BtnPrimary class="ml-1" rounded :disabled="disabled" @click="onClick">
        {{ $t('Открыть') }}
      </BtnPrimary>
    </v-toolbar>
  </v-system-bar>
</template>

<script>
import { getMobileOS } from '@/utils/utils';
import BtnPrimary from '@/components/ui/BtnPrimary';

export default {
  name: 'OpenInAppBar',

  components: { BtnPrimary },

  data: () => ({
    os: false,
  }),

  computed: {
    showBar() {
      return ['Android', 'iOS'].includes(this.os);
    },

    androidUrl() {
      if (!this.$brand?.app_google_play_link) {
        return null;
      }

      const host = window.location.host;
      const scheme = window.location.protocol.slice(0, -1);
      const packageId = new URL(this.$brand.app_google_play_link).searchParams.get('id');

      return `intent://${host}//#Intent;scheme=${scheme};package=${packageId};end`;
    },

    iosUrl() {
      return this.$brand?.app_apple_link;
    },

    disabled() {
      return (
        (this.os === 'Android' && !this.androidUrl) ||
        (this.os === 'iOS' && !this.iosUrl) ||
        this.os === 'Other'
      );
    },
  },

  mounted() {
    this.os = getMobileOS();
  },

  methods: {
    onClick() {
      if (this.os === 'iOS') {
        window.location.replace(this.iosUrl);
      } else {
        window.location.replace(this.androidUrl);
      }
    },
  },
};
</script>
