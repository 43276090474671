import { makeDataModel } from '@/store/factory/dataModel';
import newsApi from '@/api/newsApi';

export default makeDataModel(
  { service: newsApi.news },
  {
    actions: {
      async like({ commit, getters }, id) {
        const item = getters['getItem'](id);

        try {
          commit('UPDATE', { ...item, liked: !item.liked });

          const res = !item.liked
            ? await newsApi.newsLike.create({ id: id })
            : await newsApi.newsLike.delete({ id: id });

          commit('UPDATE', res.data);
        } catch (e) {
          commit('UPDATE', item);
        }
      },
    },
  }
);
