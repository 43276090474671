<template>
  <v-card
    class="pa-1"
    :class="[selected && 'selected']"
    min-height="64"
    max-height="64"
    min-width="64"
    max-width="64"
    rounded="lg"
    outlined
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-img :src="$crop(src, '100x100')" aspect-ratio="1" class="rounded-lg" />
  </v-card>
</template>

<script>
export default {
  name: 'ImgPreview',
  props: {
    src: String,
    selected: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  border-color: transparent;
  border-radius: 12px;
}

.v-card:hover {
  border: 1px solid rgba(118, 148, 184, 0.5);
}

.v-card.selected {
  border: 1px solid $additional;
}
</style>
