import PageView from '@/components/common/PageView';

export default [
  {
    path: '/service',
    components: {
      default: () => import(/* webpackChunkName: "services" */ '@/views/services/ServicesPage'),
      search: () =>
        import(/* webpackChunkName: "services" */ '@/views/services/search/ServiceSearch'),
    },
    meta: { isAuth: true },
    children: [
      {
        path: '/services',
        components: {
          default: PageView,
          tabs: () => import(/* webpackChunkName: "services" */ '@/views/services/ServicesTabs'),
        },
        meta: { isAuth: true },
        children: [
          {
            path: '',
            name: 'services.all',
            components: {
              default: () =>
                import(/* webpackChunkName: "services" */ '@/views/services/ServicesCatalog'),
            },
            meta: { isAuth: true },
          },
          {
            path: '/services/new',
            name: 'services.new',
            component: () =>
              import(/* webpackChunkName: "services" */ '@/views/services/ServicesNew'),
            meta: { isAuth: true },
          },
          {
            path: '/services/favorites',
            name: 'services.favorites',
            component: () =>
              import(/* webpackChunkName: "services" */ '@/views/services/ServicesFavorites'),
            meta: { isAuth: true },
          },
        ],
      },
      {
        path: '/services/category/:id',
        name: 'services.category',
        component: () =>
          import(/* webpackChunkName: "services" */ '@/views/services/ServicesCatalog'),
        meta: { isAuth: true, backBtn: true },
      },
      {
        path: '/services/:id',
        name: 'services.service',
        component: () => import(/* webpackChunkName: "services" */ '@/views/services/ServiceView'),
        props: route => ({ id: parseInt(route.params.id, 10) }),
        meta: { isAuth: true, backBtn: true },
      },
    ],
  },
  {
    path: '/payment',
    component: () =>
      import(/* webpackChunkName: "services" */ '@/views/services/PaymentResult.vue'),
    meta: { isAuth: true },
  },
  {
    path: '/payment/:id',
    component: () =>
      import(/* webpackChunkName: "services" */ '@/views/services/PaymentResult.vue'),
    props: true,
    meta: { isAuth: true },
  },
];
