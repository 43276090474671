export default [
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutPage'),
    meta: { isAuth: true },
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import(/* webpackChunkName: "about" */ '@/views/ContactsPage'),
    meta: { isAuth: true },
  },
  {
    path: '/static/:id(\\d+)',
    name: 'static-page',
    component: () => import(/* webpackChunkName: "about" */ '@/views/StaticPage'),
    props: ({ params }) => ({ id: Number(params.id) }),
    meta: { isAuth: true },
  },
];
