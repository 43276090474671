import * as Sentry from '@sentry/browser';
import router from '@/router';
import i18n from '@/plugins/i18n';
import authApi from '@/api/authApi';
import { getFingerprint, parseTokenData } from '@/utils/auth';
import { CONFIRMED, INIT, REGISTER_OAUTH, SMS_CODE } from '@/constants/authStage';
import { trackEvent } from '@/utils/analytics';

// 2 ways:
// phone -> sms -> registerSms -> offer
// oauth -> registerOauth -> sms -> offer

const initialState = () => ({
  exchangeToken: null,
  isSocialAuthorized: false,
  authService: '',
  authUserInfo: null,
  needAuthBindConfirm: null,
  needSmsLogin: null,
  smsStage: INIT,
  smsSendAt: null,
  userId: null,
  phone: null,
  registrationData: null, // { firstName, lastName, phone },
  accessToken: null,
  expireAt: null,
  loading: false,
  error: null,
  locationId: null,
  location: null,
});

const state = initialState();

const getters = {
  accessToken: state => state.accessToken,
  isTokenSet: state => !!state.accessToken,
  expireAt: state => state.expireAt,
  userId: state => state.userId,
  isAuth: state => !!state.userId,
  phone: state => state.phone,
  authUserInfo: state => state.authUserInfo,
  locationId: state => state.locationId,
  location: state => state.location,
};

const setTokens = async ({ commit }, data) => {
  localStorage.setItem('refreshToken', data.refreshToken);
  commit('SET_ACCESS_TOKEN', data.accessToken);
};

const initOtherData = async ({ dispatch, getters }) => {
  await dispatch('profile/initProfile', getters.userId, { root: true });
};

const successLoginAndInit = async (ctx, data) => {
  await setTokens(ctx, data);
  const locationId = ctx.getters.locationId;
  if (locationId) {
    await ctx.dispatch('fetchCurrentLocation');
  }
  await initOtherData(ctx);
};

const actions = {
  async registerDevice({ commit, state }) {
    if (!state.exchangeToken) {
      const fingerprint = await getFingerprint();

      const { data } = await authApi.registerDevice({
        fingerprint,
        platform: process.env.VUE_APP_PLATFORM,
        info: '',
      });

      commit('SET_DATA', { exchangeToken: data.accessToken });
    }
  },

  async oauthLogin(ctx, payload) {
    const { commit, dispatch, state } = ctx;

    commit('LOADING', true);

    try {
      await dispatch('registerDevice');

      const { data } = await authApi.authLogin({
        accessToken: state.exchangeToken,
        ...payload,
      });

      if (!data.needSmsLogin) {
        await successLoginAndInit(ctx, data);
        trackEvent('login', { method: payload.service });
      } else {
        commit('OAUTH_LOGIN', { ...data, authService: payload.service });

        dispatch('snackbar/notify', i18n.t('Подтвердите номер чтобы продолжить.'), {
          root: true,
        });
        if (router.currentRoute.name !== 'auth.login') {
          router.push({ name: 'auth.login' });
        }
      }
    } catch (e) {
      dispatch('snackbar/error', i18n.t('Произошла ошибка. Попробуйте повторить позже'), {
        root: true,
      });
    }

    commit('LOADING', false);
  },

  storeRegistrationData({ commit }, payload) {
    commit('SET_DATA', { registrationData: payload });
  },

  async smsLogin({ commit, dispatch, state }, { phone, captcha }) {
    commit('LOADING', true);
    commit('SMS_LOGIN_START');

    try {
      await dispatch('registerDevice');

      const { data } = await authApi.smsLogin({ phone, captcha, accessToken: state.exchangeToken });

      commit('SET_DATA', { phone: phone, exchangeToken: data.accessToken });
      commit('SMS_LOGIN_SUCCESS');
    } catch (e) {
      console.log(e);
      commit('SMS_LOGIN_ERROR', e.message);
      dispatch('snackbar/error', i18n.t('Произошла ошибка. Попробуйте повторить позже'), {
        root: true,
      });
    }

    commit('LOADING', false);
  },

  async smsConfirm(ctx, payload) {
    const { commit, dispatch, state } = ctx;

    commit('LOADING', true);

    try {
      const { data } = await authApi.smsConfirm({
        ...payload,
        accessToken: state.exchangeToken,
      });

      commit('SMS_CONFIRM_SUCCESS', data);

      if (!data.needAuthBindConfirm) {
        await successLoginAndInit(ctx, data);
        trackEvent('login', { method: state.authService || 'phone' });
      }
    } catch (e) {
      if (e.response && e.response.status === 400) {
        await dispatch('snackbar/error', i18n.t('Неверный код.'), { root: true });
      } else if (e.response && e.response.status === 404) {
        await dispatch('snackbar/error', i18n.t('Код больше не действителен.'), { root: true });
        await dispatch('logout');
      } else {
        console.log(e);
        await dispatch('snackbar/error', i18n.t('Произошла ошибка. Попробуйте повторить позже'), {
          root: true,
        });
      }
    }

    commit('LOADING', false);
  },

  async oauthBindConfirm(ctx) {
    const { dispatch, state } = ctx;

    try {
      const { data } = await authApi.authBindConfirm({
        accessToken: state.exchangeToken,
        confirm: true,
      });

      await successLoginAndInit(ctx, data);
      trackEvent('login', { method: state.authService });
    } catch (e) {
      dispatch('snackbar/error', i18n.t('Не удалось перепривязать профиль.'), {
        root: true,
      });
    }
  },

  async refreshTokens({ commit, dispatch }) {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      return;
    }

    try {
      const fingerprint = await getFingerprint();
      const { data } = await authApi.refreshToken({
        refreshToken,
        fingerprint,
      });

      await setTokens({ commit }, data);

      return true;
    } catch (e) {
      console.log(e.response);
      await dispatch('logout');
      router.push({ name: 'auth.login' });
      throw e;
    }
  },

  async loginByRefreshToken(ctx) {
    const { dispatch } = ctx;

    const refreshToken = localStorage.getItem('refreshToken');

    try {
      const fingerprint = await getFingerprint();
      const { data } = await authApi.refreshToken({
        refreshToken,
        fingerprint,
      });

      await successLoginAndInit(ctx, data);

      return true;
    } catch (e) {
      console.log(e.response);
      await dispatch('logout');
      router.push({ name: 'auth.login' });
      throw e;
    }
  },

  async autoLogin() {
    // implement if store access token in localStorage
  },

  async logout({ commit }, reload = true) {
    commit('RESET');
    localStorage.removeItem('refreshToken');
    if (reload) {
      window.location = location.origin;
    }
    // dispatch('reset', null, { root: true });
    // if (router.currentRoute.name !== 'auth.login') {
    //   await router.push({ name: 'auth.login' });
    // }
  },

  'push:auth.logout.push': {
    root: true,
    handler({ dispatch }) {
      dispatch('logout');
    },
  },

  async utilizeRegistration({ commit, dispatch, state }) {
    if (state.registrationData) {
      await dispatch('profile/saveProfile', { profile: state.registrationData }, { root: true });
      commit('SET_DATA', { registrationData: null });
    }
  },

  async fetchCurrentLocation({ state, commit, dispatch }) {
    let location = null;
    try {
      const { data } = await authApi.location.find({ id: state.locationId });
      location = data;
      if (location) {
        await dispatch('setTimezone', location.city.timezone, { root: true });
        await dispatch(
          'locationOffers/fetchPublicOfferInfo',
          { locationId: location.id },
          { root: true }
        );
      }
    } catch (e) {
      // e
    }
    commit('SET_DATA', { location });
  },

  async selectLocation({ commit, dispatch, getters }, { locationId }) {
    if (locationId === getters.locationId) {
      return;
    }

    const { data } = await authApi.location.choose({
      locationId: locationId,
      accessToken: getters.accessToken,
    });
    await setTokens({ commit }, data);

    await dispatch('fetchCurrentLocation');
  },
};

const mutations = {
  SET_DATA(state, obj) {
    Object.keys(obj).forEach(key => (state[key] = obj[key]));
  },

  LOADING(state, loading) {
    state.loading = loading;
  },

  OAUTH_LOGIN(state, payload) {
    state.isSocialAuthorized = true;
    state.authService = payload.authService;
    state.authUserInfo = payload.info;
    state.exchangeToken = payload.accessToken;
    state.needSmsLogin = payload.needSmsLogin;
    state.smsStage = REGISTER_OAUTH;
  },

  SMS_LOGIN_START(state) {
    state.loading = true;
    state.error = null;
    state.phone = null;
    state.smsSendAt = null;
  },
  SMS_LOGIN_ERROR(state, message) {
    state.loading = false;
    state.error = message;
  },
  SMS_LOGIN_SUCCESS(state) {
    state.loading = false;
    state.error = null;
    state.smsStage = SMS_CODE;
    state.smsSendAt = Math.floor(new Date().getTime() / 1000);
  },

  SET_ACCESS_TOKEN(state, accessToken) {
    const tokenData = parseTokenData(accessToken);
    const nowTime = Math.floor(new Date().getTime() / 1000);
    const ttl = tokenData.exp - tokenData.iat;

    state.accessToken = accessToken;
    state.expireAt = nowTime + ttl - 5;
    state.userId = tokenData.userId;
    state.phone = tokenData.phone;
    state.locationId = tokenData.locationId;

    Sentry.setTag('userId', state.userId);
    Sentry.setTag('locationId', state.locationId);

    if (process.env.NODE_ENV === 'development') {
      console.log('tokenData', tokenData);
      console.log('expireAt', new Date(state.expireAt * 1000).toString());
    }
  },

  SMS_CONFIRM_SUCCESS(state, payload) {
    state.exchangeToken = payload.accessToken;
    state.needAuthBindConfirm = payload.needAuthBindConfirm;
    state.smsStage = CONFIRMED;
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
