import Vue from 'vue';
import eventBus from '@/eventBus';

Object.defineProperties(Vue.prototype, {
  $eventBus: {
    get: function () {
      return eventBus;
    },
  },
});
