import { makeDataModel } from '@/store/factory/dataModel';
import eventApi from '@/api/eventApi';
import { EventTicketStatus } from '@/constants/event';

export default makeDataModel(
  { service: eventApi.event },
  {
    actions: {
      async like({ commit, getters }, eventId) {
        const item = getters['getItem'](eventId);

        try {
          commit('UPDATE', { ...item, liked: !item.liked });

          const res = !item.liked
            ? await eventApi.eventLike.create({ id: eventId })
            : await eventApi.eventLike.delete({ id: eventId });

          commit('UPDATE', res.data);
        } catch (e) {
          commit('UPDATE', item);
        }
      },

      async subscribe({ commit, getters }, eventId) {
        const item = getters['getItem'](eventId);

        if (item.subscribed && item.ticket && item.ticket.status !== EventTicketStatus.CANCELED) {
          return;
        }

        try {
          commit('UPDATE', { ...item, subscribed: !item.subscribed });

          const res = !item.subscribed
            ? await eventApi.userEvent.create({ id: eventId })
            : await eventApi.userEvent.delete({ id: eventId });

          commit('UPDATE', res.data);
        } catch (e) {
          commit('UPDATE', item);
        }
      },

      async unsubscribe({ commit }, { eventId }) {
        const res = await eventApi.userEvent.delete({ id: eventId });
        commit('UPDATE', res.data);
      },

      async eventRegister({ commit }, { eventId }) {
        const res = await eventApi.registration.register({ id: eventId });
        commit('UPDATE', res.data);
      },

      async cancelRegistration({ commit }, { eventId }) {
        const res = await eventApi.registration.cancel({ id: eventId });
        commit('UPDATE', res.data);
      },
    },
  }
);
