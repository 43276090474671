<template>
  <div v-if="employments.length === 1 && employments[0].company" class="pt-4 text-center">
    <div class="company">
      {{ employments[0].company.shortName }}
    </div>
    <div v-if="employments.length && employments[0].title" class="position t-grey--text pt-1">
      {{ employments[0].title }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ProfileCompanyInfo',

  computed: {
    ...mapGetters('profile', ['employments']),
  },

  created() {
    this.fetchEmployments();
  },

  methods: {
    ...mapActions('profile', ['fetchEmployments']),
  },
};
</script>
