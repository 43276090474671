export default [
  {
    path: '/admin/cap',
    name: 'admin.cap',
    components: {
      default: () => import(/* webpackChunkName: "admin-cap" */ '@/views/admin/cap/RoutePage.vue'),
      tabs: () => import(/* webpackChunkName: "admin-cap" */ '@/views/admin/cap/RouteTabs.vue'),
    },
    meta: { isAuth: true },
    children: [
      {
        path: 'companies',
        name: 'admin.cap.companies',
        components: {
          default: () =>
            import(
              /* webpackChunkName: "admin-cap" */ '@/views/admin/cap/companies/CompaniesPage.vue'
            ),
          search: () =>
            import(
              /* webpackChunkName: "admin-cap" */ '@/views/admin/cap/companies/CompanySearch.vue'
            ),
        },
        meta: { isAuth: true },
      },
      {
        path: 'profiles',
        name: 'admin.cap.profiles',
        components: {
          default: () =>
            import(
              /* webpackChunkName: "admin-cap" */ '@/views/admin/cap/profiles/ProfilesPage.vue'
            ),
          search: () =>
            import(
              /* webpackChunkName: "admin-cap" */ '@/views/admin/cap/profiles/ProfilesSearch.vue'
            ),
        },
        meta: { isAuth: true },
      },
      {
        path: 'member-requests',
        name: 'admin.cap.member-requests',
        component: () =>
          import(
            /* webpackChunkName: "admin-cap" */ '@/views/admin/cap/member-requests/MemberRequestsPage.vue'
          ),
        meta: { isAuth: true },
      },
      {
        path: 'contracts',
        name: 'admin.cap.contracts',
        components: {
          default: () =>
            import(
              /* webpackChunkName: "admin-cap" */ '@/views/admin/cap/contracts/ContractsPage.vue'
            ),
          search: () =>
            import(
              /* webpackChunkName: "admin-cap" */ '@/views/admin/cap/contracts/ContractSearch.vue'
            ),
        },
        meta: { isAuth: true },
      },
    ],
  },
  {
    path: '/admin/cap/companies/:id(\\d+)/view',
    name: 'admin.cap.companies.view',
    component: () =>
      import(/* webpackChunkName: "admin-cap" */ '@/views/admin/cap/companies/CompanyView.vue'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/cap/companies/new',
    name: 'admin.cap.companies.new',
    component: () =>
      import(/* webpackChunkName: "admin-cap" */ '@/views/admin/cap/companies/CompanyEditPage.vue'),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/cap/companies/:id(\\d+)/edit',
    name: 'admin.cap.companies.edit',
    component: () =>
      import(/* webpackChunkName: "admin-cap" */ '@/views/admin/cap/companies/CompanyEditPage.vue'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/cap/contracts/new',
    name: 'admin.cap.contracts.new',
    component: () =>
      import(/* webpackChunkName: "admin-cap" */ '@/views/admin/cap/contracts/ContractFormPage'),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/cap/contracts/:id(\\d+)/edit',
    name: 'admin.cap.contracts.edit',
    component: () =>
      import(/* webpackChunkName: "admin-cap" */ '@/views/admin/cap/contracts/ContractFormPage'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
];
