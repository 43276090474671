import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';
import i18n from '@/plugins/i18n';
import brandConfig from '@/brand';

const storedLocale = localStorage.getItem('locale');
if (storedLocale && storedLocale.startsWith('"')) {
  localStorage.removeItem('locale');
}

let locale =
  localStorage.getItem('locale') ||
  navigator.language.slice(0, 2) ||
  process.env.VUE_APP_I18N_LOCALE;
if (brandConfig.languages?.length === 1) {
  locale = brandConfig.languages[0];
}

const state = {
  locale: locale,
};

const getters = {
  locale: state => state.locale,
};

const actions = {
  setLocale({ commit }, payload) {
    Sentry.setTag('locale', payload);
    i18n.locale = payload;
    dayjs.locale(payload);
    localStorage.setItem('locale', payload);
    commit('SET_LOCALE', payload);
  },
};

const mutations = {
  SET_LOCALE(state, value) {
    state.locale = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
