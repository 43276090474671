import otrsApi from '@/api/otrsApi';
import bookingApi from '@/api/bookingApi';
import { makeDataModel } from '@/store/factory/dataModel';

export default makeDataModel(
  { service: otrsApi.admin.serviceTicketWithExecution },
  {
    actions: {
      async createReservation({ commit }, data) {
        return bookingApi.reservation.create(data).then(res => {
          commit('ADD', res.data);
          return res;
        });
      },

      async updateReservation({ commit }, data) {
        return bookingApi.reservation.update(data).then(res => {
          commit('UPDATE', res.data);
          return res;
        });
      },

      async updateState({ commit }, { id, state }) {
        return otrsApi.admin.serviceTicket.updateState({ id, state }).then(res => {
          commit('UPDATE', res.data);
          return res;
        });
      },

      async updateResponsible({ commit }, { id, responsibleId }) {
        return otrsApi.admin.serviceTicket.updateResponsible({ id, responsibleId }).then(res => {
          commit('UPDATE', res.data);
          return res;
        });
      },

      async updateDeadline({ commit }, { id, ...data }) {
        return otrsApi.admin.serviceTicket.updateDeadline({ id, ...data }).then(res => {
          commit('UPDATE', res.data);
          return res;
        });
      },

      async extendExpirationDate({ commit }, { id, ...data }) {
        return otrsApi.admin.serviceTicket.extendExpirationDate({ id, ...data }).then(res => {
          commit('UPDATE', res.data);
          return res;
        });
      },

      async paymentRefunded({ commit }, { id }) {
        return otrsApi.admin.serviceTicket.paymentRefunded({ id }).then(res => {
          commit('UPDATE', res.data);
          return res;
        });
      },

      async refreshAccess({ commit }, { id }) {
        return otrsApi.admin.serviceTicket.refreshAccess({ id }).then(res => {
          commit('UPDATE', res.data);
          return res;
        });
      },
    },
  }
);
