<template>
  <PageContent back-light>
    <v-row v-if="!isLoading" dense>
      <v-col v-if="showStories" cols="12">
        <StoriesFeed />
      </v-col>

      <v-col v-if="previousItems.length > 0" cols="12">
        <div v-if="previousItems.length && !showPrevious" class="pl-sm-6">
          <span
            class="text-caption text-md-body-2 t-grey--text"
            style="cursor: pointer"
            @click="onShowPrevious"
          >
            {{ $t('Показать прошедшие события') }}
          </span>
        </div>
        <div v-if="showPrevious">
          <div class="pl-sm-6">
            <span
              class="text-caption text-md-body-2 t-grey--text"
              style="cursor: pointer"
              @click="onHidePrevious"
            >
              {{ $t('Скрыть прошедшие события') }}
            </span>
          </div>
          <InlineItems :title="$t('Прошедшие события')" :items="previousItems" />
        </div>
      </v-col>

      <v-col ref="upcoming" cols="12">
        <InlineItems
          v-if="actualItems.length"
          :title="$t('Текущие события')"
          is-primary
          :items="actualItems"
        >
          <template #link>
            <router-link class="text-body-2 t-grey--text" :to="{ name: 'home.calendar' }">
              {{ $t('Посмотреть все события в календаре') }}
            </router-link>
          </template>
        </InlineItems>
        <UpcomingEvents
          v-else-if="canSeeEvents && upcomingEvents.length"
          :events="upcomingEvents"
        />
        <EmptyMessage v-else />
      </v-col>

      <v-col v-if="homeServices.length > 0" cols="12">
        <PartnerOffers :category="partnerCategory" :services="homeServices" />
      </v-col>

      <v-col v-if="homeCategories.length > 0" cols="12">
        <PopularServices :categories="homeCategories" />
      </v-col>

      <v-col v-if="canSeeEvents && actualDays.length && upcomingEvents.length > 0" cols="12">
        <UpcomingEvents :events="upcomingEvents" />
      </v-col>
    </v-row>
  </PageContent>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapGetters, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import PageContent from '@/components/ui/PageContent';
import EmptyMessage from '@/components/home/EmptyMessage.vue';
import UpcomingEvents from '@/views/home/main/UpcomingEvents.vue';
import PartnerOffers from '@/views/home/main/PartnerOffers.vue';
import PopularServices from '@/views/home/main/PopularServices.vue';
import InlineItems from '@/views/home/main/InlineItems.vue';
import StoriesFeed from '@/views/services/StoriesFeed.vue';

export default {
  name: 'MainPage',

  components: {
    StoriesFeed,
    InlineItems,
    PopularServices,
    PartnerOffers,
    UpcomingEvents,
    EmptyMessage,
    PageContent,
  },

  data: () => ({
    isLoading: false,
    showPrevious: false,
  }),

  computed: {
    ...mapFields('app', ['homeCalendarLoaded']),
    ...mapState('services', { servicesLoaded: 'loaded' }),
    ...mapGetters('db/memo', ['items']),
    ...mapGetters('upcomingEventList', { upcomingEvents: 'items' }),
    ...mapGetters('services', ['partnerCategory', 'homeServices', 'homeCategories']),
    ...mapGetters('permissions', ['canAccess']),

    todayDt() {
      return dayjs.tz().startOf('day');
    },

    itemsByDay() {
      const days = {};

      for (const item of this.items) {
        const date = dayjs.unix(item.startAt).tz().startOf('day');
        const time = date.unix();

        let day = days[time];
        if (!day) {
          day = { date: date, isToday: null, items: [] };
          days[time] = day;
        }

        day.items.push(item);
      }

      Object.values(days).forEach(day => {
        day.isToday = day.date.unix() === this.todayDt.unix();
      });

      return Object.values(days).sort((a, b) => a.date.unix() - b.date.unix());
    },

    daysCount() {
      return this.itemsByDay.length;
    },

    actualDays() {
      return this.itemsByDay.filter(x => x.date.unix() >= this.todayDt.unix());
    },

    previousDays() {
      return this.itemsByDay.filter(x => x.date.unix() < this.todayDt.unix());
    },

    allItems() {
      return [].concat(...Object.values(this.itemsByDay).map(x => x.items));
    },

    actualItems() {
      return [].concat(...Object.values(this.actualDays).map(x => x.items));
    },

    previousItems() {
      return [].concat(...Object.values(this.previousDays).map(x => x.items));
    },

    canSeeEvents() {
      return this.canAccess('event:event.search');
    },

    showStories() {
      return this.$location?.placeForStories === 'home';
    },
  },

  mounted() {
    this.isLoading = !this.homeCalendarLoaded;

    const promises = [this.fetchItems()];
    if (this.showStories) {
      promises.push(this.fetchStories());
    }
    if (this.canSeeEvents) {
      promises.push(this.fetchUpcomingEvents());
    }
    if (!this.servicesLoaded) {
      promises.push(this.fetchCatalog());
    }

    Promise.all(promises).finally(() => {
      this.isLoading = false;
      this.homeCalendarLoaded = true;
    });
  },

  methods: {
    ...mapActions('db/memo', { fetchItems: 'fetchItems' }),
    ...mapActions('services', ['fetchCatalog']),
    ...mapActions('stories', ['fetchStories']),
    ...mapActions('upcomingEventList', { fetchUpcomingEvents: 'fetchItems' }),

    onShowPrevious() {
      this.showPrevious = true;
      this.$nextTick(() => {
        this.$refs.upcoming.scrollIntoView();
        window.scrollBy(0, -this.$vuetify.application.top - 172);
      });
    },

    onHidePrevious() {
      this.showPrevious = false;
      // this.$nextTick(() => window.scrollTo(0, 0));
    },
  },
};
</script>
