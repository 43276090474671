/* global PushStream */
import 'pushstreamjs/src/pushstream';
import md5 from 'md5';
import eventBus from '@/eventBus';

let pushstream;

// channels:
// - article.create.data-push
// - service-ticket.reservation.push
// - service-ticket.state-change.data-push
// - service-ticket.state-change.notification-push
// - profile.member-request.push
// - profile.notification-job.push
// - auth.logout.push
// - coworking-ticket.status-change.data-push
// - service-ticket.coworking.push

const notificationChannels = [
  'profile.member-request.push',
  'service-ticket.state-change.notification-push',
  'profile.notification-job.push',
  'service-ticket.reservation.push',
  'service-ticket.coworking.push',
];

const initialState = () => ({
  viewedTicketId: null,
});

export default {
  namespaced: true,

  state: initialState,

  actions: {
    connect({ dispatch }) {
      if (pushstream) {
        return;
      }

      pushstream = new PushStream({
        useSSL: process.env.NODE_ENV === 'production',
        host: window.location.hostname,
        port: window.location.port,
        urlPrefixEventsource: '/push/ev',
        urlPrefixWebsocket: '/push/ws',
        urlPrefixStream: '/push/sub',
        urlPrefixLongpolling: '/push/lp',
        modes: process.env.NODE_ENV === 'development' ? 'longpolling' : 'eventsource|websocket',
      });

      pushstream.onmessage = text => {
        const { channel, data } = JSON.parse(atob(text));
        console.log('→', channel, data);
        dispatch('message', { channel, data });

        const eventName = `push:${channel}`;
        // dispatch(eventName, data, { root: true });
        eventBus.$emit(eventName, data);
      };

      const channel = md5(localStorage.getItem('fingerprint'));
      pushstream.addChannel(channel);
      pushstream.connect();
    },

    message({ state, dispatch }, { channel, data }) {
      if (notificationChannels.includes(channel)) {
        dispatch('notificationStats/fetchStats', null, { root: true });

        if (channel === 'profile.notification-job.push' && data.data?.showNotification) {
          return dispatch('importantNotificationList/fetchItems', null, { root: true });
        }

        if (
          data.data?.event === 'otrs.user.article.create' &&
          state.viewedTicketId &&
          state.viewedTicketId === data.data.serviceTicket.ticketId
        ) {
          return Promise.resolve();
        }

        dispatch(
          'snackbar/notify',
          {
            // message: formatNotification(data.message, data.data),
            notification: { title: data.title, message: data.message, data: data.data },
            duration: 10,
            push: true,
          },
          { root: true }
        );
      }

      if (channel === 'auth.logout.push') {
        dispatch('auth/logout', data, { root: true });
      }
    },

    disconnect() {
      if (pushstream) {
        pushstream.disconnect();
        pushstream = null;
      }
    },

    viewTicket({ commit }, { ticketId }) {
      commit('SET_DATA', { viewedTicketId: ticketId });
    },

    closeTicket({ commit }) {
      commit('SET_DATA', { viewedTicketId: null });
    },
  },

  mutations: {
    SET_DATA(state, obj) {
      Object.keys(obj).forEach(key => (state[key] = obj[key]));
    },

    RESET(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => (state[key] = newState[key]));
    },
  },
};
