<template>
  <PageContent back-light>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="text-h4 font-weight-bold mb-3">
          {{ $t('Раздел находится в разработке!') }}
        </h1>
      </v-col>
    </v-row>
  </PageContent>
</template>

<script>
import PageContent from '@/components/ui/PageContent';

export default {
  name: 'UnderConstruction',
  components: { PageContent },
};
</script>
