<template>
  <div ref="div">
    <!--    <v-snackbar color="t-additional" :timeout="-1" top right value="1" max-width="650">
      <div class="mb-2 font-weight-bold">
        Нужно поготовить пятый этаж к заселению, чтобы чайнкики могли включать.
      </div>
      <div>
        Будем работать максимально быстро, чтобы минимизировать неудобства. Спасибо за понимание.
        Максимально быстро, чтобы минимизировать неудобства. Спасибо за понимание.
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="remove({ id: 0 })">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>-->
    <v-snackbar
      v-for="notification in notifications"
      :key="notification.id"
      :color="notification.push ? 't-additional' : notification.type"
      :timeout="notification.duration"
      :top="notification.push"
      :right="notification.push"
      value="1"
      max-width="650"
      :data-id="notification.id"
      @input="remove({ id: notification.id })"
    >
      <Notification v-if="notification.notification" :notification="notification.notification" />
      <template v-else>
        <div v-if="notification.title" class="mb-2 font-weight-bold" v-text="notification.title" />
        <nl2br tag="div" :text="notification.message" />
      </template>
      <template #action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="remove({ id: notification.id })">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Notification from '@/components/notification/Notification';

export default {
  name: 'SnackbarNotifications',

  components: { Notification },

  computed: mapGetters('snackbar', ['notifications']),

  mounted() {
    this.$refs.div.addEventListener('click', this.clickHandler);
  },

  beforeDestroy() {
    this.$refs.div.removeEventListener('click', this.clickHandler);
  },

  methods: {
    ...mapActions('snackbar', ['remove', 'notify']),

    clickHandler(e) {
      if (e.target.tagName === 'A') {
        const last = this.notifications[this.notifications.length - 1];
        this.remove({ id: last.id });
      }
    },
  },
};
</script>
