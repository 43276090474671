import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';
import i18n from '@/plugins/i18n';

const initialState = () => {
  let timezone = window.localStorage.getItem('timezone');
  if (timezone === 'null') {
    timezone = null;
    window.localStorage.removeItem('timezone');
  }

  return { timezone };
};

const getters = {
  timezone: state => state.timezone,
};

const actions = {
  setTimezone({ commit }, timezone) {
    if (!timezone) {
      return;
    }

    Sentry.setTag('timezone', timezone);

    dayjs.tz.setDefault(timezone);

    i18n.availableLocales.forEach(locale => {
      const formats = i18n.getDateTimeFormat(locale);
      Object.keys(formats).forEach(index => {
        formats[index].timeZone = timezone;
      });
      i18n.setDateTimeFormat(locale, formats);
    });

    window.localStorage.setItem('timezone', timezone);

    commit('SET_TIMEZONE', timezone);
  },
};

const mutations = {
  SET_TIMEZONE(state, value) {
    state.timezone = value;
  },
};

export default {
  state: initialState,
  getters,
  actions,
  mutations,
};
