import Vue from 'vue';
import paymentApi from '@/api/paymentApi';

const initialState = () => ({
  paymentBySt: {},
  payments: {},
});

export default {
  namespaced: true,

  state: initialState,

  getters: {
    getPayment: state => serviceTicketId => {
      if (serviceTicketId in state.paymentBySt) {
        return state.paymentBySt[serviceTicketId];
      }

      if (serviceTicketId in state.payments) {
        return state.payments[serviceTicketId].at(-1);
      }

      return null;
    },

    getPayments: state => serviceTicketId => state.payments[serviceTicketId] ?? [],
  },

  actions: {
    async fetchPayment({ commit }, { serviceTicketId }) {
      return paymentApi
        .fetchPayment({ serviceTicketId })
        .then(res => commit('SET', { serviceTicketId, payment: res.data }));
    },

    async fetchPayments({ commit }, { serviceTicketId }) {
      return paymentApi
        .fetchPayments({ serviceTicketId })
        .then(res => commit('SET_PAYMENTS', { serviceTicketId, payments: res.data }));
    },

    async createPayment({ commit }, payload) {
      return paymentApi.createPayment(payload).then(res => {
        commit('SET', { serviceTicketId: payload.serviceTicketId, payment: res.data });
      });
    },
  },

  mutations: {
    SET(state, { serviceTicketId, payment }) {
      Vue.set(state.paymentBySt, serviceTicketId, payment);
    },

    SET_PAYMENTS(state, { serviceTicketId, payments }) {
      Vue.set(state.payments, serviceTicketId, payments);
    },
  },
};
