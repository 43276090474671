<template>
  <div class="ck-content editor-content" v-html="content" />
</template>

<script>
import '@/styles/ck-content.css';

export default {
  name: 'EditorContent',
  props: {
    content: String,
  },
};
</script>
