<template>
  <v-menu v-model="showMenu" content-class="profile-menu" min-width="270">
    <template #activator="{ on, attrs }">
      <div class="avatar-wrapper" :class="{ edge: isEdgeBrowser() }" v-bind="attrs" v-on="on">
        <div class="avatar">
          <v-img :src="photoUrl" class="polygon honeycomb" alt="Avatar" />
        </div>
      </div>
    </template>

    <v-btn small icon absolute top right @click="showMenu = false">
      <v-icon small color="#6F7882">mdi-close</v-icon>
    </v-btn>

    <v-sheet class="px-8">
      <div class="pt-6 d-flex flex-column align-center justify-center">
        <div class="big-avatar-wrapper" :class="{ edge: isEdgeBrowser() }">
          <div class="big-avatar">
            <v-img :src="photoUrl" class="polygon honeycomb" height="96" width="96" alt="Avatar" />
          </div>
        </div>
      </div>

      <div v-if="profile" class="pt-2 primary--text font-weight-bold text-center">
        {{ profile.fullName }}
      </div>

      <ProfileCompanyInfo />

      <v-divider class="mt-6" />
    </v-sheet>

    <v-list class="pt-4 pb-6">
      <v-list-item class="px-8" :to="{ name: 'profile' }">
        <v-list-item-action class="mr-2">
          <v-icon small>mdi-account-box-outline</v-icon>
        </v-list-item-action>
        <v-list-item-title>
          {{ $t('Редактировать личные данные') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="$brandConfig.isOlveryPlatform || 1"
        class="px-8"
        :to="{ name: 'about-app' }"
      >
        <v-list-item-action class="mr-2">
          <v-icon small>mdi-cog-outline</v-icon>
        </v-list-item-action>
        <v-list-item-title>
          {{ $t('О приложении') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="$env.VUE_APP_ENV === 'local'" class="px-8" :to="{ name: 'admin.upload' }">
        <v-list-item-action class="mr-2">
          <v-icon small>mdi-cog-outline</v-icon>
        </v-list-item-action>
        <v-list-item-title> Upload file </v-list-item-title>
      </v-list-item>
      <v-list-item class="px-8" @click="logout()">
        <v-list-item-action class="mr-2">
          <v-icon small>mdi-exit-to-app</v-icon>
        </v-list-item-action>
        <v-list-item-title>
          {{ $t('Выйти из профиля') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProfileCompanyInfo from '@/components/layout/ProfileCompanyInfo';

export default {
  name: 'ProfileMenu',

  components: {
    ProfileCompanyInfo,
  },

  data: () => ({
    showMenu: false,
  }),

  computed: {
    ...mapGetters('profile', ['profile']),

    photoUrl() {
      return this.profile?.photoUrl || require('@/assets/profile/t-dude_003.png');
    },
  },

  methods: {
    ...mapActions('auth', ['logout']),

    isEdgeBrowser() {
      return window.navigator.userAgent.indexOf('Edge') > -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-menu__content {
  border-radius: 16px;
  box-shadow: $elevation-4;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--v-t-grey-base) !important;
}

.company {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.18px;
}

.position {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.18px;
}

.v-list-item__title {
  font-size: 0.75rem !important;
  font-weight: normal;
}

.v-list-item--active {
  background-color: var(--v-t-back-light-base);
  color: var(--v-secondary-base);
}

.v-list-item--link:before {
  background-color: inherit;
}

.avatar-wrapper {
  padding: 0 3px;

  .avatar {
    height: 48px;
    width: 42px;

    .v-image {
      height: 48px;
      width: 48px;
    }
  }
}

.big-avatar-wrapper {
  padding: 0 6px;

  .big-avatar {
    height: 96px;
    width: 84px;

    .v-image {
      height: 96px;
      width: 96px;
    }
  }
}

.avatar,
.big-avatar {
  background-color: $back-light;
  clip-path: $polygon;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .avatar-wrapper {
    padding: 0 2px;

    .avatar {
      height: 36px;
      width: 32px;

      .v-image {
        height: 36px;
        width: 36px;
      }
    }
  }
}
</style>
