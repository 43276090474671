import { loadWith } from '@/store/factory/utils';

export function makeSimpleList(
  {
    name,
    dataModel,
    getKey,
    params: defaultParams,
    withRelations = [
      // {field, dataModel, idProp='id'}
    ],
  },
  ext = {}
) {
  const initialState = () => ({
    key: null,
    ...ext.state,
  });

  return {
    namespaced: true,

    state: initialState,

    getters: {
      items(state, _, __, rootGetters) {
        return rootGetters[`${dataModel}/getItems`](state.key);
      },

      meta(state, _, __, rootGetters) {
        const meta = rootGetters[`${dataModel}/getMeta`](state.key);
        return meta || { page: 0, pageCount: 0, pageSize: 0, totalCount: 0 };
      },

      getItem(state, _, __, rootGetters) {
        return id => rootGetters[`${dataModel}/getItem`](id);
      },

      ...ext.getters,
    },

    actions: {
      async fetchItems({ commit, dispatch, getters, rootGetters }, params) {
        const key =
          typeof getKey === 'function' ? getKey(params) : `${name}-${JSON.stringify(params)}`;

        const exists = rootGetters[`${dataModel}/hasKey`](key);
        if (exists) {
          commit('SET_KEY', { key });
        }

        const payload = { key, query: { ...defaultParams, ...params } };
        await dispatch(`${dataModel}/fetchItems`, payload, { root: true });
        if (!exists) {
          commit('SET_KEY', { key });
        }

        const items = getters.items;
        loadWith(items, withRelations, dispatch);
      },

      async fetchItem({ dispatch, rootGetters }, payload) {
        const key = await dispatch(`${dataModel}/fetchItem`, payload, { root: true });
        const item = rootGetters[`${dataModel}/getItem`](key);
        loadWith([item], withRelations, dispatch);
      },

      async create({ dispatch }, payload) {
        return dispatch(`${dataModel}/create`, payload, { root: true });
      },

      async update({ dispatch }, payload) {
        return dispatch(`${dataModel}/update`, payload, { root: true });
      },

      async delete({ dispatch }, payload) {
        return dispatch(`${dataModel}/delete`, payload, { root: true });
      },

      ...ext.actions,
    },

    mutations: {
      SET_KEY(state, { key }) {
        state.key = key;
      },

      RESET(state) {
        const newState = initialState();
        Object.keys(newState).forEach(key => (state[key] = newState[key]));
      },

      ...ext.mutations,
    },
  };
}
