import { makeDataModel } from '@/store/factory/dataModel';
import coworkingApi from '@/api/coworkingApi';

export default makeDataModel(
  { service: coworkingApi.tickets },
  {
    actions: {
      async updateUser({ commit }, { id, userId }) {
        return coworkingApi.tickets.updateUser({ id, userId }).then(res => {
          commit('UPDATE', res.data);
          return res;
        });
      },
    },
  }
);
