function inserted(el, binding) {
  const callback = binding.value;

  const observerCallback = entries => {
    window.requestAnimationFrame(() => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      callback();
    });
  };

  const observer = new ResizeObserver(observerCallback);

  if (!binding.modifiers || !binding.modifiers.quiet) {
    callback();
  }

  el._resizeObserve = { observer };

  observer.observe(el);
}

function unbind(el) {
  if (!el._resizeObserve) return;
  el._resizeObserve.observer.unobserve(el);
  delete el._resizeObserve;
}

export const ResizeObserverDirective = {
  inserted,
  unbind,
};

export default ResizeObserverDirective;
