import dayjs from 'dayjs';

const getters = {
  items: (state, _, __, rootGetters) =>
    rootGetters['db/notifications/getItems']('popup').filter(x => !x.seenAt),
};

const actions = {
  fetchItems({ dispatch }) {
    return dispatch(
      'db/notifications/fetchItems',
      { key: 'popup', query: { showPopupAt: dayjs().unix(), seen: 0, limit: 10 } },
      { root: true }
    );
  },
};

export default {
  namespaced: true,
  getters,
  actions,
};
