<template>
  <v-badge :value="!!content" :content="content" color="primary" class="mt-0" inline bordered>
    <DefaultItem v-bind="$attrs" />
  </v-badge>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DefaultItem from '@/components/layout/menu/item/DefaultItem';

export default {
  name: 'CompaniesAndPeople',

  components: { DefaultItem },

  computed: {
    ...mapGetters('adminMemberRequestPage', ['processingCount']),

    content() {
      const count = this.processingCount;
      if (!count) return null;
      return count < 10 ? count : '9+';
    },
  },

  mounted() {
    this.fetchStats();
  },

  methods: {
    ...mapActions('adminMemberRequestPage', ['fetchStats']),
  },
};
</script>
