export default [
  {
    path: '/community',
    name: 'community',
    components: {
      default: () => import(/* webpackChunkName: "community" */ '@/views/community/RoutePage.vue'),
      search: () => import(/* webpackChunkName: "community" */ '@/views/community/CommunitySearch'),
      tabs: () => import(/* webpackChunkName: "community" */ '@/views/community/RouteTabs.vue'),
    },
    meta: { isAuth: true },
    children: [
      {
        path: 'companies',
        name: 'companies',
        component: () =>
          import(/* webpackChunkName: "community" */ '@/views/community/companies/CompanyList'),
        meta: { isAuth: true },
      },
      {
        path: 'people',
        name: 'people',
        component: () =>
          import(/* webpackChunkName: "community" */ '@/views/community/people/PeoplePage'),
        meta: { isAuth: true },
      },
      {
        path: 'search',
        name: 'community.search',
        component: () =>
          import(/* webpackChunkName: "community" */ '@/views/community/search/SearchPage'),
        meta: { isAuth: true, backBtn: true },
      },
    ],
  },
  {
    path: '/community/companies/:id(\\d+)',
    name: 'companies.view',
    component: () =>
      import(/* webpackChunkName: "community" */ '@/views/community/companies/CompanyView'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
];
