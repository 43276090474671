import { Layout } from '@/layouts';
import UnderConstruction from '@/components/UnderConstruction.vue';

export default [
  {
    path: '/admin/ads',
    name: 'admin.ads',
    components: {
      default: () => import(/* webpackChunkName: "admin-ads" */ '@/views/admin/ads/AdPage.vue'),
      tabs: () => import(/* webpackChunkName: "admin-ads" */ '@/views/admin/ads/AdTabs.vue'),
    },
    meta: { isAuth: true },
    children: [
      {
        path: 'notifications',
        name: 'admin.ads.notifications',
        component: () =>
          import(
            /* webpackChunkName: "admin-ads" */ '@/views/admin/ads/notifications/NotificationsPage.vue'
          ),
        meta: { isAuth: true },
      },
      {
        path: 'stories',
        name: 'admin.ads.stories',
        component: () =>
          import(/* webpackChunkName: "admin-ads" */ '@/views/admin/ads/stories/StoriesPage.vue'),
        meta: { isAuth: true },
      },
      {
        path: 'banners',
        name: 'admin.ads.banners',
        component: UnderConstruction,
        meta: { isAuth: true },
      },
    ],
  },
  {
    path: '/admin/ads/notifications/new',
    name: 'admin.ads.notifications.new',
    component: () =>
      import(
        /* webpackChunkName: "admin-ads" */ '@/views/admin/ads/notifications/NotificationFormPage.vue'
      ),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/ads/notifications/:id(\\d+)/edit',
    name: 'admin.ads.notifications.edit',
    component: () =>
      import(
        /* webpackChunkName: "admin-ads" */ '@/views/admin/ads/notifications/NotificationFormPage.vue'
      ),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/ads/stories/new',
    name: 'admin.ads.stories.new',
    component: () =>
      import(/* webpackChunkName: "admin-ads" */ '@/views/admin/ads/stories/StoryForm.vue'),
    meta: { isAuth: true, layout: Layout.SIMPLE },
  },
  {
    path: '/admin/ads/stories/:id(\\d+)',
    name: 'admin.ads.stories.edit',
    component: () =>
      import(/* webpackChunkName: "admin-ads" */ '@/views/admin/ads/stories/StoryForm.vue'),
    meta: { isAuth: true, layout: Layout.SIMPLE },
  },
];
