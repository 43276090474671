<template>
  <v-img :src="imgSrc" contain max-width="126" height="40" transition="scale-transition" />
</template>

<script>
export default {
  name: 'AppLogo',

  inject: {
    theme: {
      default: {
        isDark: false,
      },
    },
  },

  props: {
    src: String,
    dark: Boolean,
  },

  computed: {
    imgSrc() {
      return this.src || this.$brandConfig.img.navLogo;
    },
  },
};
</script>
