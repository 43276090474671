import Vue from 'vue';
import paymentApi from '@/api/paymentApi';

const initialState = () => ({
  payments: {},
});

export default {
  namespaced: true,

  state: initialState,

  getters: {
    getPayments: state => serviceTicketId => state.payments[serviceTicketId] ?? [],
  },

  actions: {
    async createCashPayment(_, payload) {
      // const { serviceTicketId } = payload;

      const { data } = await paymentApi.admin.createCashPayment(payload);

      console.log(data);

      // return dispatch('fetchPayments', { serviceTicketId });
    },

    async fetchPayments({ commit }, { serviceTicketId }) {
      return paymentApi
        .fetchPayments({ serviceTicketId })
        .then(res => commit('SET_PAYMENTS', { serviceTicketId, payments: res.data }));
    },
  },

  mutations: {
    SET_PAYMENTS(state, { serviceTicketId, payments }) {
      Vue.set(state.payments, serviceTicketId, payments);
    },
  },
};
