<template>
  <router-view />
</template>

<script>
export default {
  name: 'HomePage',
  metaInfo() {
    return {
      title: this.$t('nav.user.home'),
    };
  },
};
</script>
