import http from './http';

const baseUrl = (process.env.VUE_APP_API_BILLING || '').replace(/\/$/g, '');

const billingApi = {
  cardRegistration(data) {
    return http.post(`${baseUrl}/card`, data);
  },

  applePayValidation(data) {
    return http.post(`${baseUrl}/apple-pay/validation`, data);
  },

  bankCard: {
    list: params => http.get(`${baseUrl}/card`, { params }),
    delete: ({ token }) => http.delete(`${baseUrl}/card/${token}`),
  },

  accountant: {
    payment: {
      list: params => http.get(`${baseUrl}/accountant/payment`, { params }),
      // find: ({ id }) => http.get(`${baseUrl}/accountant/payment/${id}`),
      update: ({ id, ...data }) => http.put(`${baseUrl}/accountant/payment/${id}`, data),
    },
  },
};

export default billingApi;
