export default {
  namespaced: true,

  state: {
    notifications: [],
  },

  getters: {
    notifications: state => state.notifications,
  },

  actions: {
    notify({ commit }, payload) {
      commit('PUSH', payload);
    },
    error({ commit }, message) {
      commit('PUSH', { message, type: 'error' });
    },
    remove({ commit }, payload) {
      commit('REMOVE', payload);
    },
    clear({ commit }) {
      commit('RESET');
    },
  },

  mutations: {
    PUSH(state, payload) {
      const defaults = {
        id: new Date().getTime(),
        type: 'default',
        duration: 8,
        notification: null,
      };

      const notification =
        typeof payload === 'string'
          ? { ...defaults, message: payload }
          : { ...defaults, ...payload };

      notification.duration *= 1000;

      state.notifications.push(notification);
    },
    REMOVE(state, payload) {
      state.notifications = state.notifications.filter(({ id }) => id !== payload.id);
    },
    RESET(state) {
      state.notifications = [];
    },
  },
};
