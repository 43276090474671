export default [
  {
    path: '/admin/services',
    name: 'admin.services',
    components: {
      default: () => import(/* webpackChunkName: "admin" */ '@/views/admin/services/ServicesPages'),
      tabs: () => import(/* webpackChunkName: "admin" */ '@/views/admin/services/RouteTabs'),
    },
    meta: { isAuth: true },
  },
  {
    path: '/admin/services/new',
    name: 'admin.services.new',
    components: {
      default: () =>
        import(/* webpackChunkName: "admin" */ '@/views/admin/services/ServiceFormPage'),
      tabs: () => import(/* webpackChunkName: "admin" */ '@/views/admin/services/ServiceFormTabs'),
    },
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/services/:id(\\d+)/edit',
    name: 'admin.services.edit',
    components: {
      default: () =>
        import(/* webpackChunkName: "admin" */ '@/views/admin/services/ServiceFormPage'),
      tabs: () => import(/* webpackChunkName: "admin" */ '@/views/admin/services/ServiceFormTabs'),
    },
    meta: { isAuth: true, backBtn: true },
  },
];
