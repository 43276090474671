import profileApi from '@/api/profileApi';

export default {
  namespaced: true,

  state: {
    stats: [],
  },

  getters: {
    stats: state => state.stats,

    processingCount: state => state.stats.find(x => x.status === 'processing')?.count,
  },

  actions: {
    async confirmMemberRequest({ dispatch }, { id, title, employee }) {
      await dispatch(`db/adminEmployees/create`, employee, { root: true });
      await dispatch(
        `db/adminMemberRequests/update`,
        { id, title, status: 'accepted' },
        { root: true }
      );
    },

    async declineMemberRequest({ dispatch }, { id }) {
      return dispatch(`db/adminMemberRequests/update`, { id, status: 'declined' }, { root: true });
    },

    async fetchStats({ commit }) {
      return profileApi.admin.memberRequest.stats().then(res => commit('SET_STATS', res.data));
    },
  },

  mutations: {
    SET_STATS(state, stats) {
      state.stats = stats;
    },
  },
};
