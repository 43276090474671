import offerApi from '@/api/offerApi';

const initialState = () => ({
  offerByLocation: {},
  acceptanceByOffer: {},
});

const state = initialState();

const getters = {
  getLocationOffer: state => locationId => state.offerByLocation[locationId] ?? null,
  isLocationAccepted: (state, getters) => locationId => {
    const offer = getters.getLocationOffer(locationId);
    if (!offer) {
      return false;
    }
    return !!state.acceptanceByOffer[offer.id];
  },
};

const actions = {
  async fetchPublicOfferInfo({ commit }, { locationId }) {
    const { data: offers } = await offerApi.fetchPublicOffers({ locationId });
    const offer = offers[0] ?? null;
    if (offer) {
      const { data: acceptance } = await offerApi.fetchPublicOfferAcceptance({ id: offer.id });
      if (acceptance) {
        commit('ADD_ACCEPTANCE', acceptance);
      }
      commit('ADD_OFFER', { locationId, offer });
    }
  },

  async acceptOffer({ commit }, { offerId }) {
    const { data } = await offerApi.acceptPublicOffer({ id: offerId });
    commit('ADD_ACCEPTANCE', data);
  },

  async fetchPublicOffer({ commit }, { locationId }) {
    const { data: offers } = await offerApi.fetchPublicOffers({ locationId });
    const offer = offers[0] ?? null;
    commit('ADD_OFFER', { locationId, offer });
  },

  async updatePublicOffer({ commit }, data) {
    const { data: offer } = await offerApi.updatePublicOffer(data);
    commit('ADD_OFFER', { locationId: offer.locations[0], offer });
  },
};

const mutations = {
  ADD_OFFER(state, { locationId, offer }) {
    state.offerByLocation = {
      ...state.offerByLocation,
      [locationId]: offer,
    };
  },

  ADD_ACCEPTANCE(state, offerAcceptance) {
    state.acceptanceByOffer = {
      ...state.acceptanceByOffer,
      [offerAcceptance.offer.id]: offerAcceptance,
    };
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
