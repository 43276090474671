<template>
  <v-img v-resize="onResize" :height="height" :max-height="height" v-bind="$attrs">
    <slot />
  </v-img>
</template>

<script>
import { Resize } from 'vuetify/lib/directives';

export default {
  name: 'AdaptiveImg',

  directives: {
    // fix: Failed to resolve directive: resize
    resize: Resize,
  },

  props: {
    aspectRatio: {
      type: Number,
      default: 3 / 2,
    },
  },

  data: () => ({
    height: null,
  }),

  methods: {
    onResize() {
      this.$nextTick(() => {
        const { width } = this.$el.getBoundingClientRect();
        this.height = `${width / this.aspectRatio}px`;
      });
    },
  },
};
</script>
