import { makeDataModel } from '@/store/factory/dataModel';
import eventApi from '@/api/eventApi';

export default makeDataModel(
  { service: eventApi.admin.ticket },
  {
    actions: {
      async checkin({ commit }, { id }) {
        const res = await eventApi.admin.ticket.checkin({ id });
        commit('UPDATE', res.data);
      },
    },
  }
);
