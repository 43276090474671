<template>
  <AppMain :back-light="backLight">
    <v-container :class="[centered && 'fill-height']" class="px-2 py-4 px-sm-10 py-sm-8" fluid>
      <slot />
    </v-container>
  </AppMain>
</template>

<script>
import AppMain from '@/components/ui/AppMain';

export default {
  name: 'PageContent',
  components: { AppMain },
  props: {
    backLight: Boolean,
    centered: Boolean,
  },
};
</script>
