import http from './http';
import { OfferType } from '@/constants/offer';

const baseUrl = (process.env.VUE_APP_API_OFFER || '').replace(/\/$/g, '');

const offerApi = {
  fetchOfferAcceptanceAccessRules() {
    return http.get(`${baseUrl}/offer-acceptance-access-rule`);
  },
  acceptOffer(data) {
    return http.post(`${baseUrl}/user-offer-acceptance`, data);
  },
  acceptResidentOffer(data) {
    return http.post(`${baseUrl}/company-offer-resident`, data);
  },
  // fetchResidentOffer({ companyId }) {
  //   return http.get(`${baseUrl}/company-offer-resident/${companyId}`);
  // },
  // deleteOfferResidentAcceptance(companyId) {
  //   return http.delete(`${baseUrl}/company-offer-resident/${companyId}`);
  // },
  fetchPublicOffers(params) {
    return http.get(`${baseUrl}/public-offer`, { params });
  },
  updatePublicOffer({ id, ...data }) {
    return http.patch(`${baseUrl}/public-offer/${id}`, data);
  },
  fetchPublicOfferAcceptance({ id }) {
    return http.get(`${baseUrl}/public-offer/${id}/acceptance`);
  },
  acceptPublicOffer({ id }) {
    return http.put(`${baseUrl}/public-offer/${id}/acceptance`);
  },
  // saas
  acceptSaasCompanyOffer({ locationId, ...data }) {
    return http.post(`${baseUrl}/company-offer-saas/${locationId}`, data);
  },
  fetchSaasOffers(params) {
    return http.get(`${baseUrl}/saas/offer`, { params });
  },
  upsertSaasOffer(data) {
    return http.put(`${baseUrl}/saas/offer`, data);
  },
  fetchSaasOfferAcceptances(params) {
    return http.get(`${baseUrl}/saas/offer-acceptance`, { params });
  },
  upsertSaasOfferAcceptance(data) {
    return http.put(`${baseUrl}/saas/offer-acceptance`, data);
  },
  deleteSaasOfferAcceptance(id) {
    return http.delete(`${baseUrl}/saas/offer-acceptance/${id}`);
  },

  offer: {
    list: params => http.get(`${baseUrl}/offer`, { params }),
    find: ({ id }) => http.get(`${baseUrl}/offer/${id}`),
    create: data => http.post(`${baseUrl}/offer`, data),
    update: ({ id, ...data }) => http.patch(`${baseUrl}/offer/${id}`, data),
    upsert: data => http.put(`${baseUrl}/offer`, data),
    delete: ({ id }) => http.delete(`${baseUrl}/offer/${id}`),
  },

  offerAcceptance: {
    list: params => http.get(`${baseUrl}/offer-acceptance`, { params }),
    find: ({ id }) => http.get(`${baseUrl}/offer-acceptance/${id}`),
    create: data => http.post(`${baseUrl}/offer-acceptance`, data),
    update: ({ id, ...data }) => http.patch(`${baseUrl}/offer-acceptance/${id}`, data),
  },

  offerTemplate: {
    list: params => http.get(`${baseUrl}/offer-template`, { params }),
    create: data => http.post(`${baseUrl}/offer-template`, data),
    update: ({ id, ...data }) => http.patch(`${baseUrl}/offer-template/${id}`, data),
  },

  my: {
    fetchAccessibleUnits: () => http.get(`${baseUrl}/my/accessible-units`),
    fetchAccessibleLevels: () => http.get(`${baseUrl}/my/accessible-levels`),
  },

  admin: {
    fetchCompanyAccessibleUnits: ({ locationId, companyId }) =>
      http.get(`${baseUrl}/admin/accessible-units/company/${companyId}/location/${locationId}`),
  },
};

export default offerApi;

export async function batchUpdateAccessRules({ userIds, makeAccessRules }) {
  const acceptors = userIds.map(id => `pe-${id}`);
  const { data: acceptances } = await offerApi.offerAcceptance.list({
    offerType: OfferType.PUBLIC_OFFER,
    acceptor: acceptors.join(','),
  });

  const updates = [];
  for (const acceptor of acceptors) {
    const publicOfferAcceptance = acceptances.find(x => x.acceptor === acceptor);
    if (!publicOfferAcceptance) {
      continue;
    }
    const accessRules = makeAccessRules(publicOfferAcceptance.accessRules);
    updates.push(
      offerApi.offerAcceptance.update({
        id: publicOfferAcceptance.id,
        accessRules,
        acceptor,
        offerId: publicOfferAcceptance.offer.id,
      })
    );
  }

  await Promise.all(updates);
}
