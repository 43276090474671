import { Layout } from '@/layouts';

export default [
  {
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/AuthPage.vue'),
    meta: { isAuth: false },
    children: [
      {
        path: '',
        name: 'auth.login',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/LoginPage.vue'),
      },
      {
        path: '/auth/register',
        name: 'auth.register',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/RegisterPage'),
      },
      {
        path: '/auth/location',
        name: 'auth.location',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/LocationPage'),
      },
    ],
  },
  {
    path: '/login-vk',
    name: 'auth.login-vk',
    component: () => import(/* webpackChunkName: "auth-vk" */ '@/views/auth/LoginVK'),
    meta: { isAuth: false, layout: Layout.SIMPLE },
  },
];
