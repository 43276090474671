import { makeDataModel } from '@/store/factory/dataModel';
import profileApi from '@/api/profileApi';
import otrsApi from '@/api/otrsApi';

export default makeDataModel(
  {
    service: profileApi.admin.profile,
    idProp: 'userId',
  },
  {
    getters: {
      accountants: (state, getters) => getters.getItems(`accountants`),

      getServiceResponsible: (state, getters) => (type, offerorId) =>
        getters.getItems(['resp', type, offerorId].join('-')),
    },
    actions: {
      async fetchAccountants({ state, commit }) {
        const key = `accountants`;
        if (state.fetchingKeys.includes(key)) {
          return key;
        }

        commit('ADD_FETCH_KEY', { key });
        const response = await otrsApi.admin.accountants.list();
        commit('ADD_QUERY', { key, response, query: { accountants: 1 } });

        return key;
      },

      async fetchServiceResponsible({ state, commit }, { type, offerorId }) {
        const key = ['resp', type, offerorId].join('-');
        if (state.fetchingKeys.includes(key)) {
          return key;
        }

        commit('ADD_FETCH_KEY', { key });
        const response = await otrsApi.admin.serviceResponsible.list({ type, offerorId });
        commit('ADD_QUERY', { key, response, query: { resp: 1, type, offerorId } });

        return key;
      },

      async addUserToLocation({ commit, dispatch }, { userId, profile }) {
        const response = await profileApi.admin.profile.addToLocation({
          ...profile,
          id: userId,
        });
        if (response.data.fullName) {
          const query = { id: userId };
          commit('ADD_QUERY', { key: JSON.stringify(query), response, query });
        } else {
          await dispatch('update', { ...profile, id: userId });
        }
      },
    },
  }
);
