export default [
  {
    path: '/news',
    name: 'news',
    components: {
      default: () => import(/* webpackChunkName: "news" */ '@/views/news/NewsPage'),
      search: () => import(/* webpackChunkName: "news" */ '@/views/news/NewsSearch'),
    },
    meta: { isAuth: true },
  },
  {
    path: '/news/:id(\\d+)',
    name: 'news.view',
    component: () => import(/* webpackChunkName: "news" */ '@/views/news/NewsViewPage'),
    props: true,
    meta: { isAuth: true, backBtn: true },
  },
];
