<template>
  <v-app>
    <v-row class="ma-0 align-center justify-center">
      <v-progress-circular indeterminate color="primary" />
    </v-row>
  </v-app>
</template>

<script>
export default {
  name: 'AppLoader',
};
</script>
