import http from './http';

const baseUrl = (process.env.VUE_APP_API_AUTH || '').replace(/\/$/g, '');

export default {
  fetchBrand: () => http.get(`${baseUrl}/brand`, null, { skipAuth: true }),
  registerDevice: data => http.post(`${baseUrl}/device`, data, { skipAuth: true }),
  authLogin: data => http.post(`${baseUrl}/auth-login`, data, { skipAuth: true }),
  smsLogin: data => http.post(`${baseUrl}/sms-login`, data, { skipAuth: true }),
  smsConfirm: data =>
    http.post(`${baseUrl}/sms-confirm`, data, { skipAuth: true, errorHandle: false }),
  authBindConfirm: data => http.post(`${baseUrl}/auth-bind-confirm`, data, { skipAuth: true }),
  refreshToken: data => http.post(`${baseUrl}/refresh-token`, data, { skipAuth: true }),
  fetchUserServices: userId => http.get(`${baseUrl}/user/${userId}/services`),
  attachOauthService: (userId, data) => http.post(`${baseUrl}/user/${userId}/services`, data),
  fetchOauthInfo: (userId, service, token) => {
    const config = { headers: {} };
    if (token) {
      config.headers['X-OAUTH-TOKEN'] = token;
    }

    return http.get(`${baseUrl}/user/${userId}/services/${service}`, config);
  },
  detachOauthService: (userId, service) =>
    http.delete(`${baseUrl}/user/${userId}/services/${service}`),

  city: {
    list: params => http.get(`${baseUrl}/city`, { params }),
    create: data => http.post(`${baseUrl}/city`, data),
  },

  country: {
    list: params => http.get(`${baseUrl}/country`, { params }),
    create: data => http.post(`${baseUrl}/country`, data),
  },

  location: {
    list: params => http.get(`${baseUrl}/location`, { params }),
    find: ({ id }) => http.get(`${baseUrl}/location/${id}`, { errorHandle: false }),
    choose: data => http.post(`${baseUrl}/location`, data),
  },

  staticPage: {
    find: ({ id }) => http.get(`${baseUrl}/static-page/${id}`),
  },

  timezone: {
    list: params => http.get(`${baseUrl}/admin/timezone`, { params }),
  },

  erase: {
    sendCode: data => http.post(`${baseUrl}/call-erase`, data),
    confirmCode: data => http.post(`${baseUrl}/erase-confirm`, data, { errorHandle: false }),
  },

  admin: {
    location: {
      list: params => http.get(`${baseUrl}/admin/location`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/admin/location/${id}`),
      create: data => http.post(`${baseUrl}/admin/location`, data),
      update: ({ id, ...data }) => http.patch(`${baseUrl}/admin/location/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/admin/location/${id}`),
      updateContacts: ({ id, ...data }) =>
        http.put(`${baseUrl}/admin/location/${id}/geo-contact`, data),
      updateAbout: ({ id, ...data }) => http.put(`${baseUrl}/admin/location/${id}/about`, data),
    },

    staticPage: {
      list: params => http.get(`${baseUrl}/admin/static-page`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/admin/static-page/${id}`),
      create: data => http.post(`${baseUrl}/admin/static-page`, data),
      update: ({ id, ...data }) => http.patch(`${baseUrl}/admin/static-page/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/admin/static-page/${id}`),
    },

    user: {
      list: params => http.get(`${baseUrl}/admin/user`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/admin/user/${id}`),
      create: data => http.post(`${baseUrl}/admin/user`, data),
      update: ({ id, ...data }) => http.patch(`${baseUrl}/admin/user/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/admin/user/${id}`),
    },

    updateSupportCategory: ({ id, ...data }) =>
      http.put(`${baseUrl}/admin/location/${id}/support-category`, data),
  },
};
