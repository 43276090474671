import { makeDataModel } from '@/store/factory/dataModel';
import otrsPosterApi, { categoryService, productService } from '@/api/otrsPosterApi';

export default {
  namespaced: true,

  modules: {
    category: makeDataModel({ service: categoryService }),

    product: makeDataModel({ service: productService }),
  },

  getters: {
    getServiceCategories: (state, _, __, rootGetters) => serviceId =>
      rootGetters['poster/category/getItems'](serviceId),

    getServiceProducts: (state, _, __, rootGetters) => serviceId =>
      rootGetters['poster/product/getItems'](serviceId),

    getCategory: (state, _, __, rootGetters) => id => rootGetters['poster/category/getItem'](id),

    getProduct: (state, _, __, rootGetters) => id => rootGetters['poster/product/getItem'](id),
  },

  actions: {
    fetchServiceCategories({ dispatch }, { serviceId }) {
      return dispatch(
        'poster/category/fetchItems',
        { key: serviceId, query: { serviceId, limit: 1000 } },
        { root: true }
      );
    },

    fetchServiceProducts({ dispatch }, { serviceId }) {
      return dispatch(
        'poster/product/fetchItems',
        { key: serviceId, query: { serviceId, limit: 1000 } },
        { root: true }
      );
    },

    async createOrder(context, payload) {
      return otrsPosterApi.createOrder(payload);
    },
  },
};
