<script>
export default {
  name: 'BaseDialog',

  dialogController: null,

  data: () => ({
    dialog: false,
  }),

  watch: {
    dialog(val) {
      if (!val) {
        return this.$options.dialogController.resolve(false);
      }
    },
  },

  methods: {
    /**
     * @public
     */
    open() {
      return new Promise((resolve, reject) => {
        this.$options.dialogController = { resolve, reject };
        this.dialog = true;
      });
    },

    close() {
      this.dialog = false;
    },

    resolve(data) {
      this.$options.dialogController.resolve(data ?? true);
      this.close();
    },
  },
};
</script>
