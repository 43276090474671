export const EventStatus = {
  UPCOMING: 'upcoming',
  CURRENT: 'current',
  PASSED: 'passed',
  CANCELED: 'canceled',
};

export const EventTicketStatus = {
  NEW: 'NEW',
  CONFIRMED: 'CONFIRMED',
  CHECKED: 'CHECKED',
  CANCELED: 'CANCELED',
};

export const EventTicketTariff = {
  GUEST: 'guest',
  RESIDENT: 'resident',
  FREE: 'free',
};
