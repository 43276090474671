import profileApi from '@/api/profileApi';
import { makeDataModel } from '@/store/factory/dataModel';
import { denormalizeOperationAlias } from '@/utils/rbac';

export default makeDataModel(
  { service: profileApi.admin.company },
  {
    getters: {
      getOperationCompanies: (state, getters) => (operation, userId) =>
        getters.getItems(`${operation}-${userId}`),
      suppliers: (state, getters) => getters.getItems(`suppliers`),
      mySuppliers: (state, getters) => getters.getItems(`mySuppliers`),
      allCompanies: (state, getters) => getters.getItems(`allCompanies`),
    },

    actions: {
      async fetchOperationCompanies({ commit }, { operation, userId }) {
        const obj = denormalizeOperationAlias(operation);

        const key = `${operation}-${userId}`;
        const params = {
          microservice: obj.microservice,
          operation: obj.operationId,
          user: userId,
        };

        commit('ADD_FETCH_KEY', { key });
        const response = await profileApi.admin.company.listByOperation(params);
        commit('ADD_QUERY', { key, response, query: { byOperation: 1, ...params } });

        return key;
      },

      async fetchSuppliers({ commit }, params) {
        const key = `suppliers`;

        commit('ADD_FETCH_KEY', { key });
        const response = await profileApi.admin.company.allSuppliers(params);
        commit('ADD_QUERY', { key, response, query: { ...params } });

        return key;
      },

      async fetchMySuppliers({ commit }, params) {
        const key = `mySuppliers`;

        commit('ADD_FETCH_KEY', { key });
        const response = await profileApi.admin.company.mySuppliers(params);
        commit('ADD_QUERY', { key, response, query: { ...params } });

        return key;
      },

      async fetchAllCompanies({ commit }, params) {
        const key = `allCompanies`;

        commit('ADD_FETCH_KEY', { key });
        const response = await profileApi.admin.company.allCompanies(params);
        commit('ADD_QUERY', { key, response, query: { ...params } });

        return key;
      },
    },
  }
);
