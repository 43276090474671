<template>
  <v-app id="app">
    <OpenInAppBar />
    <Navigation />
    <AppBar />
    <slot />
    <PhotoViewer />
    <PushStreamConnect />
    <SnackbarNotifications />
    <ImportantNotifications />
  </v-app>
</template>

<script>
import Navigation from '@/components/layout/Navigation';
import SnackbarNotifications from '@/components/layout/SnackbarNotifications';
import AppBar from '@/components/layout/AppBar';
import PhotoViewer from '@/components/common/gallery/PhotoViewer';
import ImportantNotifications from '@/components/layout/ImportantNotifications';
import OpenInAppBar from '@/components/layout/OpenInAppBar';
import PushStreamConnect from '@/components/layout/PushStreamConnect.vue';

export default {
  components: {
    PushStreamConnect,
    OpenInAppBar,
    ImportantNotifications,
    SnackbarNotifications,
    PhotoViewer,
    AppBar,
    Navigation,
  },
};
</script>
