import http from './http';

const baseUrl = (process.env.VUE_APP_API_RBAC || '').replace(/\/$/g, '');

const rbacApi = {
  fetchMicroServices(params) {
    return http.get(`${baseUrl}/micro-service`, { params });
  },
  fetchMicroService(id, params) {
    return http.get(`${baseUrl}/micro-service/${id}`, { params });
  },
  updateMicroService(id, data) {
    return http.patch(`${baseUrl}/micro-service/${id}`, data);
  },
  fetchMicroServiceOperations(params) {
    return http.get(`${baseUrl}/micro-service-operation`, { params });
  },
  fetchMicroServiceOperation(id, params) {
    return http.get(`${baseUrl}/micro-service-operation/${id}`, { params });
  },
  updateMicroServiceOperation(id, data) {
    return http.patch(`${baseUrl}/micro-service-operation/${id}`, data);
  },
  fetchPermissions(params) {
    return http.get(`${baseUrl}/permission`, { params });
  },
  fetchPermission(id, params) {
    return http.get(`${baseUrl}/permission/${id}`, { params });
  },
  createPermission(data) {
    return http.post(`${baseUrl}/permission`, data);
  },
  updatePermission({ id, ...data }) {
    return http.patch(`${baseUrl}/permission/${id}`, data);
  },
  deletePermission(id) {
    return http.delete(`${baseUrl}/permission/${id}`);
  },
  fetchUserOperation(params) {
    return http.get(`${baseUrl}/user-operation`, { params });
  },
  acceptOffer(data) {
    return http.post(`${baseUrl}/user-offer-acceptance`, data);
  },
  fetchOperationOfferors({ operationId }) {
    return http.get(`${baseUrl}/operation-offerors/${operationId}`);
  },

  permission: {
    list: params => http.get(`${baseUrl}/permission`, { params }),
    find: ({ id, ...params }) => http.get(`${baseUrl}/permission/${id}`, { params }),
    create: data => http.post(`${baseUrl}/permission`, data),
    update: ({ id, ...data }) => http.patch(`${baseUrl}/permission/${id}`, data),
    delete: ({ id }) => http.delete(`${baseUrl}/permission/${id}`),
  },

  role: {
    list: params => http.get(`${baseUrl}/role`, { params }),
  },

  admin: {
    role: {
      list: params => http.get(`${baseUrl}/admin/role`, { params }),
      create: data => http.post(`${baseUrl}/admin/role`, data),
      update: ({ id, ...data }) => http.patch(`${baseUrl}/admin/role/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/admin/role/${id}`),
    },
  },

  operator: {
    role: {
      list: params => http.get(`${baseUrl}/operator/role`, { params }),
      create: data => http.post(`${baseUrl}/operator/role`, data),
      update: ({ id, ...data }) => http.patch(`${baseUrl}/operator/role/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/operator/role/${id}`),
    },
  },
};

export default rbacApi;
