<template>
  <div v-if="categories && categories.length">
    <div class="d-flex align-baseline justify-space-between">
      <BlockTitle class="font-weight-bold">
        {{ $t('Популярные услуги') }}
      </BlockTitle>

      <router-link class="text-body-2 t-grey--text" :to="{ name: 'services.all' }">
        {{ $t('Перейти в каталог') }}
      </router-link>
    </div>

    <v-row :dense="$vuetify.breakpoint.xsOnly">
      <v-col v-for="item of categories" :key="item.id" cols="6" md="3">
        <SimpleServiceCard
          :name="item.name"
          :image="item.image"
          :to="{ name: 'services.category', params: { id: item.id } }"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BlockTitle from '@/components/home/BlockTitle.vue';
import SimpleServiceCard from '@/components/service/SimpleServiceCard.vue';

export default {
  name: 'PopularServices',
  components: { SimpleServiceCard, BlockTitle },
  props: {
    categories: Array,
  },
};
</script>
