import Vue from 'vue';

// import VueMask from 'v-mask';
// Vue.use(VueMask);

// import VueTheMask from 'vue-the-mask';
// Vue.use(VueTheMask);

import InputFacade from 'vue-input-facade';

const options = {
  // // rename the directive from: v-facade to: v-mask
  // name: 'mask',
  //
  // // use these tokens instead of the default
  // tokens: {
  //   '#': { pattern: /\d/ },
  //   A: { pattern: /[a-z]/i },
  //   N: { pattern: /[0-9a-z]/i },
  //   X: { pattern: /./ },
  // },
};

Vue.use(InputFacade, options);
