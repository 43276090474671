export default [
  {
    path: '/admin/ref',
    name: 'admin.ref',
    components: {
      default: () => import(/* webpackChunkName: "admin-ref" */ '@/views/admin/ref/RoutePage'),
      tabs: () => import(/* webpackChunkName: "admin-ref" */ '@/views/admin/ref/RouteTabs'),
    },
    children: [
      {
        path: '/admin/ref/doc-templates',
        name: 'admin.ref.doc-templates',
        component: () =>
          import(
            /* webpackChunkName: "admin-ref" */ '@/views/admin/ref/doc-templates/DocTemplatesPage.vue'
          ),
        meta: { isAuth: true },
      },
      {
        path: '/admin/ref/company-roles',
        name: 'admin.ref.company-roles',
        component: () =>
          import(/* webpackChunkName: "admin-ref" */ '@/views/admin/location/LocationRolesPage'),
        props: { type: 'company' },
        meta: { isAuth: true },
      },
      {
        path: '/admin/ref/price-category',
        name: 'admin.ref.price-category',
        component: () =>
          import(
            /* webpackChunkName: "admin-ref" */ '@/views/admin/ref/price-category/PriceCategoryPage.vue'
          ),
        meta: { isAuth: true },
      },
      {
        path: '/admin/ref/meters',
        name: 'admin.ref.meters',
        component: () =>
          import(/* webpackChunkName: "admin-ref" */ '@/views/admin/ref/meters/MetersPage.vue'),
        meta: { isAuth: true },
      },
      {
        path: '/admin/ref/media/event-categories',
        name: 'admin.event-categories',
        component: () =>
          import(
            /* webpackChunkName: "admin-media" */ '@/views/admin/ref/media/event-categories/EventCategoriesPage'
          ),
        meta: { isAuth: true },
      },
      {
        path: '/admin/ref/media/news-categories',
        name: 'admin.news-categories',
        component: () =>
          import(
            /* webpackChunkName: "admin-media" */ '@/views/admin/ref/media/news-categories/NewsCategoriesPage.vue'
          ),
        meta: { isAuth: true },
      },
      {
        path: '/admin/ref/access-levels',
        name: 'admin.ref.access-levels',
        component: () =>
          import(
            /* webpackChunkName: "admin-ref" */ '@/views/admin/ref/access-levels/AccessLevelsPage.vue'
          ),
        meta: { isAuth: true },
      },
      {
        path: 'coworking/work-places',
        name: 'admin.coworking.work-places',
        component: () =>
          import(
            /* webpackChunkName: "admin-coworking" */ '@/views/admin/ref/coworking/work-places/WorkPlacesPage.vue'
          ),
        meta: { isAuth: true },
      },
      {
        path: 'hotel/rooms',
        name: 'admin.hotel.rooms',
        component: () =>
          import(
            /* webpackChunkName: "admin-hotel" */ '@/views/admin/ref/hotel/rooms/RoomsPage.vue'
          ),
        meta: { isAuth: true },
      },
      {
        path: 'coffee-break/products',
        name: 'admin.coffee-break.products',
        component: () =>
          import(
            /* webpackChunkName: "admin-cb" */ '@/views/admin/ref/coffee-break/products/ProductsPage.vue'
          ),
        meta: { isAuth: true },
      },
      {
        path: 'coffee-break/stock-list',
        name: 'admin.coffee-break.stock-list',
        component: () =>
          import(
            /* webpackChunkName: "admin-cb" */ '@/views/admin/ref/coffee-break/stock-list/StockListPage.vue'
          ),
        meta: { isAuth: true },
      },
    ],
  },
  {
    path: '/admin/ref/doc-templates/new',
    name: 'admin.ref.doc-templates.new',
    component: () =>
      import(
        /* webpackChunkName: "admin-ref" */ '@/views/admin/ref/doc-templates/DocTemplateForm.vue'
      ),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/ref/doc-templates/:id(\\d+)/edit',
    name: 'admin.ref.doc-templates.edit',
    component: () =>
      import(
        /* webpackChunkName: "admin-ref" */ '@/views/admin/ref/doc-templates/DocTemplateForm.vue'
      ),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
];
