import { makeDataModel } from '@/store/factory/dataModel';
import coffeeBreakApi from '@/api/coffeeBreakApi';

export default makeDataModel(
  { service: coffeeBreakApi.delivery },
  {
    actions: {
      async deliveryStart({ commit }, { id }) {
        return coffeeBreakApi.delivery.deliveryStart({ id }).then(res => {
          commit('UPDATE', res.data);
        });
      },

      async deliveryEnd({ commit }, { id }) {
        return coffeeBreakApi.delivery.deliveryEnd({ id }).then(res => {
          commit('UPDATE', res.data);
        });
      },

      async deliveryCancel({ commit }, { id, comment }) {
        return coffeeBreakApi.delivery.deliveryCancel({ id, comment }).then(res => {
          commit('UPDATE', res.data);
        });
      },
    },
  }
);
