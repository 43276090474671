import i18n from '@/plugins/i18n';
import authApi from '@/api/authApi';
import offerApi from '@/api/offerApi';
import { OfferType } from '@/constants/offer';

const initialState = () => ({});

export function makeManageLocationsModule({
  locationsList,
  dbLocations,
  dbCompanies,
  dbProfiles,
  manageEmployees,
}) {
  const params = Object.values(arguments[0]);
  if (params.filter(x => x).length !== params.length) {
    throw new Error('Invalid params');
  }

  return {
    namespaced: true,

    state: initialState,

    getters: {
      locations: (state, _, __, rootGetters) => {
        return rootGetters[`${locationsList}/items`].map(x => ({
          ...x,
          company: rootGetters[`${dbCompanies}/getItem`](x.companyId),
        }));
      },

      getLocation: (state, _, __, rootGetters) => id => {
        const location = rootGetters[`${locationsList}/getItem`](id);
        return (
          location && {
            ...location,
            company: rootGetters[`${dbCompanies}/getItem`](location.companyId),
          }
        );
      },

      countries: (state, _, __, rootGetters) => rootGetters['countries/items'],

      cities: (state, _, __, rootGetters) => rootGetters['cities/items'],

      getProfileById: (state, _, __, rootGetters) => id => rootGetters[`${dbProfiles}/getItem`](id),
    },

    actions: {
      async fetchLocations({ dispatch, rootGetters }) {
        await dispatch(`${locationsList}/fetchItems`, {}, { root: true });
        const ids = rootGetters[`${locationsList}/items`].map(x => x.companyId).filter(x => x);
        if (ids.length > 0) {
          await dispatch(
            `${dbCompanies}/fetchItems`,
            { query: { id: ids.join(','), limit: ids.length } },
            { root: true }
          );
        }
      },

      async fetchLocation({ dispatch, rootGetters }, id) {
        await dispatch(`${locationsList}/fetchItem`, { id }, { root: true });
        const location = rootGetters[`${locationsList}/getItem`](id);
        if (location.companyId > 0) {
          await dispatch(
            `${dbCompanies}/fetchItem`,
            { query: { id: location.companyId } },
            { root: true }
          );
        }
      },

      async fetchRefs({ dispatch }) {
        return Promise.all([
          dispatch('countries/fetchItems', {}, { root: true }),
          dispatch('cities/fetchItems', {}, { root: true }),
        ]);
      },

      async saveLocationForm({ rootGetters, dispatch }, payload) {
        let locationId = payload.location.id;
        let companyId = payload.company.id;
        const isNew = !locationId;

        if (!payload.location.country.id) {
          const { data: country } = await dispatch(
            'db/countries/create',
            payload.location.country,
            {
              root: true,
            }
          );
          await dispatch('countries/fetchItems', {}, { root: true });
          payload.location.country = country;
          // payload.location.countryId = country.id;
          payload.location.city.countryId = country.id;
        }

        if (!payload.location.city.id) {
          const qs = new URLSearchParams({
            location: [
              payload.location.city.point.latitude,
              payload.location.city.point.longitude,
            ].join(','),
            timestamp: 0,
            key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
          }).toString();
          const res = await fetch(`https://maps.googleapis.com/maps/api/timezone/json?${qs}`);
          const json = await res.json();
          payload.location.city.timeZone = json.timeZoneId;

          const { data: city } = await dispatch('db/cities/create', payload.location.city, {
            root: true,
          });
          await dispatch('cities/fetchItems', {}, { root: true });
          payload.location.city = city;
          // payload.location.cityId = city.id;
        }

        // create location / mc
        if (!companyId) {
          const { data: newCompany } = await dispatch(`${dbCompanies}/create`, payload.company, {
            root: true,
          });
          companyId = newCompany.id;
        } else {
          await dispatch(`${dbCompanies}/update`, payload.company, { root: true });
        }

        const locationData = {
          ...payload.location,
          companyId: companyId,
          cityId: payload.location.city.id,
        };

        if (!locationId) {
          const { data: newLocation } = await dispatch(`${dbLocations}/create`, locationData, {
            root: true,
          });
          locationId = newLocation.id;
        } else {
          await dispatch(`${dbLocations}/update`, locationData, { root: true });
        }

        // mc offer
        if (isNew) {
          await offerApi.acceptSaasCompanyOffer({
            locationId: locationId,
            companyId: companyId,
          });
        }
        await offerApi.upsertSaasOffer({
          locationId: locationId,
          offeror: `mc-${companyId}`,
          offerType: OfferType.ADMIN_CONTRACT,
        });

        // co offer
        await offerApi.acceptResidentOffer({
          locationId: locationId,
          companyId: companyId,
        });
        await offerApi.upsertSaasOffer({
          locationId: locationId,
          offeror: `co-${companyId}`,
          offerType: OfferType.EMPLOYMENT_CONTRACT,
        });

        // public offer
        if (isNew) {
          await offerApi.upsertSaasOffer({
            locationId: locationId,
            offeror: `mc-${companyId}`,
            offerType: OfferType.PUBLIC_OFFER,
            public: true,
            needAccept: true,
            name: i18n.t('Пользовательское соглашение'),
          });
        }

        await dispatch(
          `${manageEmployees}/saveEmployees`,
          {
            companyId,
            data: payload.adminEmployees.map(x => ({ ...x, offerType: OfferType.ADMIN_CONTRACT })),
            offerType: OfferType.ADMIN_CONTRACT,
          },
          { root: true }
        );

        const msg = isNew ? i18n.t('Успешно сохранено') : i18n.t('Изменения сохранены');
        await dispatch('snackbar/notify', msg, { root: true });

        if (rootGetters['auth/locationId'] === locationId) {
          await dispatch('auth/fetchCurrentLocation', null, { root: true });
        }

        return locationId;
      },

      async updateLocation({ dispatch }, payload) {
        await dispatch(`${dbLocations}/update`, payload, { root: true });
      },

      async updateAbout({ commit }, payload) {
        const { data } = await authApi.admin.location.updateAbout(payload);
        commit(`${dbLocations}/UPDATE`, data, { root: true });
      },

      async updateContacts({ commit }, payload) {
        const { data } = await authApi.admin.location.updateContacts(payload);
        commit(`${dbLocations}/UPDATE`, data, { root: true });
      },
    },

    mutations: {
      SET_DATA(state, obj) {
        Object.keys(obj).forEach(key => (state[key] = obj[key]));
      },

      RESET(state) {
        const newState = initialState();
        Object.keys(newState).forEach(key => (state[key] = newState[key]));
      },
    },
  };
}
