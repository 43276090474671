import Vue from 'vue';
import rbacApi from '@/api/rbacApi';
import { arrayToMap } from '@/utils/arrays';
import i18n from '@/plugins/i18n';

const initialState = () => ({
  permissionsById: {},
  microServicesById: {},
  microServiceOperationsById: {},
});

export default {
  namespaced: true,

  state: initialState(),

  getters: {
    permissions: state => state.permissionsById,
    getPermissionById: state => id => state.permissionsById[id],
    getMicroServiceById: state => id => state.microServicesById[id],
    getMicroService: state => id => state.microServicesById[id],
    getPermissions: state => Object.values(state.permissionsById),
    microServices: state => Object.values(state.microServicesById),
    getMicroServices: state => Object.values(state.microServicesById),
    treeItems: state =>
      Object.values(state.microServicesById).map(microservice => ({
        id: 'ms-' + microservice.id,
        name: `${microservice.name} [${microservice.basePaths.join(',')}]`,
        children: microservice.operations.map(operation => ({
          id: operation.id,
          name: `${operation.path} [${operation.methods.join(',')}] (${operation.operationId})`,
        })),
      })),
  },

  actions: {
    async fetchPermissions({ commit }) {
      return rbacApi.fetchPermissions().then(res => commit('PERMISSIONS', res.data));
    },

    async fetchPermission({ commit }, { id }) {
      return rbacApi.fetchPermission(id).then(res => commit('PERMISSION', res.data));
    },

    async deletePermission({ commit }, { id }) {
      return rbacApi.fetchPermission(id).then(commit('DELETE_PERMISSION', { ...id }));
    },

    async createPermission({ commit, dispatch }, payload) {
      await rbacApi.createPermission(payload).then(res => commit('PERMISSION', res.data));
      dispatch('snackbar/notify', i18n.t('Успешно сохранено'), { root: true });
    },

    async savePermission({ commit, dispatch }, payload) {
      const { data } = payload.id
        ? await rbacApi.updatePermission(payload)
        : await rbacApi.createPermission(payload);

      commit('PERMISSION', data);

      dispatch('snackbar/notify', i18n.t('Успешно сохранено'), { root: true });
    },

    async fetchMicroServices({ commit }) {
      return rbacApi.fetchMicroServices().then(res => commit('MICROSERVICES', res.data));
    },

    async fetchMicroService({ commit }, { id }) {
      return rbacApi.fetchMicroService(id).then(res => commit('MICROSERVICE', res.data));
    },

    async updateMicroServiceOperation({ dispatch }, payload) {
      await rbacApi.updateMicroServiceOperation(payload.id, payload);
      dispatch('snackbar/notify', i18n.t('Успешно сохранено'), { root: true });
    },

    async updateMicroService({ commit, dispatch }, payload) {
      await rbacApi
        .updateMicroService(payload.id, payload)
        .then(res => commit('MICROSERVICE', res.data));
      dispatch('snackbar/notify', i18n.t('Успешно сохранено'), { root: true });
    },
  },

  mutations: {
    PERMISSION(state, payload) {
      Vue.set(state.permissionsById, payload.id, payload);
    },
    PERMISSIONS(state, payload) {
      state.permissionsById = {
        ...state.permissionsById,
        ...arrayToMap(payload, x => x.id),
      };
    },
    MICROSERVICE(state, payload) {
      Vue.set(state.microServicesById, payload.id, payload);
    },
    MICROSERVICES(state, payload) {
      state.microServicesById = {
        ...state.microServicesById,
        ...arrayToMap(payload, x => x.id),
      };
    },
    DELETE_PERMISSION(state, payload) {
      Vue.delete(state.permissionsById, payload.id);
    },
    SET_DATA(state, obj) {
      Object.keys(obj).forEach(key => (state[key] = obj[key]));
    },
    RESET(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
  },
};
