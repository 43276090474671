<template>
  <span>
    <template v-if="daily">
      {{ ticket.startAt | date('short') }} - {{ (ticket.endAt - 1) | date('short') }}
    </template>
    <template v-else>
      {{ ticket.startAt | date('dateLong') }}, {{ $t('с') }}
      {{ ticket.startAt | time }}
      {{ $t('до') }}
      {{ ticket.endAt | timeTo }}
    </template>
  </span>
</template>

<script>
export default {
  name: 'CoworkingTicketPeriod',

  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },

  computed: {
    daily() {
      return this.ticket.endAt - this.ticket.startAt >= 86400 - 1;
    },
  },
};
</script>
