export default {
  computed: {
    $userId() {
      return this.$store.state.auth.userId;
    },
    $profile() {
      return this.$store.getters['profile/profile'];
    },
    $locationId() {
      return this.$store.state.auth.locationId;
    },
    $location() {
      return this.$store.state.auth.location;
    },
  },
};
