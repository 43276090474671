import { Layout } from '@/layouts';

export default [
  {
    path: '/profile',
    name: 'profile',
    redirect: { name: 'profile.personal' },
    components: {
      default: () => import(/* webpackChunkName: "profile" */ '@/views/profile/ProfilePage'),
      tabs: () => import(/* webpackChunkName: "profile" */ '@/views/profile/ProfileTabs'),
    },
    meta: { isAuth: true },
    children: [
      {
        path: '/profile/personal',
        name: 'profile.personal',
        component: () =>
          import(/* webpackChunkName: "profile" */ '@/views/profile/personal/PersonalPage'),
        meta: { isAuth: true },
      },
      {
        path: '/profile/companies',
        name: 'profile.companies',
        component: () =>
          import(/* webpackChunkName: "profile" */ '@/views/profile/companies/CompaniesPage'),
        meta: { isAuth: true },
      },
      {
        path: '/profile/payment-methods',
        name: 'profile.payment-methods',
        component: () =>
          import(
            /* webpackChunkName: "profile" */ '@/views/profile/payment-methods/PaymentMethodsPage'
          ),
        meta: { isAuth: true },
      },
    ],
  },
  {
    path: '/profile/deletion',
    name: 'profile.deletion',
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/views/profile/deletion/DeletionPage'),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/profile/deletion/success',
    name: 'profile.deletion.success',
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/views/profile/deletion/DeletionSuccessPage'),
    meta: { isAuth: false, layout: Layout.SIMPLE },
  },
];
