import $store from '@/store';

export function logMiddleware(to, from, next) {
  console.log('%c route', 'font-wight: bold', from.path, to.path);
  next();
}

export async function initBrand(to, from, next) {
  const brand = $store.state.appBrand.brand;
  if (!brand) {
    await $store.dispatch('appBrand/fetchBrand');
  }

  return next();
}

export async function initUserMiddleware(to, from, next) {
  const userId = $store.state.auth.userId;

  if (userId) {
    return next();
  }

  if (localStorage.getItem('refreshToken')) {
    await $store.dispatch('auth/loginByRefreshToken');
  }

  return next();
}

export async function checkAccessMiddleware(to, from, next) {
  const userId = $store.state.auth.userId;
  const isAuthRoute = to.matched.some(item => item.meta.isAuth);

  if (isAuthRoute && userId) {
    return next();
  }

  if (isAuthRoute) {
    return next({ name: 'auth.login' });
  }

  return next();
}

export async function checkPublicOffers(to, from, next) {
  const needAccept = $store.getters['acceptOffers/needAccept'];
  if (needAccept && to.name !== 'termsOfUse') {
    return next({ name: 'termsOfUse' });
  }

  return next();
}
